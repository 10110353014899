export enum SAAS_ELITE_ORGANIZATION_STATUS {
  DOCUMENTS_PENDING = "DOCUMENTS_PENDING",
  FINANCIAL_PROFILE_PENDING = "FINANCIAL_PROFILE_PENDING",
  VERIFIED = "VERIFIED",
}

export enum ORGANIZATION_TYPES {
  SAAS = "SAAS",
  SALES = "SALES",
}
