<template>
  <AgCard class="fp-loader">
    <transition name="fpfade" mode="out-in">
      <div class="label" v-if="pollMessage" :key="pollMessage">
        {{ pollMessage }}
      </div>
    </transition>
    <div class="progress">
      <v-progress-linear
        indeterminate
        rounded
        height="6"
        stream
      ></v-progress-linear>
    </div>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlightsPollingLoader",
  props: {
    pollMessage: {
      type: String,
      required: true,
    },
  },
});
</script>

<style>
.fp-loader .progress .v-progress-linear__indeterminate {
  background-color: var(--green-color) !important;
}
</style>

<style scoped lang="scss">
.fp-loader .label {
  text-align: center;
  font-weight: 800;
  transition: opacity 0.5s ease;
  margin-bottom: 20px;
}

.fp-loader .progress {
  max-width: 400px;
  margin: auto;
}

.fpfade-enter-active,
.fpfade-leave-active {
  transition: opacity 0.5s ease;
}

.fpfade-enter-from,
.fpfade-leave-to {
  opacity: 0;
}
</style>
