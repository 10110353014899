import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgFareInfo = _resolveComponent("AgFareInfo")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      variant: "h2",
      title: "Price Summary",
      class: "margin_bottom_10"
    }),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderAdultPriceSummaryTitle.label,
      price: _ctx.renderAdultPriceSummaryTitle.price
    }, null, 8 /* PROPS */, ["info", "price"]), [
      [_vShow, _ctx.showAdultBreakDown]
    ]),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderChildPriceSummaryTitle.label,
      price: _ctx.renderChildPriceSummaryTitle.price
    }, null, 8 /* PROPS */, ["info", "price"]), [
      [_vShow, _ctx.showChildBreakDown]
    ]),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderInfantPriceSummaryTitle.label,
      price: _ctx.renderInfantPriceSummaryTitle.price
    }, null, 8 /* PROPS */, ["info", "price"]), [
      [_vShow, _ctx.showInfantBreakDown]
    ]),
    _createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: "Price You Pay",
      price: _ctx.renderTotalGrossPrice,
      "total-price": "true"
    }, null, 8 /* PROPS */, ["price"]),
    _withDirectives(_createVNode(_component_ag_accordion, {
      class: "flight_accordion_wrap",
      "panel-value": [1, 2]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ag_accordion_panel, null, {
          flightInfo: _withCtx(() => [
            _createVNode(_component_ag_heading, {
              variant: "h3",
              title: "Fare Break Down",
              class: "margin_bottom_10",
              style: {"margin-top":"10px"}
            })
          ]),
          tabSection: _withCtx(() => [
            _createVNode(_component_AgFareInfo, {
              "test-id": "",
              style: {"max-width":"inherit"}
            }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_AgDiv, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_heading, {
                      variant: "h3",
                      title: "Adult Break Down",
                      class: "margin_bottom_10",
                      style: {"margin-top":"10px"}
                    }),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Base Fare",
                      price: _ctx.renderBaseFare.adultBaseFare
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Tax",
                      price: _ctx.renderTax.adultTax
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Gross Fare",
                      price: _ctx.renderGrossFare.adultGrossFare
                    }, null, 8 /* PROPS */, ["price"]),
                    (_ctx.AdultPriceDifference !== 0)
                      ? (_openBlock(), _createBlock(_component_AgPriceSummary, {
                          key: 0,
                          "test-id": "",
                          info: _ctx.title(_ctx.AdultPriceDifference || 0),
                          price: _ctx.renderPriceDifference.adultPriceDifference
                        }, null, 8 /* PROPS */, ["info", "price"]))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Total",
                      price: _ctx.renderTotalFare.adultTotal,
                      "total-price": "true"
                    }, null, 8 /* PROPS */, ["price"])
                  ]),
                  _: 1 /* STABLE */
                }, 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.showAdultBreakDown]
                ]),
                _withDirectives(_createVNode(_component_AgDiv, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_heading, {
                      variant: "h3",
                      title: "Child Break Down",
                      class: "margin_bottom_10",
                      style: {"margin-top":"10px"}
                    }),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Base Fare",
                      price: _ctx.renderBaseFare.childBaseFare
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Tax",
                      price: _ctx.renderTax.childTax
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Gross Fare",
                      price: _ctx.renderGrossFare.childGrossFare
                    }, null, 8 /* PROPS */, ["price"]),
                    (_ctx.ChildPriceDifference !== 0)
                      ? (_openBlock(), _createBlock(_component_AgPriceSummary, {
                          key: 0,
                          "test-id": "",
                          info: _ctx.title(_ctx.ChildPriceDifference || 0),
                          price: _ctx.renderPriceDifference.childPriceDifference
                        }, null, 8 /* PROPS */, ["info", "price"]))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Total",
                      price: _ctx.renderTotalFare.childTotal,
                      "total-price": "true"
                    }, null, 8 /* PROPS */, ["price"])
                  ]),
                  _: 1 /* STABLE */
                }, 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.showChildBreakDown]
                ]),
                _withDirectives(_createVNode(_component_AgDiv, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_heading, {
                      variant: "h3",
                      title: "Infant Break Down",
                      class: "margin_bottom_10",
                      style: {"margin-top":"10px"}
                    }),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Base Fare",
                      price: _ctx.renderBaseFare.infantBaseFare
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Tax",
                      price: _ctx.renderTax.infantTax
                    }, null, 8 /* PROPS */, ["price"]),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Gross Fare",
                      price: _ctx.renderGrossFare.infantGrossFare
                    }, null, 8 /* PROPS */, ["price"]),
                    (_ctx.InfantPriceDifference !== 0)
                      ? (_openBlock(), _createBlock(_component_AgPriceSummary, {
                          key: 0,
                          "test-id": "",
                          info: _ctx.title(_ctx.InfantPriceDifference || 0),
                          price: _ctx.renderPriceDifference.infantPriceDifference
                        }, null, 8 /* PROPS */, ["info", "price"]))
                      : _createCommentVNode("v-if", true),
                    _createVNode(_component_AgPriceSummary, {
                      "test-id": "",
                      info: "Total",
                      price: _ctx.renderTotalFare.infantTotal,
                      "total-price": "true"
                    }, null, 8 /* PROPS */, ["price"])
                  ]),
                  _: 1 /* STABLE */
                }, 512 /* NEED_PATCH */), [
                  [_vShow, _ctx.showInfantBreakDown]
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, _ctx.showBreakDown]
    ]),
    _renderSlot(_ctx.$slots, "flightDetailsActionButtons")
  ], 64 /* STABLE_FRAGMENT */))
}