import { MutationTree } from "vuex";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import {
  FlightResponse,
  MultiCityLegStateType,
  SelectedFlightLeg,
  FareOption,
  FlightOption,
} from "@/ag-flight-components/types/Flights";
import { Place } from "@/ag-flight-components/types/Place";

import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { CABIN_CLASS } from "@/ag-flight-components/enums/cabin_class";

const mutations: MutationTree<IFlightSearch> = {
  setIsPolling(state, payload: boolean) {
    state.isPolling = payload;
  },
  setFlightsResponse(state, flights: FlightResponse) {
    state.flightsResponse = flights;
  },
  loadingFlights(state, payload: boolean) {
    state.isFlightFetching = payload;
  },
  setLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  saveFlights(state, flights: FlightResponse) {
    const filteredFlights =
      flights.journey_legs[state.currentLegIndex].flight_options;
    state.isFlightFetching = false;
    state.flightsResponse = flights;
    state.flights = filteredFlights;
    state.selectedFilteredLegsByFareIds.push(filteredFlights);
    state.lastLegIndex = flights.journey_legs.length - 1;
  },
  loadingAirports(state, payload: boolean) {
    state.isAirportFetching = payload;
  },
  saveAirports(state, airports: Array<Place>) {
    state.isAirportFetching = false;
    state.airports = airports;
  },
  saveOrigin(state, payload: Place) {
    state.origin = payload;
  },
  saveDestination(state, payload: Place) {
    state.destination = payload;
  },
  saveDepartureDate(state, payload: Date) {
    state.departure_date = payload;
  },
  saveReturnDate(state, payload: Date) {
    state.return_date = payload;
  },
  saveCabinClass(state, payload: CABIN_CLASS) {
    state.cabin_class = payload;
  },
  saveRouteType(state, payload: ROUTE_TYPE) {
    state.route_type = payload;
  },
  saveAdultCount(state, payload: number) {
    state.adult_count = payload;
  },
  saveChildCount(state, payload: number) {
    state.child_count = payload;
  },
  saveInfantCount(state, payload: number) {
    state.infant_count = payload;
  },
  addMulticityFlight(state, payload: MultiCityLegStateType) {
    state.multiCityLegs.push(payload);
  },
  deleteMulticityFlight(state, index: number) {
    const _legs = state.multiCityLegs;
    _legs.splice(index, 1);
    state.multiCityLegs = _legs;
  },
  updateMulticityDepartureDate(state, payload) {
    const _legs = state.multiCityLegs;
    _legs[payload.index].departure_date = payload.date;

    const updated_legs = _legs.map((x, index) => {
      if (index > payload.index && x.departure_date < payload.date) {
        return {
          ...x,
          departure_date: payload.date,
        };
      }

      return x;
    });

    state.multiCityLegs = updated_legs;
  },
  updateMulticityOrigin(state, payload) {
    state.multiCityLegs[payload.index].origin = payload.origin;
  },
  updateMulticityDestination(state, payload) {
    state.multiCityLegs[payload.index].destination = payload.destination;
  },
  saveFilters(state, payload) {
    state.filters = payload;
  },
  saveSelectedFlightFilters(state, payload) {
    state.selectedFlightFilters = payload;
  },
  saveAmountType(state, payload) {
    state.amountType = payload;
  },
  saveFareType(state, payload) {
    state.fareType = payload;
  },
  saveFareCalculatorAmount(state, payload) {
    state.fareCalculatorAmount = payload;
  },
  selectFlightLeg(state, payload: SelectedFlightLeg) {
    state.selectedFlightLegs.push(payload);
  },
  saveCurrentLegIndex(state, payload: number) {
    state.currentLegIndex = payload;
  },
  clearSelectedFlightLegs(state) {
    state.selectedFlightLegs = [];
    state.selectedFares = [];
    state.selectedFilteredLegsByFareIds = [];
    state.currentLegIndex = 0;
    state.flights = [];
  },
  removeSelectedFlightLeg(state, index: number) {
    state.currentLegIndex = index === 0 ? 0 : index - 1;
    state.selectedFlightLegs.splice(index);
    state.selectedFares.splice(index);
    if (index === 0) {
      state.selectedFilteredLegsByFareIds.splice(index + 1);
    } else {
      state.selectedFilteredLegsByFareIds.splice(index);
    }
  },
  selectFlightFare(state, payload: FareOption) {
    state.selectedFares.push(payload);
  },
  saveFilteredLegsByFareIds(state, payload: FlightOption[]) {
    state.selectedFilteredLegsByFareIds.push(payload);
  },
};

export default mutations;
