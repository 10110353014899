export const POLL_MESSAGES = [
  "Plotting the course to your next adventure...",
  "Scanning the skies for your ideal journey...",
  "Preparing our wings to fetch you the top flights...",
  "Cruising through deals to bring you the best fares...",
  "Fueling up on the latest flight specials for you...",
  "Coordinating with the control tower for your travel plans...",
  "Fasten your seatbelts as we find your perfect flight...",
  "Checking the radar for unbeatable deals...",
  "Getting ready for takeoff with your flight options...",
  "Lifting off to locate your dream destinations...",
  "Soaring into action to secure your sky-high deals...",
  "Navigating through the clouds to find your perfect seat...",
  "On autopilot to the best travel deals for you...",
  "Flying high to snag low fares for you...",
  "Elevating your travel experience with exclusive offers...",
  "Jet-setting to bring you closer to your dream trip...",
  "Gathering the crew to plan your perfect flight itinerary...",
  "Boarding now: Incredible journeys at incredible prices...",
  "Your captain to comfort: Finding flights that suit you best...",
  "Final call for luxury at economy prices...",
];
