<template>
  <AgCard test-id="">
    <AgIconInfoBar test-id="" icon="contactTravelerIcon" :title="renderTitle" />
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[0]"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <ag-heading
            variant="h3"
            :title="renderAccordionTitle"
            class="margin_bottom_0"
          />
        </template>
        <template #tabSection>
          <div class="traveler_autocomplete_row">
            <div class="traveler_autocomplete_container">
              <PassengerAutoComplete
                :value="selectedPassenger"
                :passengers="passengers"
                @onChange="handlePassengerAutoCompleteChange"
              />
              <h2 class="or_heading"><span>OR</span></h2>
              <div>
                <p class="new_passenger_heading" @click="showForm = true">
                  Add New Traveler
                </p>
              </div>
              <hr />
            </div>
          </div>
          <AgRow test-id="" class="padding_top_40" v-if="showForm">
            <AgColumn test-id="" md="7" lg="7">
              <AgRow test-id="">
                <AgColumn test-id="" md="12" lg="9">
                  <AgSelect
                    :items="renderTitleData"
                    @change="(value:any) => handleFieldChange(value,'title')"
                    :value="getValueByNameandIndex('title')"
                    label="Title*"
                    variant="outlined"
                    test-id=""
                    :error="renderError('title')"
                    :id="generateIds('title')"
                  />
                  <AGTextField
                    label="First Name and Middle Name (if any)*"
                    @input="(value:any) => handleTextFieldChange(value, 'first_name')"
                    :value="getValueByNameandIndex('first_name')"
                    test-id=""
                    variant="outlined"
                    :error="renderError('first_name')"
                    :id="generateIds('first_name')"
                  />
                  <AGTextField
                    label="Last Name*"
                    @input="(value:any) => handleTextFieldChange(value, 'last_name')"
                    :value="getValueByNameandIndex('last_name')"
                    test-id=""
                    variant="outlined"
                    :error="renderError('last_name')"
                    :id="generateIds('last_name')"
                  />
                  <ag-calendar
                    label="Date of Birth*"
                    @update:dob="(value:any) => handleDateSelect(value, 'dob')"
                    calendar-name="dob"
                    :date="dob ? dob : disabledDates.from"
                    :min-date="disabledDates.to"
                    :max-date="disabledDates.from"
                    :year-range="disabledDates.yearsToShow"
                    :error="renderError('dob')"
                    :id="generateIds('dob')"
                  />
                  <AgSelect
                    :items="genders"
                    label="Gender*"
                    variant="outlined"
                    @change="(value:any) => handleFieldChange(value, 'gender')"
                    :value="getValueByNameandIndex('gender')"
                    test-id=""
                    :error="renderError('gender')"
                    :id="generateIds('gender')"
                  />
                  <AgSelect
                    :items="allNationalities"
                    label="Nationality*"
                    variant="outlined"
                    @change="(value:any) => handleFieldChange(value, 'nationality')"
                    :value="getValueByNameandIndex('nationality')"
                    test-id=""
                    :error="renderError('nationality')"
                    :id="generateIds('nationality')"
                  />
                  <AGTextField
                    label="CNIC Number*"
                    v-show="!isInternational"
                    @input="(value:any) => handleTextFieldChange(value, 'cnic')"
                    :value="maskCNIC(getValueByNameandIndex('cnic'))"
                    test-id=""
                    variant="outlined"
                    :error="renderError('cnic')"
                    :id="generateIds('cnic')"
                  />
                  <AGTextField
                    label="Passport Number*"
                    v-show="isInternational"
                    @input="(value:any) => handleTextFieldChange(value, 'passport_number')"
                    :value="getValueByNameandIndex('passport_number')"
                    test-id=""
                    variant="outlined"
                    :error="renderError('passport_number')"
                    :id="generateIds('passport_number')"
                  />
                  <ag-calendar
                    label="Passport Expiry*"
                    @update:passport_expiry="(value:any) => handleDateSelect(value, 'passport_expiry')"
                    calendar-name="passport_expiry"
                    v-show="isInternational"
                    :date="validMinimumDate"
                    :min-date="validMinimumDate"
                    :error="renderError('passport_expiry')"
                    :id="generateIds('passport_expiry')"
                  />
                </AgColumn>
              </AgRow>
            </AgColumn>

            <AgColumn test-id="" md="5" lg="5">
              <AgTravelDetail test-id="" :items="renderTravelDetails">
                <template #headingArea>
                  <AgHeading
                    variant="h2"
                    title="Traveler Details"
                    class="margin_bottom_5"
                  />
                </template>
              </AgTravelDetail>
            </AgColumn>
          </AgRow>
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </AgCard>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref } from "vue";
import { addDays, addMonths, addYears, format } from "date-fns";

import { getTravelerTypeName } from "@/ag-flight-components/constants/TravelerTypes";
import Nationalities from "@/ag-portal-common/constants/nationalities";
import { Traveler } from "@/ag-flight-components/types/FlightBookingForm";
import { FlightDetailsType } from "@/ag-flight-components/types";
import { titles, genders } from "@/ag-flight-components/constants/bookingForm";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { TravelerCardDetails } from "@/ag-portal-common/enums/TRAVELER_CARD_DETAILS";
import PassengerAutoComplete from "./PassengerAutoComplete.vue";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import analyticServices from "@/services/analytics.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { PASSENGER_TYPE } from "@/ag-flight-components/enums/passenger-type";
import { bookingFormValidationSchema } from "@/ag-flight-components/validations/bookingFormValidation";

export default defineComponent({
  name: "TavelerCard",
  components: {
    PassengerAutoComplete,
  },
  props: {
    index: {
      type: Number,
    },
    traveler: {
      type: Object as PropType<Traveler>,
    },
    errors: {
      type: Object,
    },
  },
  data(): {
    allNationalities: IObject[];
    genders: IObject[];
    titles: IObject[];
    showForm: boolean;
    onChangeTriggered: boolean;
  } {
    return {
      allNationalities: Nationalities,
      genders,
      titles,
      showForm: false,
      onChangeTriggered: false,
    };
  },
  computed: {
    renderAccordionTitle() {
      const passenger_type = getTravelerTypeName(
        this.traveler?.passenger_type as string
      );
      return `Traveler ${(this.index || 0) + 1}: ${passenger_type}`;
    },
    isInternational() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return flightDetails?.is_international;
    },
    validMinimumDate() {
      const today = new Date();
      const sixMonthsLater = addMonths(today, 6);
      const nextDay = addDays(sixMonthsLater, 1);
      return nextDay;
    },
    disabledDates() {
      const currentDate = new Date();
      let minDate = null;
      let maxDate = new Date();
      let yearsToShow: Array<string> = [];

      const passengerType = getTravelerTypeName(
        this.traveler?.passenger_type as string
      );

      switch (passengerType) {
        case "Adult":
          minDate = addYears(currentDate, -100);
          maxDate = addYears(currentDate, -12);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
        case "Child":
          minDate = addYears(currentDate, -12);
          maxDate = addYears(currentDate, -2);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];

          break;
        case "Infant":
          minDate = addMonths(currentDate, -24);
          yearsToShow = [
            minDate.getFullYear().toString(),
            maxDate.getFullYear().toString(),
          ];
          break;
      }

      return {
        to: minDate,
        from: maxDate,
        yearsToShow: yearsToShow,
      };
    },
    renderTitle() {
      return `Traveler Details - ${
        this.isInternational ? "International" : "Domestic"
      }`;
    },
    renderTitleData() {
      return titles.filter(
        (x) => x.type === (this.traveler?.passenger_type as string)
      );
    },
    renderTravelDetails() {
      return {
        [TravelerCardDetails.TITLE]: this.getValueByNameandIndex("title"),
        [TravelerCardDetails.FIRST_NAME]:
          this.getValueByNameandIndex("first_name"),
        [TravelerCardDetails.LAST_NAME]:
          this.getValueByNameandIndex("last_name"),
        [TravelerCardDetails.DOB]: this.getDateByNameandIndex("dob"),
        [TravelerCardDetails.GENDER]: this.getGender(),
        [TravelerCardDetails.NATIONALITY]: this.getNationality(),
        ...(!this.isInternational && {
          [TravelerCardDetails.CNIC_NUMBER]:
            this.getValueByNameandIndex("cnic"),
        }),
        ...(this.isInternational && {
          [TravelerCardDetails.PASSPORT_NUMBER]:
            this.getValueByNameandIndex("passport_number"),
        }),
        ...(this.isInternational && {
          [TravelerCardDetails.PASSPORT_EXPIRY]:
            this.getDateByNameandIndex("passport_expiry"),
        }),
      };
    },
    passengers(): IPassenger[] {
      const passengers = this.$store.getters.passengers;
      return passengers.filter(
        (x: IPassenger) => x.passenger_type === this.traveler?.passenger_type
      );
    },
  },

  methods: {
    maskCNIC(cnic: string) {
      const cnicStr = cnic.toString();
      const lengthOfCnic = cnicStr.length;
      if (lengthOfCnic === 13 && !cnicStr.includes("-")) {
        return `${cnicStr.substring(0, 5)}-${cnicStr.substring(
          5,
          12
        )}-${cnicStr.substring(12, 13)}`;
      }
      if (lengthOfCnic === 5) {
        return `${cnicStr.substring(0, 5)}-`;
      } else if (lengthOfCnic === 13) {
        return `${cnicStr.substring(0, 5)}${cnicStr.substring(5, 13)}-`;
      } else {
        return cnic;
      }
    },

    changeAgeDate(newDate: Date) {
      this.$store.commit("saveReturnDate", newDate);
    },
    handleFieldChange(value: any, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    renderError(key: string) {
      return this.errors?.[`travelers[${this.index}].${key}`];
    },
    handleDateSelect(value: Date, key: string) {
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    getValueByNameandIndex(key: string) {
      const item =
        this.$store.state.flightBookingModule.travelers[this.index][key];
      return item ? item : "";
    },
    getDateByNameandIndex(key: string) {
      const dateValue =
        this.$store.state.flightBookingModule.travelers[this.index][key];
      return dateValue
        ? format(
            this.$store.state.flightBookingModule.travelers[this.index][key],
            FORMAT_DD_MMM_YYYY
          )
        : "";
    },
    getNationality() {
      const value =
        this.$store.state.flightBookingModule.travelers[this.index].nationality;
      return Nationalities.find((x) => x.value === value)?.label;
    },
    getGender() {
      const value =
        this.$store.state.flightBookingModule.travelers[this.index].gender;
      return genders.find((x) => x.value === value)?.label;
    },
    handleTextFieldChange(event: InputEvent, key: string) {
      const value = (event.target as HTMLInputElement).value;
      const payload = {
        index: this.index,
        key,
        value,
      };
      this.$store.commit("updateTaveler", payload);
    },
    generateIds(key: string) {
      return this.errors?.[`travelers[${this.index}].${key}`];
    },
    handlePassengerAutoCompleteChange(value: IPassenger) {
      const dateOfBirth = new Date(value.dob);
      const passengerData = {
        title: value.title,
        first_name: value.first_name,
        last_name: value.last_name,
        dob: dateOfBirth,
        passenger_type: value.passenger_type,
        nationality: value.nationality,
        is_international: this.isInternational,
        passport_number: value?.passport_number ? value?.passport_number : null,
        passport_expiry: value.passport_expiry
          ? new Date(value.passport_expiry as string)
          : this.isInternational && addMonths(new Date(), 6),
        cnic: value.cnic ? value.cnic : null,
        gender: value.gender,
      };
      this.onChangeTriggered = true;

      analyticServices.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.PASSENGER_DROPDOWN_SELECT,
        passengerData
      );

      this.$store.commit("updatePassengerByIndex", {
        index: this.index,
        value: passengerData,
      });
      this.showForm = true;
    },
  },
  watch: {
    "$store.state.flightBookingModule.travelers": {
      handler: function (newValue) {
        if (this.onChangeTriggered) {
          const value = newValue[this.index as number];
          this.dob = value?.dob;

          if (value?.passport_expiry) {
            this.passport_expiry = new Date(value?.passport_expiry);
          } else {
            this.passport_expiry = addMonths(new Date(), 6);
          }

          this.onChangeTriggered = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.handleDateSelect(this.disabledDates.from, "dob");
    if (this.isInternational) {
      this.handleDateSelect(addMonths(new Date(), 6), "passport_expiry");
    }
  },
  setup() {
    const passport_expiry: Ref<Date | null> = ref(null);
    const dob: Ref<Date | null> = ref(null);
    const selectedPassenger: Ref<IPassenger | null> = ref(null);
    return { passport_expiry, dob, selectedPassenger };
  },
});
</script>

<style scoped lang="scss">
.traveler_autocomplete_row {
  display: flex;
  justify-content: flex-start;
  margin-top: 25px;
  .traveler_autocomplete_container {
    width: 400px;
  }
}

.or_heading {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
  span {
    background: #fff;
    padding: 0 10px;
    color: #854949;
  }
}

.new_passenger_heading {
  font-weight: bold;
  text-align: center;
  font-size: 19px;
  margin: 0;
  padding: 5px;
  color: #1c617a;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    font-size: 21px;
  }
}
</style>
