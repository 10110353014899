import { StatusCodes } from "http-status-codes";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import analyticsService from "@/services/analytics.service";
import { ANALYTICS_EVENTS } from "@/modules/Profile/constants/analyticsEvents";
import notificationService from "@/ag-portal-common/services/notification.service";

import {
  IProfileState,
  IProfilePreferences,
} from "@/ag-portal-common/interfaces/profile.interface";
import PreferencesService from "@/ag-portal-common/services/profile.service";

const actions: ActionTree<IProfileState, IProfileState> = {
  async fetchPreferences(
    context: ActionContext<IProfileState, IProfileState>,
    payload: {
      agentId: string;
    }
  ) {
    const methodName = "actions.fetchOrganizations";
    const { agentId } = payload;

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      const service = new PreferencesService();
      const response = await service.get(agentId);

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("savePreferences", response.data?.data);

        const analyticsPayload = response.data?.data;
        analyticsService.logActionEvent(
          ANALYTICS_EVENTS.FETCH_PREFERENCES,
          analyticsPayload
        );
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    }
  },

  async updatePreferences(
    context: ActionContext<IProfileState, IProfileState>,
    payload: {
      agentId: string;
      body: IProfilePreferences;
    }
  ) {
    const methodName = "actions.updatePreferences";
    const { agentId, body } = payload;
    let response: IAGResponse<any> = {
      success: false,
    };

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("enableUpdatePreferencesLoading");

      const payload = {
        contact_number: body.contact_number,
        whatsapp_number: body.whatsapp_number,
        flight_search_pairs_view: body.flight_search_pairs_view,
      };

      const service = new PreferencesService();
      response = await service.update(agentId, payload);

      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("savePreferences", payload);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Preferences updated successfully";

        const analyticsPayload = payload;
        analyticsService.logActionEvent(
          ANALYTICS_EVENTS.FETCH_PREFERENCES,
          analyticsPayload
        );
      } else {
        throw response;
      }
    } catch (err) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = response?.error || "";
      loggerService.logError(`${methodName}:`, err);
    }
    notificationService.triggerNotification();
    context.commit("disableUpdatePreferencesLoading");
  },
};

export default actions;
