import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgTimeRunOut = _resolveComponent("AgTimeRunOut")!

  return (_openBlock(), _createBlock(_component_AgTimeRunOut, {
    "test-id": "",
    time: _ctx.formatedTime,
    text: "Book now before search expires"
  }, null, 8 /* PROPS */, ["time"]))
}