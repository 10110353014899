import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlightSearchResultLegInfo = _resolveComponent("FlightSearchResultLegInfo")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_AgRow, { "test-id": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_AgColumn, {
            "test-id": "",
            xs: "12",
            md: "6",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgSelectedFlightInfo, {
                date: _ctx.tripDate,
                adult: _ctx.$route.query.adult,
                children: _ctx.$route.query.child,
                infant: _ctx.$route.query.infant,
                "cabin-class": _ctx.$route.query.cabin_class
              }, {
                destination: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.allJourneyLegs, (leg, index) => {
                    return (_openBlock(), _createBlock(_component_FlightSearchResultLegInfo, {
                      key: index,
                      "total-length": _ctx.allJourneyLegsLength,
                      index: index,
                      leg: leg,
                      "current-leg": _ctx.$store.getters.currentLegIndex
                    }, null, 8 /* PROPS */, ["total-length", "index", "leg", "current-leg"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["date", "adult", "children", "infant", "cabin-class"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_AgColumn, {
            "test-id": "",
            xs: "12",
            md: "6",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "timer")
            ]),
            _: 3 /* FORWARDED */
          })
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }))
}