import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex margin_bottom_10 margin_top_20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_ag_chip = _resolveComponent("ag-chip")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    onClose: _ctx.closeModal,
    "modal-width": "40%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        variant: "h4",
        title: "Airline Tickets"
      }),
      _createVNode(_component_a_g_button, {
        testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-close'),
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["testId", "onClick"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_ag_heading, {
        title: "Please type email in the box and press enter. Airline tickets will be sent on emails below.",
        variant: "p"
      }),
      _createVNode(_component_a_g_text_field, {
        label: "Email",
        testId: 
          _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'airline-ticket-modal-email')
        ,
        value: _ctx.emailField,
        onFocusout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emailFieldErrorMessage = '')),
        error: _ctx.emailFieldErrorMessage,
        modelValue: _ctx.emailField,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailField) = $event)),
        onKeydown: _withKeys(_ctx.updateEmails, ["enter"])
      }, null, 8 /* PROPS */, ["testId", "value", "error", "modelValue", "onKeydown"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emails, (email, index) => {
          return (_openBlock(), _createBlock(_component_ag_chip, {
            class: "margin_right_10",
            color: "success",
            key: index,
            testId: 
            _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-email-chip')
          ,
            closable: "",
            "onClick:close": ($event: any) => (_ctx.onRemoveEmail(index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(email), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["testId", "onClick:close"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_a_g_button, {
        testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-send'),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onSendAirlineTicket', _ctx.emails))),
        "is-loading": _ctx.$store.getters.isSendingAirlineTicket
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Send Airline Tickets ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["testId", "is-loading"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open", "onClose"]))
}