import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IProfilePreferences } from "@/ag-portal-common/interfaces/profile.interface";
import { PREFERENCES_API } from "@/ag-portal-common/constants/apiPreferences";

class PreferencesService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(
    agentId: string
  ): Promise<IAGResponse<IAGResponse<IProfilePreferences>>> {
    return this.restClient.get(`${PREFERENCES_API.get}${agentId}/`);
  }

  public update(
    agentId: string,
    body: IProfilePreferences
  ): Promise<IAGResponse<IAGResponse<IProfilePreferences>>> {
    return this.restClient.put(`${PREFERENCES_API.update}${agentId}/`, body);
  }
}

export default PreferencesService;
