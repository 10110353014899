import { ActionContext, ActionTree } from "vuex";
import { StatusCodes } from "http-status-codes";

import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import FlightIssuanceService from "@/ag-flight-components/services/flightIssuance.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import {
  FETCH_FLIGHT_PNR_PAYLOAD,
  ISSUE_TICKET_PAYLOAD,
} from "@/ag-flight-components/types";
import notificationService from "@/ag-portal-common/services/notification.service";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { CACHE_NAMES } from "@/ag-portal-common/constants";
import { GetFinancialProfileDto } from "./../dtos/financialProfile.dto";

const actions: ActionTree<IFlightIssuanceState, IFlightIssuanceState> = {
  // ****** Fetch PNR ******
  async fetchPNR(
    context: ActionContext<IFlightIssuanceState, IFlightIssuanceState>,
    params: FETCH_FLIGHT_PNR_PAYLOAD
  ) {
    const methodName = "actions.fetchPNR";
    context.commit("resetPNRDetails");
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("fetchingPNR", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse<any> = await flightIssuanceService.fetchPNR(
        params
      );
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("fetchedPNRDetail", response.data.data);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.message || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("fetchingPNR", false);
    }
  },

  // ****** Issue Ticket ******
  async issueTicket(
    context: ActionContext<IFlightIssuanceState, IFlightIssuanceState>,
    params: ISSUE_TICKET_PAYLOAD
  ) {
    const methodName = "actions.issueTicket";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`, params);
      context.commit("isIssueTicketLoading", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse<any> =
        await flightIssuanceService.issueTicket(params);
      if (response.success && response.status === StatusCodes.OK) {
        await caches.delete(CACHE_NAMES.flightsBookings);
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          response.data?.message || "Ticket is successfully issued";
        notificationService.triggerNotification();
        context.commit("isTicketIssued", true);
      } else {
        throw response;
      }
    } catch (error: any) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, error);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = error?.error || "";
      notificationService.triggerNotification();
    } finally {
      context.commit("isIssueTicketLoading", false);
    }
  },

  // ****** Fetch Financial Profiles *****
  async fetchFinancialProfiles(
    context: ActionContext<IFlightIssuanceState, IFlightIssuanceState>,
    params: GetFinancialProfileDto
  ) {
    const methodName = "actions.fetchFinancialProfiles";
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      context.commit("setFinancialProfileLoading", true);
      const flightIssuanceService = new FlightIssuanceService();
      const response: IAGResponse<any> =
        await flightIssuanceService.getAllFinancialProfiles(params);
      console.log("🚀 ~ response:", response);
      if (response.success && response.status === StatusCodes.OK) {
        loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
        context.commit("saveIssuanceFinancialProfiles", response.data);
      } else {
        throw response;
      }
    } catch (err) {
      loggerService.logError(`${methodName}:`, err);
    } finally {
      context.commit("setFinancialProfileLoading", false);
    }
  },
};

export default actions;
