import { MutationTree } from "vuex";
import {
  IProfileState,
  IProfilePreferences,
} from "@/ag-portal-common/interfaces/profile.interface";

const mutations: MutationTree<IProfileState> = {
  enableUpdatePreferencesLoading(state) {
    state.isUpdatingPreferences = true;
  },
  disableUpdatePreferencesLoading(state) {
    state.isUpdatingPreferences = false;
  },

  savePreferences(state, payload: IProfilePreferences) {
    state.preferences = payload;
    localStorage.setItem("user-preferences", JSON.stringify(payload));
  },
};

export default mutations;
