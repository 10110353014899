import { Traveler } from "@/ag-flight-components/types/FlightBookingForm";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { format } from "date-fns";

type FormattedTravelerResponses = {
  travelersForConfirmBookingPaylaod: Traveler[];
  travelersForCreatePassengersPayload: Traveler[];
};

export const formatBookingTravelersPayload = (
  travelers: Traveler[]
): FormattedTravelerResponses => {
  const travelersForConfirmBookingPaylaod = travelers.map((x: Traveler) => {
    const DOB = format(new Date(x.dob), FORMAT_YYY_MM_DD);
    const PassportExpiry = x?.is_international
      ? format(
          new Date(x.passport_expiry ? x.passport_expiry : new Date()),
          FORMAT_YYY_MM_DD
        )
      : null;
    return {
      ...x,
      cnic: !x.is_international ? x.cnic?.replace(/-/g, "") : null,
      passport_number: x.is_international ? x.passport_number : null,
      dob: DOB,
      passport_expiry: PassportExpiry,
    };
  });

  const travelersForCreatePassengersPayload = travelers.map((x: Traveler) => {
    const DOB = format(new Date(x.dob), FORMAT_YYY_MM_DD);
    const PassportExpiry = x.passport_expiry
      ? format(
          new Date(x.passport_expiry ? x.passport_expiry : new Date()),
          FORMAT_YYY_MM_DD
        )
      : null;

    return {
      ...x,
      cnic: x.cnic?.replace(/-/g, ""),
      dob: DOB,
      passport_expiry: PassportExpiry,
    };
  });

  return {
    travelersForConfirmBookingPaylaod,
    travelersForCreatePassengersPayload,
  };
};
