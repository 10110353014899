import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a5c3f23"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_AgCard = _resolveComponent("AgCard")!

  return (_openBlock(), _createBlock(_component_AgCard, { class: "fp-loader" }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fpfade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.pollMessage)
            ? (_openBlock(), _createElementBlock("div", {
                class: "label",
                key: _ctx.pollMessage
              }, _toDisplayString(_ctx.pollMessage), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_progress_linear, {
          indeterminate: "",
          rounded: "",
          height: "6",
          stream: ""
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}