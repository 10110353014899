<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<!-- eslint-disable vue/valid-v-show -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <template v-if="flightsLoading">
    <ag-loader />
  </template>
  <template v-else>
    <ResultTimeoutModal
      :is-open="timerTimeOuted"
      :handle-refresh-results="handleRefreshResults"
    />

    <SearchedFlightInfo :trip-date="tripDate">
      <template #timer>
        <Timer v-show="showTimer" @timer:timedOut="handleTimerTimeOut" />
      </template>
    </SearchedFlightInfo>

    <AgDiv
      class="date-chip-container"
      v-show="showAvailableDateCalendarContaner"
    >
      <AgAvailableDateChip
        v-for="(item, index) in futureDateCombinations"
        :key="index"
        :departureDate="item?.departureDate"
        :arrivalDate="item?.returnDate"
        :isReturn="isReturnType"
        @quickDateSearchEmit="hanldeDateChipClick"
        :isActive="isDateActive(item?.departureDate)"
      />
    </AgDiv>

    <Filters
      :selected-filters="selectedFilters"
      :handle-change-search-dialog-open="handleChangeSearchDialogOpen"
      :handle-update-range="handleUpdateRange"
      @updateSelectedFilters="updateSelectedFilters"
    />

    <FlightSearchChips
      :selected-filters="selectedFilters"
      :handle-remove-chip="handleRemoveChip"
    >
      <template #fare_calculator>
        <FareCalculator
          v-model:pair-view="isPairView"
          @update:pair-view="onPairViewToggleHandler"
        />
      </template>
    </FlightSearchChips>

    <!-- Selected Legs -->
    <SelectedFlightLegs />

    <!-- Polling -->
    <template v-if="isPolling && !isPollingLimitReached && isFlightsLoaded()">
      <FlightsPollingLoader :poll-message="pollMessage" />
    </template>

    <!-- Flight List Mapping -->
    <template v-if="isReturnType && isPairView">
      <AgCard test-id="" v-for="item in filteredReturnFlights" :key="item">
        <ReturnFlightDetailCardItem :flightItem="item" />
      </AgCard>
    </template>
    <template v-else>
      <AgCard
        test-id=""
        v-for="item in filteredFlights"
        :key="item.flight_numbers[0] + item.departure_time"
      >
        <FlightDetailCardItem
          @selectedDialogItem="handleFlightFareSelect"
          :flightItem="item"
        />
      </AgCard>
    </template>

    <!-- Polling -->
    <template v-if="isPolling && !isPollingLimitReached">
      <template v-if="isFlightsLoaded()">
        <FlightsPollingLoader :poll-message="pollMessage" />
      </template>
      <template v-else>
        <AgCard class="flights-loader">
          <div class="word skeleton">header</div>
          <div class="word skeleton">description content</div>
          <div class="line skeleton"></div>
          <div class="line skeleton"></div>
        </AgCard>
      </template>
    </template>

    <template v-else-if="emptyResults">
      <!-- Results no found -->
      <AgNotFound
        test-id=""
        heading="No Results Found"
        description="Please Try Modify Your Filters OR Try Again"
      />
    </template>
  </template>

  <!-- Dialogs -->
  <FareBookingDialog
    :is-open="isBookingDialogOpen"
    :flight-item="selectedFlightItemForDialog"
    :fare-option="selectedFareForDialog"
    @handleDialogClose="handleCloseBookingDialog"
  />
  <FlightChangeSearch
    :is-open="isChangeSearchDialogOpen"
    :handle-close="handleChangeSearchDialogClose"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import _ from "lodash";
import {
  differenceInDays,
  addDays,
  subDays,
  format,
  isSameDay,
  isPast,
} from "date-fns";

// Components
import SearchedFlightInfo from "@/ag-flight-components/components/FlightSearchResults/SearchedFlightInfo.vue";
import Filters from "@/ag-flight-components/components/FlightSearchResults/Filters.vue";
import FlightSearchChips from "@/ag-flight-components/components/FlightSearchResults/FlightSearchChips.vue";
import SelectedFlightLegs from "@/ag-flight-components/components/FlightSearchResults/SelectedFlightLegs.vue";
import FlightChangeSearch from "@/ag-flight-components/components/FlightSearchResults/ChangeSearch.vue";
import Timer from "@/ag-flight-components/components/FlightSearchResults/Timer.vue";
import ResultTimeoutModal from "@/ag-flight-components/components/FlightSearchResults/TimeoutModal.vue";
import FareCalculator from "@/ag-flight-components/components/FlightSearchResults/FareCalculator.vue";
import FlightDetailCardItem from "@/ag-flight-components/components/FlightSearchResults/FlightDetailCardItem.vue";
import ReturnFlightDetailCardItem from "@/ag-flight-components/components/FlightSearchResults/ReturnFlightDetailCardItem.vue";
import FareBookingDialog from "@/ag-flight-components/components/FlightSearchResults/FareBookingDialog.vue";
import FlightsPollingLoader from "@/components/FlightsPollingLoader.vue";

// Utils
import {
  formatMultiCityQueryParamToArray,
  formatTripDates,
  filterFlightsByAirlines,
  filterFlightsByDepartureTime,
  filterFlightsByStops,
  filterFlightsByPrice,
} from "@/ag-flight-components/utils";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { POLL_MESSAGES } from "@/modules/Flight/constants/polling";

// Types
import {
  FareOption,
  FlightOption,
  SelectedFlightSearchQueryFilters,
  SelectedFlightLeg,
  returnFlightPairs,
  FlightResponse,
} from "@/ag-flight-components/types/Flights";
import {
  MAX_POLLING_TIMEOUT,
  MAX_POLLING_LIMIT,
} from "@/modules/Flight/constants";
import { JourneyLeg } from "@/ag-flight-components/types/JourneyLeg";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { PATH } from "@/ag-portal-common/constants/path";
import { QuickDateChip } from "@/ag-flight-components/types/QuickDateChip";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/services/analytics.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import { IProfilePreferences } from "@/ag-portal-common/interfaces/profile.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

export default defineComponent({
  name: "FlightSearchResult",
  components: {
    SearchedFlightInfo,
    Filters,
    FlightSearchChips,
    SelectedFlightLegs,
    FlightChangeSearch,
    Timer,
    ResultTimeoutModal,
    FareCalculator,
    FlightDetailCardItem,
    ReturnFlightDetailCardItem,
    FareBookingDialog,
    FlightsPollingLoader,
  },
  data(): {
    filteredFlights: Array<FlightOption>;
    tripDate: string;
    timerTimeOuted: boolean;
    selectedFilters: SelectedFlightSearchQueryFilters;
    isBookingDialogOpen: boolean;
    currentSelectedFlightOptionForDialog: FlightOption | null;
    selectedFareForDialog: FareOption | null;
    selectedFlightItemForDialog: FlightOption | null;
    isChangeSearchDialogOpen: boolean;
    allowRouterQueryRequest: boolean;
    matchedValues: Array<returnFlightPairs>;
    isPairView: boolean;
    flights: FlightOption[];
    pollingInterval: number;
    maxPollingLimit: number;
    pollingLimit: number;
    timeoutId: number;
    isPollingLimitReached: boolean;
    pollMessages: string[];
    pollMessageIndex: number;
    pollMessage: string;
    user: IUserV2 | null;
  } {
    return {
      matchedValues: [],
      tripDate: "",
      timerTimeOuted: false,
      isBookingDialogOpen: false,
      isChangeSearchDialogOpen: false,
      selectedFareForDialog: null,
      selectedFlightItemForDialog: null,
      selectedFilters: {
        airlines: [],
        stops: [],
        priceRange: [],
        departureTime: [],
        maxPriceRangeSlider: 0,
      },
      filteredFlights: [],
      currentSelectedFlightOptionForDialog: null,
      allowRouterQueryRequest: true,
      isPairView: true,
      flights: [],
      pollingInterval: MAX_POLLING_TIMEOUT,
      maxPollingLimit: MAX_POLLING_LIMIT,
      pollingLimit: 0,
      timeoutId: 0,
      isPollingLimitReached: false,
      pollMessages: POLL_MESSAGES,
      pollMessageIndex: 0,
      pollMessage: "",
      user: null,
    };
  },

  computed: {
    isPolling() {
      return this.$store.getters.isPolling;
    },
    flightsResponse() {
      return this.$store.getters.flightsResponse;
    },
    nextJourneyLeg() {
      return this.$store.getters.nextJourneyLeg;
    },
    flightsLoading() {
      return this.$store.getters.isFlightsLoading;
    },
    filters() {
      return this.$store.getters.filters;
    },
    currentJourneyLeg() {
      return this.$store.getters.currentJourneyLeg;
    },
    selectedFlightFilters() {
      return this.$store.getters.allSelectdFlightFilters;
    },
    selectedAirlineFilters() {
      return this.$store.getters.selectedAirlineFilters;
    },
    selectedFlightLegs(): Array<SelectedFlightLeg> {
      return this.$store.state.flightModule.selectedFlightLegs;
    },
    showTimer() {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;
      return !isLoading && flights.length > 0;
    },
    emptyResults() {
      const isLoading = this.$store.getters.isFlightsLoading;
      const flights = this.$store.getters.flights;
      return !isLoading && flights.length < 1;
    },
    allJourneyLegs() {
      return this.$store.getters.allJourneyLegs;
    },
    showAvailableDateCalendarContaner() {
      const { route_type } = this.$route.query;
      return route_type !== ROUTE_TYPE.MULTI_CITY;
    },
    futureDateCombinations(): any[] {
      const { route_type, trips } = this.$route.query;
      const tripsString = trips?.toString() || "";

      const [origin, destination, departure_date, return_date] =
        tripsString.split(",");
      const dates = [];

      if (route_type === ROUTE_TYPE.RETURN) {
        const departureDate = new Date(departure_date);
        const returnDate = new Date(return_date);

        if (isSameDay(departureDate, returnDate)) {
          // If departure and return dates are the same, make dates incremental
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(departureDate, i),
              returnDate: addDays(returnDate, i),
            });
          }
        } else {
          const daysDifference = differenceInDays(returnDate, departureDate);
          for (let i = 1; i < 4; i++) {
            const totalDiff = daysDifference * (i + 1);
            dates.push({
              departureDate: subDays(departureDate, i),
              returnDate: subDays(returnDate, i),
            });
          }
          for (let i = 0; i < 4; i++) {
            const totalDiff = daysDifference * (i + 1);
            dates.push({
              departureDate: addDays(departureDate, i),
              returnDate: addDays(returnDate, i),
            });
          }

          dates.sort((a, b) => {
            const dateA: Date = a.departureDate as Date;
            const dateB: Date = b.departureDate as Date;
            return dateA.getTime() - dateB.getTime();
          });

          const currentDate = new Date();
          const filteredDates = dates.filter((date) => {
            // Extract only the date part without the time component for date.departureDate
            const dateWithoutTime = new Date(
              date.departureDate.getFullYear(),
              date.departureDate.getMonth(),
              date.departureDate.getDate()
            );

            // Extract only the date part without the time component for the current date
            const currentDateWithoutTime = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            );
            return dateWithoutTime >= currentDateWithoutTime;
          });

          return filteredDates;
        }
      } else if (route_type === ROUTE_TYPE.ONEWAY) {
        const departureDate = new Date(departure_date);

        if (isSameDay(departureDate, new Date())) {
          // If it's the same day, show the next 8 days
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(departureDate, i + 1),
            });
          }
        } else if (isPast(departureDate)) {
          // if past then show aaj k din sa aagay k 8 days
          for (let i = 0; i < 7; i++) {
            dates.push({
              departureDate: addDays(new Date(), i + 1),
            });
          }
        } else {
          // If it's not the same day, show the previous 3 days and the next 3 days
          const daysDifference = differenceInDays(departureDate, new Date());
          const previousDays = Math.min(daysDifference, 4);

          for (let i = 1; i < 4; i++) {
            dates.push({
              departureDate: subDays(departureDate, i),
            });
          }
          for (let i = 0; i < 4; i++) {
            dates.push({
              departureDate: addDays(departureDate, i),
            });
          }
        }

        dates.sort((a, b) => {
          const dateA: Date = a.departureDate as Date;
          const dateB: Date = b.departureDate as Date;
          return dateA.getTime() - dateB.getTime();
        });

        const currentDate = new Date();
        const filteredDates = dates.filter((date) => {
          // Extract only the date part without the time component for date.departureDate
          const dateWithoutTime = new Date(
            date.departureDate.getFullYear(),
            date.departureDate.getMonth(),
            date.departureDate.getDate()
          );

          // Extract only the date part without the time component for the current date
          const currentDateWithoutTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          );
          return dateWithoutTime >= currentDateWithoutTime;
        });

        return filteredDates;
      }

      return dates;
    },
    isReturnType(): boolean {
      return ROUTE_TYPE.RETURN === this.$route.query?.route_type;
    },
    filteredReturnFlights() {
      if (!this.currentJourneyLeg) {
        return [];
      }

      if (!this.nextJourneyLeg) {
        return [];
      }

      const flightOptions = this.currentJourneyLeg
        .flight_options as FlightOption[];

      const nextFlightOptions = this.nextJourneyLeg
        .flight_options as FlightOption[];

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.matchedValues = [];

      flightOptions.forEach((value, index) => {
        value.fare_options.forEach((internalValue, internalIndex) => {
          internalValue.next_fare_option_ids.forEach(
            (nextFareOptionIds, nextFareOptionIdsIndex) => {
              nextFlightOptions.forEach((nextvalue, nextKaIndex) => {
                nextvalue.fare_options.forEach(
                  (nextKiinternalValue, nextKiinternalIndex) => {
                    if (
                      this.getFirstHalf(
                        nextKiinternalValue.pre_booking_token
                      ) === nextFareOptionIds
                    ) {
                      this.matchedValues.push({
                        FO_1: internalValue,
                        FO_2: nextvalue.fare_options[nextKiinternalIndex],
                        ag_price_difference:
                          nextKiinternalValue.price.ag_price_difference?.value,
                        ag_gross_fare:
                          nextKiinternalValue.price.ag_gross_fare?.value,
                        base_fare: nextKiinternalValue.price.base_fare.value,
                        gross_fare: nextKiinternalValue.price.gross_fare.value,
                        tax: nextKiinternalValue.price.tax.value,
                        firstLegAirport: this.currentJourneyLeg.origin.airport,
                        secondLegAirport:
                          this.currentJourneyLeg.destination.airport,
                        currency: nextKiinternalValue.price.base_fare.currency,
                        source: this.currentJourneyLeg.origin.iata_code,
                        destination:
                          this.currentJourneyLeg.destination.iata_code,
                        name: value.airline.name,
                        logo: value.airline.logo,
                        price: nextKiinternalValue.price.ag_gross_fare?.value
                          ? nextKiinternalValue.price.ag_gross_fare?.value
                          : nextKiinternalValue.price.gross_fare.value,
                        pre_booking_token:
                          nextKiinternalValue.pre_booking_token,
                        next_booking: nextFareOptionIds,
                        rbdFirst: internalValue.rbd,
                        rbdSecond: nextKiinternalValue.rbd,
                        goingFlightNo: value.flight_numbers[0],
                        comingFlightNo: nextvalue.flight_numbers[0],
                        goingBagage: internalValue.baggage_info,
                        comingBagage: nextKiinternalValue.baggage_info,
                        firstLegArrivalTime: this.formatTime(
                          value.arrival_time
                        ),
                        firstLegDepartureTime: this.formatTime(
                          value.departure_time
                        ),
                        secondLegArrivalTime: this.formatTime(
                          nextvalue.arrival_time
                        ),
                        secondLegDepartureTime: this.formatTime(
                          nextvalue.departure_time
                        ),
                        firstLegDuration: this.legDuration(value.duration),
                        secondLegDuration: this.legDuration(nextvalue.duration),
                        departure_date: value.departure_time,
                        arrival_date: nextvalue.arrival_time,
                        code: value.airline.code,
                        provider: value.provider,
                      });
                    }
                  }
                );
              });
            }
          );
        });
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.matchedValues.sort((a, b) => a.price - b.price);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.matchedValues;
    },
    savedPreferences(): IProfilePreferences {
      const savedPreferences = localStorage.getItem("user-preferences");

      if (savedPreferences) {
        return JSON.parse(savedPreferences);
      }

      return this.$store.state.preferences;
    },
  },

  methods: {
    isDateActive(departureDate: Date) {
      const urlDate = this.$route.query.trips as string;
      urlDate?.toString() || "";

      const [origin, destination, departure_date, return_date] =
        urlDate.split(",");

      // Parse departure_date to match the format "2024-02-14"
      const formattedDepartureDate = new Date(departureDate)
        .toISOString()
        .slice(0, 10);

      if (departure_date === formattedDepartureDate) {
        return true;
      } else {
        return false;
      }
    },

    isFlightsLoaded() {
      const flightsLength = this.filteredFlights
        ? this.filteredFlights.length > 0
        : false;
      const returnFlightsLength = this.filteredReturnFlights
        ? this.filteredReturnFlights.length > 0
        : false;

      return this.isReturnType && this.isPairView
        ? returnFlightsLength
        : flightsLength;
    },
    resetPolling() {
      this.pollingLimit = 0;
      this.pollMessage = "";
      this.pollMessageIndex = 0;
      clearTimeout(this.timeoutId);
    },
    handleTimerTimeOut(value: boolean) {
      this.timerTimeOuted = value;
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    getFirstHalf(str: string) {
      const indexOfUnderscore = str.indexOf("_");

      if (indexOfUnderscore !== -1) {
        return str.substring(0, indexOfUnderscore);
      } else {
        return str;
      }
    },
    async fetchFlights(poll_id?: string) {
      if (!poll_id) {
        this.$store.commit("clearSelectedFlightLegs");
        this.isPollingLimitReached = false;
      }

      const {
        route_type,
        adult,
        child,
        infant,
        cabin_class,
        from_chip,
        trips,
      } = this.$route.query;
      const tripsString = trips?.toString() || "";

      const [origin, destination, departure_date, return_date] =
        tripsString.split(",");

      this.tripDate = formatTripDates(tripsString, route_type as string);

      const normalFlightPayload = {
        origin,
        destination,
        departure_date,
        return_date: return_date ? return_date : null,
      };

      const legs = formatMultiCityQueryParamToArray(trips as string);

      const multiCityFlightPayload = {
        legs,
      };

      const payload = {
        route_type: route_type,
        traveler_count: {
          adult_count: adult,
          child_count: child,
          infant_count: infant,
        },
        cabin_class: cabin_class,
        from_chip: from_chip,
        non_stop_flight: false,
        ...([ROUTE_TYPE.ONEWAY, ROUTE_TYPE.RETURN].includes(
          route_type as ROUTE_TYPE
        ) && {
          ...normalFlightPayload,
        }),
        ...(route_type === ROUTE_TYPE.MULTI_CITY && {
          ...multiCityFlightPayload,
        }),
        poll_id,
      };

      await this.$store.dispatch("fetchFlights", payload);
      this.pollingLimit += 1;
    },
    handleRefreshResults() {
      this.timerTimeOuted = false;
      this.fetchFlights();
    },
    handleFilters(
      journeyLeg: JourneyLeg,
      filter: SelectedFlightSearchQueryFilters
    ) {
      let tempFilteredData: Array<FlightOption> = this.filteredFlights;

      tempFilteredData = filterFlightsByAirlines(
        journeyLeg.flight_options,
        filter.airlines
      );
      tempFilteredData = filterFlightsByDepartureTime(
        tempFilteredData,
        filter.departureTime
      );
      tempFilteredData = filterFlightsByStops(tempFilteredData, filter.stops);
      tempFilteredData = filterFlightsByPrice(
        tempFilteredData,
        filter.priceRange[0],
        filter.maxPriceRangeSlider ?? 0
      );

      return tempFilteredData;
    },
    handleRemoveChip(
      index: number,
      filter: "airlines" | "stops" | "departureTime" | "priceRange"
    ) {
      const selectedFilter = this.selectedFilters[filter];
      selectedFilter.splice(index, 1);
    },
    filterFlightsByFareOptionIds(
      flightOptions: FlightOption[],
      nextFareIds: Array<string>
    ): Array<FlightOption> {
      return flightOptions.filter((item) => {
        item.fare_options = item.fare_options.filter((fare_opt) => {
          return nextFareIds.includes(fare_opt.pre_booking_token.split("_")[0]);
        });

        return item.fare_options.length ? true : false;
      });
    },
    handleFlightFareSelect(fareOption: FareOption, flightOption: FlightOption) {
      this.selectedFareForDialog = fareOption;
      this.selectedFlightItemForDialog = flightOption;
    },
    handleCloseBookingDialog() {
      this.isBookingDialogOpen = false;
      this.selectedFareForDialog = null;
      this.selectedFlightItemForDialog = null;
    },
    handleUpdateRange(val: number) {
      this.selectedFilters.maxPriceRangeSlider = val;
    },
    handleChangeSearchDialogClose() {
      this.isChangeSearchDialogOpen = false;
    },
    handleChangeSearchDialogOpen() {
      this.isChangeSearchDialogOpen = true;
      this.allowRouterQueryRequest = true;
    },
    updateSelectedFilters(updatedFilters: SelectedFlightSearchQueryFilters) {
      this.selectedFilters = updatedFilters;
    },
    hanldeDateChipClick(data: QuickDateChip) {
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.AG_QUICK_DATE_SEARCH_CHIP
      );
      this.allowRouterQueryRequest = true;
      const currentQueryParams = { ...this.$route.query };
      const splitedTripsQueryParam = (
        currentQueryParams?.trips as string
      ).split(",");
      if (data?.isReturn) {
        splitedTripsQueryParam[2] = format(
          data?.departureDate,
          FORMAT_YYY_MM_DD
        );
        splitedTripsQueryParam[3] = format(data?.arrivalDate, FORMAT_YYY_MM_DD);
      } else {
        splitedTripsQueryParam[2] = format(
          data?.departureDate,
          FORMAT_YYY_MM_DD
        );
      }

      currentQueryParams.trips = splitedTripsQueryParam.join(",");
      this.$router.push(
        `${PATH.FLIGHTS_SEARCH_RESULT}${formatQueryPath(currentQueryParams)}`
      );
    },
    getRandomTravelMessageHandler() {
      const index = this.pollMessageIndex;

      this.pollMessageIndex += 1;

      if (index >= this.pollMessages.length - 1) {
        this.pollMessageIndex = 0;
      }

      return this.pollMessages[index];
    },

    onPairViewToggleHandler(value: boolean) {
      this.isPairView = value;
      this.onUpdatePreferencesHandler();
    },
    async onUpdatePreferencesHandler() {
      const payload = {
        flight_search_pairs_view: this.isPairView,
      };

      const agentId = (this.user as any).agent.agent_id as string;

      await this.$store.dispatch("updatePreferences", {
        body: payload,
        agentId,
      });
    },
    onSyncPreferencesHandler() {
      this.isPairView = this.savedPreferences.flight_search_pairs_view;
    },
  },

  watch: {
    flightsResponse: {
      handler: function (response: FlightResponse) {
        const keepPolling = response.keep_polling;
        const pollId = response.poll_id;

        if (
          keepPolling &&
          pollId &&
          this.isPolling &&
          !(this.pollingLimit === this.maxPollingLimit)
        ) {
          this.pollMessage = this.getRandomTravelMessageHandler();

          this.timeoutId = setTimeout(() => {
            this.fetchFlights(pollId);
          }, this.pollingInterval) as unknown as number;
        } else {
          this.isPollingLimitReached = true;
          this.resetPolling();
        }
      },
      deep: true,
    },

    // Watches filters on flight Search
    selectedFilters: {
      handler: function (filter) {
        const journeyLeg = this.currentJourneyLeg;
        const filteredData = this.handleFilters(journeyLeg, filter);

        this.filteredFlights = filteredData;
      },
      deep: true,
    },
    // Loads filters based on the results to the local state
    "$store.state.flightModule.selectedFlightFilters": {
      handler: function (selectedFilters) {
        this.selectedFilters = _.cloneDeep(selectedFilters);
      },
    },
    // Watches when fare is selected
    "$store.state.flightModule.selectedFares": {
      handler: function (fares: FareOption[]) {
        const { currentLegIndex, lastLegIndex, flights } =
          this.$store.state.flightModule;

        if (fares.length) {
          const fare = fares[fares.length - 1];
          const allJourneyLegs = this.$store.getters.allJourneyLegs;

          if (currentLegIndex < lastLegIndex) {
            const nextLegFareIds = fare.next_fare_option_ids;

            const nextLeg = _.cloneDeep(
              allJourneyLegs[currentLegIndex + 1] as JourneyLeg
            );

            if (nextLegFareIds.length) {
              const nextLegFlights = this.filterFlightsByFareOptionIds(
                nextLeg.flight_options,
                nextLegFareIds
              );

              this.$store.commit("saveFilteredLegsByFareIds", nextLegFlights);

              this.filteredFlights = nextLegFlights;
            } else {
              this.filteredFlights = nextLeg.flight_options;
            }
            this.$store.commit("saveCurrentLegIndex", currentLegIndex + 1);
          }
        } else {
          this.filteredFlights = flights;
        }
      },
      deep: true,
    },
    selectedFareForDialog: {
      handler: function (newVal, oldVal) {
        const newItem = _.cloneDeep(newVal);
        const oldItem = _.cloneDeep(oldVal);

        if (newItem && newItem !== oldItem) {
          this.isBookingDialogOpen = true;
        } else {
          this.isBookingDialogOpen = false;
        }
      },
    },
    "$route.query": {
      handler: function () {
        if (this.allowRouterQueryRequest) {
          this.fetchFlights();
          this.allowRouterQueryRequest = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    const storedUser = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    this.user = storedUser();

    this.onSyncPreferencesHandler();
  },
  mounted() {
    this.$store.dispatch("fetchAirports", "Pakistan");
  },
  unmounted() {
    this.$store.commit("clearSelectedFlightLegs");
    this.resetPolling();
  },
  beforeRouteUpdate() {
    this.$store.commit("clearSelectedFlightLegs");
    this.resetPolling();
  },
  beforeMount() {
    this.$store.commit("clearSelectedFlightLegs");
  },
});
</script>

<style scoped lang="scss">
.date-chip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
}

.flights-loader .line,
.flights-loader .word {
  width: fit-content;
  position: relative;
  height: 24px;
  background: #d9d9d980;
  color: transparent;
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.flights-loader .line {
  width: 100%;
}

.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: skeleton-loading 1.5s infinite;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%);
  }
}
</style>
