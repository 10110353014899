import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";

export const ROLES_AND_PERMISSION_ON_ROUTES = {
  ORGANIZATION: {
    ROLES: [USER_ROLES.AG_SUPER_USER],
    PERMISSIONS: [],
  },
  ORGANIZATION_DETAIL: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_USERS],
  },
  PREFERENCE: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SELF_PREFERENCES],
  },
  WEBSITE_MANAGEMENT: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SELF_PREFERENCES],
  },
  WHATSAPP_MANAGEMENT: {
    ROLES: [USER_ROLES.AGENT, USER_ROLES.AG_SUPER_USER],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SELF_PREFERENCES],
  },
  PASSENGER_MANAGEMENT: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SELF_PREFERENCES],
  },
  ORGANIZATION_SNAPSHOT: {
    ROLES: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.FINANCE,
      USER_ROLES.OPERATIONS,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [],
  },
  SECTOR_SNAPSHOT: {
    ROLES: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.FINANCE,
      USER_ROLES.OPERATIONS,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [],
  },
  SALES_SNAPSHOT: {
    ROLES: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.FINANCE,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [],
  },
  DAILY_ISSUANCE: {
    ROLES: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.AG_SUPER_USER,
      USER_ROLES.OPERATIONS,
    ],
    PERMISSIONS: [],
  },
  AGENT_LIST: {
    ROLES: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.FINANCE,
      USER_ROLES.OPERATIONS,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [],
  },
  AGENT_MANAGEMENT: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_MANAGE_ORGANIZATION_USERS],
  },
  DASHBOARD: {
    ROLES: [
      //   TODO: Removing for now to allow every logged in user to see a dashboard page
    ],
    PERMISSIONS: [],
  },
  COMPLETE_YOUR_APPLICATION: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [],
  },
  SOA: {
    ROLES: [
      USER_ROLES.FINANCE,
      USER_ROLES.AGENT,
      USER_ROLES.AG_SUPER_USER,
      USER_ROLES.SALES,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_SOA_ITEMS],
  },
  USERPROFILE: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [],
  },
  FIT: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_FIT],
  },
  FLIGHTS: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_SEARCH_FLIGHTS],
  },
  FLIGHT_ISSUANCE: {
    ROLES: [
      USER_ROLES.AGENT,
      USER_ROLES.SUB_AGENT,
      USER_ROLES.EMPLOYEE,
      USER_ROLES.AG_SUPER_USER,
      USER_ROLES.OPERATIONS,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_ISSUE_FLIGHT_TICKET],
  },
  FLIGHTS_BOOKING_FORM: {
    ROLES: [USER_ROLES.AGENT, USER_ROLES.SUB_AGENT, USER_ROLES.EMPLOYEE],
    PERMISSIONS: [PERMISSIONS.CAN_BOOK_FLIGHTS],
  },
  AGENT_PRICING: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_AGENT_PRICING],
  },
  MY_BOOKINGS: {
    ROLES: [USER_ROLES.AGENT, USER_ROLES.OPERATIONS, USER_ROLES.AG_SUPER_USER],
    PERMISSIONS: [],
  },
  ISSUANCE_LOGS: {
    ROLES: [USER_ROLES.OPERATIONS, USER_ROLES.AG_SUPER_USER],
    PERMISSIONS: [],
  },
  PAYMENTS: {
    ROLES: [USER_ROLES.AGENT, USER_ROLES.OPERATIONS],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_SELF_MADE_PAYMENTS],
  },
  DIGITAL_PAYMENTS_BY_SECTORS: {
    ROLES: [
      USER_ROLES.FINANCE,
      USER_ROLES.AG_SUPER_USER,
      USER_ROLES.SUPER_ADMIN,
    ],
    PERMISSIONS: [],
  },
  CREATE_PAYMENT: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_CREATE_PAYMENT],
  },
  AG_TUTORIALS: {
    ROLES: [],
    PERMISSIONS: [],
  },
  FDG_FLIGHTS: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_GROUP_FLIGHTS],
  },
  FDG_UMRAH: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_GROUP_TOURS],
  },
  CREDIT_LIMIT_MANAGEMENT: {
    ROLES: [
      USER_ROLES.AGENT,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_CREDIT_LIMIT],
  },
  CREATE_CREDIT_LIMIT_REQUEST: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_REQUEST_CREDIT_LIMIT],
  },
  CREDIT_LIMIT_REQUEST_DETAIL: {
    ROLES: [USER_ROLES.SALES],
    PERMISSIONS: [],
  },
  HOTELS: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_SEARCH_HOTEL],
  },
  SABRE_SEGMENT: {
    ROLES: [
      USER_ROLES.AGENT,
      USER_ROLES.SUB_AGENT,
      USER_ROLES.OPERATIONS,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_AGENT_PRICING],
  },
  NEWS: {
    ROLES: [USER_ROLES.AGENT, USER_ROLES.SUB_AGENT],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_AGENTS_NEWS],
  },
  FARE_MANAGEMENT: {
    ROLES: [
      USER_ROLES.AGENT,
      USER_ROLES.SUB_AGENT,
      USER_ROLES.EMPLOYEE,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_ISSUE_FLIGHT_TICKET],
  },
  AG_PRICING: {
    ROLES: [
      USER_ROLES.AGENT,
      USER_ROLES.OPERATIONS,
      USER_ROLES.SALES,
      USER_ROLES.AG_SUPER_USER,
    ],
    PERMISSIONS: [PERMISSIONS.CAN_VIEW_AGENT_PRICING],
  },
  AG_FOUR_ZERO_FOUR: {
    ROLES: [],
    PERMISSIONS: [],
  },
  MANAGE_QUOTATIONS: {
    ROLES: [],
    PERMISSIONS: [],
  },
  CURRENCY_CONVERSION: {
    ROLES: [USER_ROLES.OPERATIONS, USER_ROLES.SALES, USER_ROLES.AG_SUPER_USER],
    PERMISSIONS: [],
  },
  SALES_MY_TEAM: {
    ROLES: [USER_ROLES.SALES],
    PERMISSIONS: [],
  },
  ORGANIZATION_DOCUMENTS: {
    ROLES: [USER_ROLES.AGENT],
    PERMISSIONS: [],
  },
  SAAS_ORGANIZATIONS: {
    ROLES: [
      USER_ROLES.FINANCE,
      USER_ROLES.AG_SUPER_USER,
      USER_ROLES.OPERATIONS,
    ],
    PERMISSIONS: [],
  },
};
