import { createStore, ModuleTree } from "vuex";
import flightIssuanceModule from "@/modules/FlightIssuance/vuex";
import flightModule from "@/modules/Flight/vuex";
import flightBookingModule from "@/modules/Booking/vuex";
import profileModule from "@/modules/Profile/vuex";

const modules: ModuleTree<any> = {
  flightIssuanceModule,
  flightModule,
  flightBookingModule,
  profileModule,
};
export default createStore({
  modules,
});
