import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MulticityFlightItem = _resolveComponent("MulticityFlightItem")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_row = _resolveComponent("ag-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multiCityLegs, (leg, index) => {
      return (_openBlock(), _createBlock(_component_MulticityFlightItem, {
        errors: _ctx.errors,
        "multicity-leg": leg,
        index: index,
        key: index
      }, null, 8 /* PROPS */, ["errors", "multicity-leg", "index"]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.showAddFlightBtn)
      ? (_openBlock(), _createBlock(_component_AgDiv, {
          key: 0,
          "test-id": "",
          class: "add_multiple_flight"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AGButton, {
              onClick: _ctx.addFlight,
              "test-id": "",
              variant: "link"
            }, {
              default: _withCtx(() => [
                _createTextVNode("+ Add Flight")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_ag_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ag_column, {
          xs: "12",
          sm: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AgDiv, {
              "test-id": "",
              class: "text-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_g_button, {
                  onClick: _ctx.onFlightSearch,
                  isLoading: _ctx.$store.getters.isFlightsLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Search Flights")
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "isLoading"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}