<template>
  <AgCard class="flightFilterWrap">
    <AgDiv class="head"> Filter </AgDiv>
    <!-- **** Price Range **** -->
    <AgDiv class="d-flex">
      <AgFilterDropdown test-id="" label="Price Range (PKR)">
        <template #Items>
          <AgPriceRange
            @update:rangeSliderValue="handleUpdateRange"
            :min="localFilters.priceRange[0]"
            :max="localFilters.priceRange[1]"
            :thumb-size="20"
            thumb-label="always"
            test-id=""
          />
        </template>
      </AgFilterDropdown>

      <!-- **** Airline **** -->
      <AgFilterDropdown
        test-id=""
        label="Airlines"
        @click="(e:MouseEvent) => e.stopPropagation()"
      >
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in filters.airlines"
            @click="(e:MouseEvent) => e.stopPropagation()"
            v-model="localFilters.airlines"
            :key="index"
            :value="item.value"
            :label="item.label"
            test-id=""
          />
        </template>
      </AgFilterDropdown>

      <!-- **** Stops **** -->
      <AgFilterDropdown test-id="" label="Stops">
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in filters.stops"
            @click="(e:MouseEvent) => e.stopPropagation()"
            v-model="localFilters.stops"
            :key="index"
            :value="item.value"
            :label="item.label"
            test-id=""
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>

      <!-- **** Departure Time **** -->
      <AgFilterDropdown test-id="" label="Departure Time">
        <template #Items>
          <AgCheckbox
            v-for="(item, index) in filters.departureTime"
            v-model="localFilters.departureTime"
            @click="(e:MouseEvent) => e.stopPropagation()"
            :key="index"
            :value="item"
            :label="item"
            test-id=""
          ></AgCheckbox>
        </template>
      </AgFilterDropdown>
    </AgDiv>

    <!-- **** Change Search **** -->
    <AGButton
      test-id=""
      @click="handleChangeSearchDialogOpen"
      variant="link"
      class="change_search_btn_modal"
      >Change Search</AGButton
    >
  </AgCard>
</template>

<script lang="ts">
import { SelectedFlightSearchQueryFilters } from "@/ag-flight-components/types/Flights";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "FlightFilters",
  props: {
    selectedFilters: {
      type: Object as PropType<SelectedFlightSearchQueryFilters>,
      required: true,
    },
    handleUpdateRange: {
      type: Function,
      required: true,
    },
    handleChangeSearchDialogOpen: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filters() {
      return this.$store.getters.filters;
    },
    localFilters() {
      return this.selectedFilters;
    },
  },
  watch: {
    localFilters: {
      handler(newFilters: SelectedFlightSearchQueryFilters) {
        this.$emit("updateSelectedFilters", newFilters);
      },
      deep: true,
    },
  },
});
</script>
