import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlightSearchOptions = _resolveComponent("FlightSearchOptions")!
  const _component_MulticityFlightSearch = _resolveComponent("MulticityFlightSearch")!
  const _component_NormalFlightSearch = _resolveComponent("NormalFlightSearch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FlightSearchOptions),
    (_ctx.isMulticitySearch)
      ? (_openBlock(), _createBlock(_component_MulticityFlightSearch, {
          key: 0,
          errors: _ctx.multicityError,
          onFlightSearch: _ctx.onFlightSearch
        }, null, 8 /* PROPS */, ["errors", "onFlightSearch"]))
      : (_openBlock(), _createBlock(_component_NormalFlightSearch, {
          key: 1,
          errors: _ctx.normalError,
          onFlightSearch: _ctx.onFlightSearch
        }, null, 8 /* PROPS */, ["errors", "onFlightSearch"]))
  ], 64 /* STABLE_FRAGMENT */))
}