import { IProfileState } from "@/ag-portal-common/interfaces/profile.interface";

const profileState: IProfileState = {
  isUpdatingPreferences: false,
  preferences: {
    contact_number: "",
    whatsapp_number: "",
    flight_search_pairs_view: false,
  },
};

export default profileState;
