import { addDays } from "date-fns";

import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { CABIN_CLASS } from "@/ag-flight-components/enums/cabin_class";
import {
  AMOUNT_TYPE,
  FARE_TYPE,
} from "@/ag-flight-components/enums/fare_calculator";

const flightState: IFlightSearch = {
  isPolling: true,
  isFlightFetching: false,
  isAirportFetching: false,
  isLoading: false,
  currentLegIndex: 0,
  lastLegIndex: 0,
  flightsResponse: {
    is_international: false,
    route_type: ROUTE_TYPE.ONEWAY,
    journey_legs: [],
    poll_id: null,
    keep_polling: true,
  },
  flights: [],
  airports: [],
  selectedFlightFilters: {
    airlines: [],
    stops: [],
    priceRange: [],
    departureTime: [],
  },
  amountType: AMOUNT_TYPE.FIXED,
  fareType: FARE_TYPE.GROSS,
  fareCalculatorAmount: null,
  selectedFlightLegs: [],
  selectedFares: [],
  selectedFilteredLegsByFareIds: [],

  /********* Flight Search Query State *********/
  origin: null,
  destination: null,
  departure_date: new Date(),
  return_date: new Date(),
  route_type: ROUTE_TYPE.ONEWAY,
  adult_count: 1,
  child_count: 0,
  infant_count: 0,
  cabin_class: CABIN_CLASS.ECONOMY,
  multiCityLegs: [
    {
      origin: null,
      destination: null,
      departure_date: new Date(),
    },
    {
      origin: null,
      destination: null,
      departure_date: addDays(new Date(), 1),
    },
  ],
  filters: {
    airlines: [],
    stops: [],
    priceRange: [],
    departureTime: [],
  },
};

export default flightState;
