import { GetterTree } from "vuex";
import { IFlightSearch } from "@/ag-flight-components/interfaces/IFlightSearch.interface";

const getters: GetterTree<IFlightSearch, IFlightSearch> = {
  isPolling: (state): boolean => state.isPolling,
  flightsResponse: (state) => state.flightsResponse,
  isFlightsLoading: (state): boolean => state.isFlightFetching,
  isAirportFetching: (state): boolean => state.isAirportFetching,
  airports: (state) => state.airports,
  flights: (state) => state.flights,
  filters: (state) => state.filters,
  currentJourneyLeg: (state) =>
    state.flightsResponse.journey_legs[state.currentLegIndex],
  nextJourneyLeg: (state) => state.flightsResponse.journey_legs[1],
  allJourneyLegs: (state) => state.flightsResponse.journey_legs,
  allSelectdFlightFilters: (state) => state.selectedFlightFilters,
  selectedAmountType: (state) => state.amountType,
  allSelectedFlightLegs: (state) => state.selectedFlightLegs,
  currentLegIndex: (state) => state.currentLegIndex,
  currentRouteType: (state) => state.route_type,
  getMulticityLegs: (state) => state.multiCityLegs,
};

export default getters;
