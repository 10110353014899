<template>
  <MLayout
    :is-agent-user="isAgentUser()"
    :fp-amount-details="fpAmountDetails"
    :organisation-title="getOrganisationNameHandler()"
    :organisation-logo="getOrganisationLogoHandler()"
    :page-title="pageTitle"
    :is-loading-financial-profile="isLoadingFinances"
    :financial-profile-status="fpAccountStatus"
    :financial-profile-status-label="fpAccountStatusLabel"
    :header-menu-list="headerMenuList"
    :sidebar-items="sidebarItems"
    @click:financial-profile="onClickFinancialBreakdownTrack"
    @reload:financial-profile="onRefreshBalanceHandler"
  >
    <RouterView v-slot="{ Component, route }">
      <Transition :appear="true" name="router-view-fade-transition">
        <component :is="Component" :key="route.path" />
      </Transition>
    </RouterView>

    <div class="fab hide-on-printing" @click="onClickWidgetTrack('whatsapp')">
      <a :href="getWhatsAppContactLink" target="_blank" class="whatsappIcon">
        <i class="fab fa-whatsapp"></i>
      </a>
    </div>
  </MLayout>
  <AgPopupAlert />
  <FreshDeskWidget v-if="isAgentOrSubAgentUser()" />
</template>

<script lang="ts">
// Vue Imports
import { defineComponent, inject } from "vue";

// Component Imports
import AgPopupAlert from "../components/AgPopupAlert.vue";
import FreshDeskWidget from "../components/FreshDeskWidget.vue";
import { MLayout } from "@aeroglobe/ag-core-ui";

// Service Imports
import analyticsService from "../services/local_analytics.service";
import AgentFinanceService from "../widgets/AgentFinancesWidget/services";

// Constant Imports
import { ANALYTICS_COMMON_EVENTS } from "../constants/analytics";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { DUBAI_SECTOR, RIYADH_SECTOR } from "../constants/sectors";
import { PATH } from "../constants/path";
import { AUTH_CONTEXT_KEYS } from "../constants/authContextKeys";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "../configs/rolesAndPermissionsMappingOnRoutes";
import {
  ORGANIZATION_TYPES,
  SAAS_ELITE_ORGANIZATION_STATUS,
} from "../enums/SAAS_ELITE_ORGANIZATION_STATUS";
import { AUTH_EVENTS, authBus } from "../eventBusses/auth";
import {
  AGENT_FINANCES_EVENTS,
  agentFinanceBus,
} from "../eventBusses/agentFinances";

// Enum Imports
import { USER_ROLES } from "../enums/USER_ROLES";
import { PERMISSIONS } from "../enums/PERMISSIONS";

// Type Imports
import {
  FPAmountDetails,
  SidebarItem,
} from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import { MenuItem } from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import { IOrganizationPreference } from "../interfaces/organization.interface";
import { IUserV2 } from "../interfaces/user.interface";
import { RouteLocationNormalized } from "vue-router";

// Helper Imports
import {
  isRoleAllowed,
  isUserPermitted,
  parseLocalStorageData,
  formatNumber,
  formatWordIntoCapitalize,
} from "@/ag-portal-common/utils/helpers";

// Interface Imports
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";

export default defineComponent({
  name: "HomeLayout",
  props: {
    title: {
      type: String,
    },
  },
  components: {
    AgPopupAlert,
    FreshDeskWidget,

    MLayout,
  },
  data() {
    return {
      organisationTitle: "Binary Tours",
      organisationLogo: "https://i.imgur.com/1f7NEhm.png",
      pageTitle: "",

      fpAmountDetails: {
        totalAvailableCredits: {
          value: "- -",
          currency: "- -",
        },
      } as FPAmountDetails,

      headerMenuList: [] as MenuItem[],

      sidebarItems: [] as SidebarItem<USER_ROLES, PERMISSIONS>[],

      isDubaiSector: localStorage.getItem("sector") === DUBAI_SECTOR,
      isRiyadhSector: localStorage.getItem("sector") === RIYADH_SECTOR,

      fpAccountStatus: "default" as "error" | "default" | "success" | undefined,
      fpAccountStatusLabel: "- -",
      fpStatusRemarks: "- -",

      isAccountActive: false,
      isLoadingFinances: false,
    };
  },
  created(): void {
    const organisationDetailsPath = `${PATH.ORGANIZATION}/${
      this.organizationPreference()?.organization_id
    }`;
    const routePath = this.$route.path;

    let sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[] = [
      {
        label: "Dashboard",
        active: false,
        icon: "m-dashboard",
        children: [],
        pageTitle: "Overview",
        path: PATH.DASHBOARD,
        roles: ROLES_AND_PERMISSION_ON_ROUTES.DASHBOARD.ROLES,
        permissions: ROLES_AND_PERMISSION_ON_ROUTES.DASHBOARD.PERMISSIONS,
      },
      {
        label: "Sell",
        active: false,
        children: [
          {
            label: "Flights",
            active: false,
            icon: "m-flight",
            children: [],
            pageTitle: "Book Flights",
            path: PATH.FLIGHTS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
          },
          {
            label: "Hotels",
            active: false,
            icon: "m-hotel",
            children: [],
            pageTitle: "Book Hotels",
            path: PATH.HOTELS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
          },
          {
            label: "Group Flights",
            active: false,
            icon: "m-group-flights",
            children: [],
            pageTitle: "Group Bookings",
            path: PATH.FDG_FLIGHTS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
          },
          {
            label: "Umrah / Ziarat",
            active: false,
            icon: "m-umrah-ziarat",
            children: [],
            pageTitle: "Book Umrah Trips",
            path: PATH.FDG_UMRAH,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.PERMISSIONS,
          },
          {
            label: "My Bookings",
            active: false,
            icon: "m-my-bookings",
            children: [],
            pageTitle: "My Bookings",
            path: PATH.MY_BOOKINGS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.PERMISSIONS,
          },
          {
            label: "Issue Ticket",
            active: false,
            icon: "m-issue-ticket",
            children: [],
            pageTitle: "Issue Ticket",
            path: PATH.FLIGHT_ISSUANCE,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.PERMISSIONS,
          },
          {
            label: "Fare Management",
            active: false,
            icon: "m-fare-management",
            children: [],
            pageTitle: "Fare Management",
            path: PATH.FARE_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.PERMISSIONS,
          },
          {
            label: "Sabre Segment",
            active: false,
            icon: "m-sabre-segment",
            children: [],
            pageTitle: "My Sabre Segments",
            path: PATH.SABRE_SEGMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.PERMISSIONS,
          },
          {
            label: "Issuance Logs",
            active: false,
            icon: "m-issuance-logs",
            children: [],
            pageTitle: "Issuance Records",
            path: PATH.ISSUANCE_LOGS_WITHOUT_PNR,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.PERMISSIONS,
          },
          {
            label: "Manage Quotations",
            active: false,
            icon: "m-qoutation-management",
            children: [],
            pageTitle: "Quotation Management",
            path: PATH.MANAGE_QUOTATIONS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.MANAGE_QUOTATIONS.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.MANAGE_QUOTATIONS.PERMISSIONS,
          },
        ],
      },
      {
        label: "Organization Management",
        active: false,
        children: [
          {
            label: "Agent Management",
            active: false,
            icon: "m-agent-management",
            children: [],
            pageTitle: "Agent Management",
            path: PATH.AGENT_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.PERMISSIONS,
          },
          {
            label: "Organizations",
            active: false,
            icon: "m-organisations",
            children: [],
            pageTitle: "Organizational Tools",
            path: PATH.ORGANIZATION,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
          },
          {
            label: "Details",
            active: false,
            icon: "m-details",
            children: [],
            pageTitle: "Details View",
            path: organisationDetailsPath,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_DETAIL.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_DETAIL.PERMISSIONS,
          },
          {
            label: "Agents",
            active: false,
            icon: "m-agents",
            children: [],
            pageTitle: "Agent Listings",
            path: PATH.AGENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.PERMISSIONS,
          },
          {
            label: "Preferences",
            active: false,
            icon: "m-preferences",
            children: [],
            pageTitle: "Organisation Preferences",
            path: PATH.PREFERENCE,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.PERMISSIONS,
          },
          {
            label: "Website Management",
            active: false,
            icon: "m-website-management",
            children: [],
            pageTitle: "Website Management",
            path: PATH.WEBSITE_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.PERMISSIONS,
          },
          {
            label: "Passenger Management",
            active: false,
            icon: "m-passenger-management",
            children: [],
            pageTitle: "Passenger Management",
            path: PATH.PASSENGER_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.PERMISSIONS,
          },
          {
            label: "Sector Snapshot",
            active: false,
            icon: "m-sector-snapshot",
            children: [],
            pageTitle: "Sector Insights",
            path: PATH.SECTOR_SNAPSHOT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.PERMISSIONS,
          },
          {
            label: "Organization Snapshot",
            active: false,
            icon: "m-organisation-snapshot",
            children: [],
            pageTitle: "Organization Insights",
            path: PATH.ORGANIZATION_SNAPSHOT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.PERMISSIONS,
          },
          {
            label: "Whatsapp Management",
            active: false,
            icon: "m-send-message",
            children: [],
            pageTitle: "Messaging",
            path: PATH.WHATSAPP_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.PERMISSIONS,
          },
          {
            label: "Self Onboarding Organizations",
            active: false,
            icon: "m-organisations",
            children: [],
            pageTitle: "Onboarding Progress",
            path: PATH.SAAS_ELITE_ORGANIZATION,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.SAAS_ORGANIZATIONS.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.SAAS_ORGANIZATIONS.PERMISSIONS,
          },
        ],
      },
      {
        label: "Finance",
        active: false,
        badge: "NEW",
        children: [
          {
            label: "Payment Management",
            active: false,
            icon: "m-payment-management",
            children: [],
            pageTitle: "Payment Handling",
            path: PATH.PAYMENTS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.PERMISSIONS,
          },
          {
            label: "Credit Limit Management",
            active: false,
            icon: "m-credit-limit-management",
            children: [],
            pageTitle: "Credit Control",
            path: PATH.CREDIT_LIMIT_MANAGEMENT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT
                .PERMISSIONS,
          },
          {
            label: "Statement Of Accounts",
            active: false,
            icon: "m-soa",
            children: [],
            pageTitle: "Account Statements",
            path: PATH.SOA,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.SOA.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.SOA.PERMISSIONS,
          },
          {
            label: "AG Pricing",
            active: false,
            badge: "NEW",
            icon: "m-ag-pricing",
            children: [],
            pageTitle: "Aeroglobe Pricing",
            path: PATH.AG_PRICING,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.PERMISSIONS,
          },
          {
            label: "Currency Conversion",
            active: false,
            icon: "m-currency-conversion",
            children: [],
            pageTitle: "Currency Conversion",
            path: PATH.CURRENCY_CONVERSION,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.PERMISSIONS,
          },
          {
            label: "Sales Snapshot",
            active: false,
            icon: "m-sales-snapshot",
            children: [],
            pageTitle: "Sales Insights",
            path: PATH.SALES_SNAPSHOT,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.PERMISSIONS,
          },
          {
            label: "Digital Payments",
            active: false,
            icon: "m-digital-payments",
            children: [],
            pageTitle: "Digital Transactions",
            path: PATH.DIGITAL_PAYMENTS,
            roles:
              ROLES_AND_PERMISSION_ON_ROUTES.DIGITAL_PAYMENTS_BY_SECTORS.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.DIGITAL_PAYMENTS_BY_SECTORS
                .PERMISSIONS,
          },
        ],
      },
      {
        label: "Daily Issuance",
        active: false,
        icon: "m-daily-issuance",
        children: [],
        pageTitle: "Issuance Overview",
        path: PATH.DAILY_ISSUANCE,
        roles: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.ROLES,
        permissions: ROLES_AND_PERMISSION_ON_ROUTES.DAILY_ISSUANCE.PERMISSIONS,
      },
      {
        label: "My Team",
        active: false,
        icon: "m-my-team",
        children: [],
        pageTitle: "Team Management",
        path: PATH.MY_TEAM,
        roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.ROLES,
        permissions: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.PERMISSIONS,
      },
      {
        label: "More",
        active: false,
        children: [
          {
            label: "Aeroglobe Tutorials",
            active: false,
            icon: "m-ag-tutorials",
            children: [],
            pageTitle: "Learning Resources",
            path: PATH.AEROGLOBE_TUTORIALS,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_TUTORIALS.ROLES,
            permissions:
              ROLES_AND_PERMISSION_ON_ROUTES.AG_TUTORIALS.PERMISSIONS,
          },
          {
            label: "Profile",
            active: false,
            icon: "m-profile",
            children: [],
            pageTitle: "User Profile",
            path: PATH.USER_PROFILE,
            roles: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.ROLES,
            permissions: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.PERMISSIONS,
          },
        ],
      },
      {
        label: "Complete Your Application Progress",
        active: false,
        icon: "m-complete-application",
        children: [],
        pageTitle: "Application Progress",
        path: PATH.ORGANIZATION_DOCUMENTS,
        roles: ROLES_AND_PERMISSION_ON_ROUTES.COMPLETE_YOUR_APPLICATION.ROLES,
        permissions:
          ROLES_AND_PERMISSION_ON_ROUTES.COMPLETE_YOUR_APPLICATION.PERMISSIONS,
      },
    ];

    sidebarItems = this.onTransformSidebarItemsAllowedHandler(sidebarItems);

    sidebarItems = this.onTransformSidebarItemsActiveState(
      routePath,
      sidebarItems
    );

    sidebarItems = this.onEnsureActiveSidebarItem(sidebarItems);

    this.sidebarItems = sidebarItems;

    if (this.isAgentUser()) {
      this.onFetchAgentFinancesHandler();

      agentFinanceBus.on(AGENT_FINANCES_EVENTS.REFRESH, (isFetchNewData) => {
        this.onFetchAgentFinancesHandler(isFetchNewData as boolean);
      });

      this.headerMenuList.push({
        text: "Profle",
        leadingIcon: "m-profile",
        divider: true,
        action: () => {
          this.$router.push(PATH.USER_PROFILE);
        },
      });
    }

    this.headerMenuList.push({
      text: "Logout",
      leadingIcon: "m-logout",
      action: () => {
        authBus.emit(AUTH_EVENTS.LOGOUT);
      },
    });

    this.onAppFaviconUpdateHandler();
    this.onPageTitleUpdateHandler();
  },
  methods: {
    onAppFaviconUpdateHandler() {
      const organisationLogo = this.getOrganisationLogoHandler();
      let link = document.querySelector(
        "link[rel*='icon']"
      ) as HTMLLinkElement | null;

      if (!link) {
        link = document.createElement("link");
        link.rel = "shortcut icon";
        document.head.appendChild(link);
      }

      link.type = "image/x-icon";
      link.href = organisationLogo;
    },
    onClickWidgetTrack(name: string): void {
      const payload = {
        "item-name": name,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.WIDGET_CLICKED,
        payload
      );
    },
    isAgentOrSubAgentUser(): boolean {
      const userRole = this.user()?.role_unique_id as USER_ROLES;

      return [
        USER_ROLES.AGENT,
        USER_ROLES.EMPLOYEE,
        USER_ROLES.SUB_AGENT,
      ].includes(userRole);
    },
    isAgentUser(): boolean {
      const userRole = this.user()?.role_unique_id as USER_ROLES;

      return userRole === USER_ROLES.AGENT;
    },
    getWhatsAppContactLink(): string {
      const isDubaiSector = this.isDubaiSector;
      const number = isDubaiSector ? "971503001421" : "923111145623";
      return `https://wa.me/${number}`;
    },

    hasPermissions(
      isAgentUser: boolean,
      permissions: Array<PERMISSIONS>,
      userPermissions: Array<PERMISSIONS>
    ): boolean {
      return isAgentUser ? isUserPermitted(permissions, userPermissions) : true;
    },
    isUserAllowedToView(item: SidebarItem<USER_ROLES, PERMISSIONS>): boolean {
      const userRole = this.user()?.role_unique_id as USER_ROLES;
      const isAgentUser = userRole === USER_ROLES.AGENT;
      const userPermissions = this.permissions() || [];

      const isRolesAllowed = isRoleAllowed(userRole, item.roles || []);
      const hasPermissions = this.hasPermissions(
        isAgentUser,
        item.permissions || [],
        userPermissions
      );

      return isRolesAllowed && hasPermissions;
    },
    isUserDocumentProcessed(
      item: SidebarItem<USER_ROLES, PERMISSIONS>
    ): boolean {
      const organizationData: {
        organization_status: string;
        source: string;
      } = parseLocalStorageData(STORAGE_KEYS.ORGANIZATION);
      const organisationStatus: string | undefined =
        organizationData?.organization_status;

      const isSaasOrganization: boolean =
        organizationData?.source === ORGANIZATION_TYPES.SAAS;
      const isDocumentsPendingPath: boolean =
        item.path === PATH.ORGANIZATION_DOCUMENTS;
      const isInDocumentPendingState =
        organisationStatus !== undefined &&
        organisationStatus === SAAS_ELITE_ORGANIZATION_STATUS.DOCUMENTS_PENDING;

      const isDocumentsProcessed = isDocumentsPendingPath
        ? isSaasOrganization && isInDocumentPendingState
        : true;

      return isDocumentsProcessed;
    },
    isSaudiSector(item: SidebarItem<USER_ROLES, PERMISSIONS>): boolean {
      const paymentManagementOff = ["Credit Limit Management"];

      return (
        (this.isDubaiSector || this.isRiyadhSector) &&
        paymentManagementOff.includes(item.label)
      );
    },

    onTransformSidebarItemsAllowedHandler(
      sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[]
    ): SidebarItem<USER_ROLES, PERMISSIONS>[] {
      let transformedItems: SidebarItem<USER_ROLES, PERMISSIONS>[] = [];

      sidebarItems.forEach((item) => {
        const temp = item;

        if (this.isUserAllowedToView(item)) {
          if (this.isUserDocumentProcessed(item)) {
            if (!this.isSaudiSector(item)) {
              transformedItems.push(temp);
            }
          }
        }

        if (temp.children.length > 0) {
          temp.children = this.onTransformSidebarItemsAllowedHandler(
            temp.children
          );
        }
      });

      return transformedItems;
    },
    onTransformSidebarItemsActiveState(
      routePath: string,
      sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[],
      parentActive = false
    ): SidebarItem<USER_ROLES, PERMISSIONS>[] {
      let currentBestMatchPath = "";

      // Helper function to determine if the current item path is a closer match to the routePath
      const isCloserMatch = (itemPath: string, currentBestMatch: string) => {
        return (
          itemPath.length > currentBestMatch.length &&
          routePath.startsWith(itemPath)
        );
      };

      let updatedSidebarItems = [];

      updatedSidebarItems = sidebarItems.map((item) => {
        const temp = { ...item }; // Clone to avoid direct mutation
        temp.active = false; // Default to not active, will update if it's a closer match

        if (temp.path && isCloserMatch(temp.path, currentBestMatchPath)) {
          currentBestMatchPath = temp.path;
          temp.active = true;
        }

        if (temp.children && temp.children.length > 0) {
          temp.children = this.onTransformSidebarItemsActiveState(
            routePath,
            temp.children,
            temp.active
          );

          // Update the active state based on children's active state
          // If any child is active, parent becomes active, unless it has an icon and wasn't previously matched
          const isAnyChildActive = temp.children.some((child) => child.active);
          if (isAnyChildActive && !temp.icon) {
            temp.active = true;
          }
        }

        // Update pageTitle and document title for the closest match only
        if (temp.active && !parentActive && temp.pageTitle) {
          this.pageTitle = temp.pageTitle;
        }

        return temp;
      });

      // Ensure only the closest match is active, useful in case of multiple matches
      if (currentBestMatchPath !== "") {
        updatedSidebarItems = updatedSidebarItems.map((item) => {
          if (item.path !== currentBestMatchPath && item.active) {
            item.active = false;
          }
          return item;
        });

        localStorage.setItem(
          "last-selected-sidebar-path",
          currentBestMatchPath
        );
      }

      return updatedSidebarItems;
    },
    onEnsureActiveSidebarItem(
      sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[]
    ): SidebarItem<USER_ROLES, PERMISSIONS>[] {
      const lastSelectedPath = localStorage.getItem(
        "last-selected-sidebar-path"
      );
      let foundActive = this.onCheckActiveItemExistence(sidebarItems);

      if (!foundActive && lastSelectedPath) {
        sidebarItems = this.onActivateItemByLastSelectedPath(
          sidebarItems,
          lastSelectedPath
        );
      }

      return sidebarItems;
    },
    onCheckActiveItemExistence(
      sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[]
    ): boolean {
      return sidebarItems.some(
        (item) =>
          item.active ||
          (item.children && item.children.some((subItem) => subItem.active))
      );
    },
    onActivateItemByLastSelectedPath(
      sidebarItems: SidebarItem<USER_ROLES, PERMISSIONS>[],
      lastSelectedPath: string
    ): SidebarItem<USER_ROLES, PERMISSIONS>[] {
      const updatedSidebarItems = sidebarItems.map((item) => {
        const temp = { ...item };

        if (temp.path === lastSelectedPath) {
          temp.active = true;
        } else if (temp.children) {
          temp.children = this.onActivateItemByLastSelectedPath(
            temp.children,
            lastSelectedPath
          );
          temp.active = temp.children.some((child) => child.active);
        }

        if (item.active && item.pageTitle) {
          this.pageTitle = item.pageTitle;
        }

        return temp;
      });

      return updatedSidebarItems;
    },

    getOrganisationNameHandler(): string {
      const DEFAULT_NAME = "Aeroglobe";
      const organization = this.organizationPreference();

      if (!organization) {
        return DEFAULT_NAME;
      }

      return (
        organization.display_name ||
        organization.org_name_to_display ||
        this.user()?.full_name ||
        DEFAULT_NAME
      );
    },
    getOrganisationLogoHandler(): string {
      const organization = this.organizationPreference();
      const DEFAULT_ORGANISATION_LOGO = "https://i.imgur.com/qJO3Th9.png";

      if (!organization) {
        return DEFAULT_ORGANISATION_LOGO;
      }

      return organization.logo ?? DEFAULT_ORGANISATION_LOGO;
    },

    onPageTitleUpdateHandler() {
      const organisationName = this.getOrganisationNameHandler();

      document.title = `${this.pageTitle} - ${organisationName}`;
    },
    async onFetchAgentFinancesHandler(isFetchNewData?: boolean): Promise<void> {
      let organization =
        this.organization() as IOrganizationFromLoginResponse | null;

      this.isLoadingFinances = true;

      const agentFinancesService = new AgentFinanceService();

      if (organization) {
        let defaultFinancialProfile = organization.financial_profiles.find(
          (item) => item.is_default
        );

        if (defaultFinancialProfile) {
          const agentFinances = await agentFinancesService.fetchFinancesById(
            defaultFinancialProfile.public_id,
            isFetchNewData || false
          );

          const currency = localStorage.getItem("currency") ?? "--";
          const totalAvailableCredits = formatNumber(
            agentFinances.currentLimit
          );
          const currentBalance = formatNumber(agentFinances.currentBalance);
          const permanenetCreditLimit = formatNumber(
            agentFinances.permanentCreditLimit
          );
          const totalNotInvoicedTickets = formatNumber(
            agentFinances.totalNotInvoicedTicket
          );
          const availableCredit = formatNumber(agentFinances.availableCredit);
          const temporaryCreditLimit = formatNumber(
            agentFinances.temporaryCreditLimit
          );

          this.fpAmountDetails = {
            totalAvailableCredits: {
              value: totalAvailableCredits,
              currency,
            },
            currentBalance: {
              value: currentBalance,
              currency,
            },
            permanenetCreditLimit: {
              value: permanenetCreditLimit,
              currency,
            },
            totalNotInvoicedTickets: {
              value: totalNotInvoicedTickets,
              currency,
            },
            availableCredit: {
              value: availableCredit,
              currency,
            },
            temporaryCreditLimit: {
              value: temporaryCreditLimit,
              currency,
            },
          };

          this.fpAccountStatusLabel = formatWordIntoCapitalize(
            agentFinances.accountStatus.replaceAll("_", " ")
          );
          this.fpStatusRemarks = formatWordIntoCapitalize(
            agentFinances.statusRemarks.replaceAll("_", " ")
          );

          const fpAccountStatusLabelLC =
            this.fpAccountStatusLabel.toLowerCase();
          this.isAccountActive = fpAccountStatusLabelLC === "active";

          switch (fpAccountStatusLabelLC) {
            case "active":
              this.fpAccountStatus = "success";
              break;

            default:
              this.fpAccountStatus = "error";
              break;
          }
        }
      }

      this.isLoadingFinances = false;
    },

    onClickFinancialBreakdownTrack(): void {
      const payload = {
        "current-limit": `${this.fpAmountDetails.temporaryCreditLimit?.currency} ${this.fpAmountDetails.temporaryCreditLimit?.value}`,
        "current-balance": `${this.fpAmountDetails.currentBalance?.currency} ${this.fpAmountDetails.currentBalance?.value}`,
        "permanent-credit-limit": `${this.fpAmountDetails.permanenetCreditLimit?.currency} ${this.fpAmountDetails.permanenetCreditLimit?.value}`,
        "total-not-invoiced-ticket": `${this.fpAmountDetails.totalNotInvoicedTickets?.currency} ${this.fpAmountDetails.totalNotInvoicedTickets?.value}`,
        "available-credit": `${this.fpAmountDetails.availableCredit?.currency} ${this.fpAmountDetails.availableCredit?.value}`,
        "temporary-credit-limit": `${this.fpAmountDetails.temporaryCreditLimit?.currency} ${this.fpAmountDetails.temporaryCreditLimit?.value}`,
        status: this.fpAccountStatusLabel,
        remarks: this.fpStatusRemarks,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.HEADER_ITEM_FINANCIAL_BREAKDOWN,
        payload
      );
    },
    onRefreshBalanceHandler(): void {
      this.onFetchAgentFinancesHandler(true);
    },
  },
  beforeRouteUpdate(to: RouteLocationNormalized) {
    this.sidebarItems = this.onTransformSidebarItemsActiveState(
      to.path,
      this.sidebarItems
    );

    this.onPageTitleUpdateHandler();
  },
  setup() {
    const permissions = inject(
      AUTH_CONTEXT_KEYS.permissions
    ) as () => Array<PERMISSIONS>;

    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    ) as () => IOrganizationPreference;

    let organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse | null;

    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;

    return {
      permissions,
      user,
      organizationPreference,
      organization,
    };
  },
});
</script>

<style lang="css" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css";

.whatsappIcon {
  text-decoration: none;
  font-size: 50px;
  color: white;
}

.fab {
  position: fixed;
  bottom: 80px;
  right: 25px;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #10b981;
  z-index: 1;
}

.fab i {
  font-size: 36px;
  color: white;
  z-index: 2;
}

@media print {
  .hide-on-printing {
    display: none;
  }
}

.router-view-fade-transition-enter-active,
.router-view-fade-transition-leave-active {
  transition: opacity 0.25s ease;
}

.router-view-fade-transition-enter-from,
.router-view-fade-transition-leave-to {
  opacity: 0;
}
</style>

<style>
.ag_loader {
  background-color: var(--m-container-color) !important;
}
</style>
