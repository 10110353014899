import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconBox = _resolveComponent("AgIconBox")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgFlightDetailCard = _resolveComponent("AgFlightDetailCard")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_FlightRoute = _resolveComponent("FlightRoute")!
  const _component_ag_accordion_panel = _resolveComponent("ag-accordion-panel")!
  const _component_ag_accordion = _resolveComponent("ag-accordion")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createBlock(_component_ag_card, { "test-id": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_ag_accordion, {
        class: "flight_accordion_wrap margin_bottom_0",
        "panel-value": [1, 2]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_accordion_panel, null, {
            flightInfo: _withCtx(() => [
              _createVNode(_component_AgRow, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    sm: "12",
                    md: "3",
                    lg: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgIconBox, {
                        "test-id": "",
                        title: _ctx.renderFlightCount,
                        "sub-title": _ctx.renderDepartureDate(_ctx.flightLeg?.departure_datetime),
                        icon: "departureIcon"
                      }, null, 8 /* PROPS */, ["title", "sub-title"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    sm: "12",
                    md: "9",
                    lg: "10"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgFlightDetailCard, {
                        "test-id": "",
                        "airline-logo": _ctx.flightLeg.segment[0].operating_airline?.logo,
                        "airline-code": _ctx.flightLeg?.segment[0].flight_number[0],
                        duration: _ctx.legDuration(_ctx.flightLeg?.leg_duration),
                        "from-destination": _ctx.flightLeg?.origin?.iata_code,
                        "from-time": _ctx.formatTime(_ctx.flightLeg.departure_datetime),
                        "to-time": _ctx.formatTime(_ctx.flightLeg.arrival_datetime),
                        "to-destination": _ctx.flightLeg?.destination?.iata_code,
                        "airline-name": _ctx.flightLeg.segment[0].operating_airline?.name,
                        "fare-info": "true"
                      }, null, 8 /* PROPS */, ["airline-logo", "airline-code", "duration", "from-destination", "from-time", "to-time", "to-destination", "airline-name"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            tabSection: _withCtx(() => [
              _createVNode(_component_AgHeading, {
                class: "flight_info_green_box",
                title: _ctx.renderDepartureDate(_ctx.flightLeg.departure_datetime),
                variant: "h4",
                "test-id": ""
              }, null, 8 /* PROPS */, ["title"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightLeg.segment, (segment, index) => {
                return (_openBlock(), _createBlock(_component_FlightRoute, {
                  "next-departure-time": _ctx.getNextDepartureTime(index),
                  "show-lay-off": index + 1 < _ctx.flightLeg.segment.length,
                  key: index,
                  segment: segment
                }, null, 8 /* PROPS */, ["next-departure-time", "show-lay-off", "segment"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}