import { GetterTree } from "vuex";

import { PassengersList, PNRDetails } from "@/ag-flight-components/types";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";

const getters: GetterTree<IFlightIssuanceState, IFlightIssuanceState> = {
  isFetchingPNR: (state): boolean => state.fetchingPNR,
  isIssueTicketLoading: (state): boolean => state.issueTicketLoading,
  pnrDetails: (state): PNRDetails | null => state.pnrDetail,
  pnr: (state): string | null => state.pnr,
  financialProfilePublicId: (state): string | null =>
    state.financialProfilePublicId,
  airTravelers: (state): PassengersList[] => state.pnrDetail?.passengers || [],
  isTicketIssued: (state): boolean => state.isTicketIssued,
  issuanceFinancialProfiles: (state): IFinancialProfile[] =>
    state.issuanceFinancialProfiles,
  isFinancialProfilesFetching: (state): boolean =>
    state.isFinancialProfilesFetching,
};

export default getters;
