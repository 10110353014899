import { MutationTree } from "vuex";
import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";
import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";

const mutations: MutationTree<IFlightIssuanceState> = {
  fetchingPNR(state, isFetching) {
    state.fetchingPNR = isFetching;
  },
  isIssueTicketLoading(state, payload) {
    state.issueTicketLoading = payload;
  },
  fetchedPNRDetail(state, payload) {
    state.fetchingPNR = false;
    state.pnrDetail = payload;
  },
  resetPNRDetails(state) {
    state.pnrDetail = null;
    state.isTicketIssued = false;
  },
  setPnr(state, payload) {
    state.pnr = payload;
  },
  setFinancialProfilePublicId(state, payload) {
    state.financialProfilePublicId = payload;
  },
  isTicketIssued(state, payload) {
    state.isTicketIssued = payload;
  },
  setFinancialProfileLoading(state, payload: boolean) {
    state.isFinancialProfilesFetching = payload;
  },
  saveIssuanceFinancialProfiles(state, payload: IFinancialProfile[]) {
    state.issuanceFinancialProfiles = payload;
  },
};

export default mutations;
