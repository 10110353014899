import { IFlightIssuanceState } from "@/ag-flight-components/interfaces/IFlightIssuance.interface";

const state: IFlightIssuanceState = {
  fetchingPNR: false,
  issueTicketLoading: false,
  financialProfilePublicId: "",
  pnrDetail: null,
  pnr: "",
  isTicketIssued: false,
  isFinancialProfilesFetching: false,
  issuanceFinancialProfiles: [],
};

export default state;
