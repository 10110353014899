<template>
  <ag-accordion class="flight_accordion_wrap" :panel-value="[1, 2]">
    <ag-accordion-panel title="">
      <template #flightInfo>
        <AgRow test-id="" style="display: flex; align-items: center">
          <AgColumn test-id="" style="flex: 4; margin-right: 10px">
            <AgReturnFlightDetailCard
              test-id=""
              :airline-logo="flightItem.logo"
              :airline-code="flightItem.goingFlightNo"
              :rairline-code="flightItem.comingFlightNo"
              :duration="flightItem.firstLegDuration"
              :rduration="flightItem.secondLegDuration"
              :from-destination="flightItem.source"
              :from-time="flightItem.firstLegDepartureTime"
              :from-timer="flightItem.secondLegDepartureTime"
              :to-time="flightItem.firstLegArrivalTime"
              :to-destination="flightItem.destination"
              :rfrom-destination="flightItem.destination"
              :rto-time="flightItem.secondLegArrivalTime"
              :rto-destination="flightItem.source"
              :fare-info="true"
              :airline-name="flightItem.name"
            />
            <AgFilterDropdown
              class="flight_fare_wrap"
              test-id=""
              label=""
              icon="infoIcon"
              cart="cartIcon"
              :provider="flightItem.provider"
              @add:quote="addToQuote(flightItem)"
              v-show="showFareBreakDown"
            >
              <template #Items>
                <AgFareInfo test-id="">
                  <AgHeading
                    :title="
                      renderRBD(
                        flightItem.rbdFirst + ' - ' + flightItem.rbdSecond
                      )
                    "
                    variant="h3"
                  ></AgHeading>
                  <AgPriceSummary
                    test-id=""
                    info="Base Fare"
                    :price="
                      formatCurrency(
                        Number(flightItem.base_fare),
                        flightItem.currency
                      )
                    "
                  ></AgPriceSummary>
                  <AgPriceSummary
                    test-id=""
                    info="Tax"
                    :price="
                      formatCurrency(
                        Number(flightItem.tax),
                        flightItem.currency
                      )
                    "
                  ></AgPriceSummary>
                  <AgPriceSummary
                    test-id=""
                    info="Gross Fare"
                    :price="
                      formatCurrency(
                        Number(flightItem.gross_fare),
                        flightItem.currency
                      )
                    "
                  ></AgPriceSummary>
                  <AgPriceSummary
                    test-id=""
                    v-if="flightItem.ag_price_difference != null"
                    :info="title(flightItem.ag_price_difference || 0)"
                    :price="
                      formatCurrency(
                        Number(flightItem.ag_price_difference || 0),
                        flightItem.currency
                      )
                    "
                  ></AgPriceSummary>
                  <AgPriceSummary
                    test-id=""
                    info="Total"
                    :price="
                      formatCurrency(
                        Number(
                          flightItem.ag_gross_fare
                            ? flightItem.ag_gross_fare
                            : flightItem.gross_fare
                        ),
                        flightItem.currency
                      )
                    "
                    total-price="true"
                  ></AgPriceSummary>
                </AgFareInfo>
              </template>
            </AgFilterDropdown>
          </AgColumn>
          <AgColumn test-id="" style="flex: 4">
            <AgRow test-id="" style="align-items: center">
              <AgFlightFare
                :type="flightItem?.rbdFirst"
                :price="flightItem.source + '-' + flightItem.destination"
                :piece="
                  flightItem.goingBagage
                    ? renderBaggageInformation(
                        flightItem.goingBagage.pieces,
                        flightItem.goingBagage.weight,
                        flightItem.goingBagage.unit
                      )
                    : 'No-Baggage'
                "
                pieceIcon="bagIcon"
              >
              </AgFlightFare>
              <AgFlightFare
                :type="flightItem?.rbdSecond"
                :price="flightItem.destination + '-' + flightItem.source"
                :piece="
                  flightItem.comingBagage
                    ? renderBaggageInformation(
                        flightItem.comingBagage.pieces,
                        flightItem.comingBagage.weight,
                        flightItem.comingBagage.unit
                      )
                    : 'No-Baggage'
                "
                pieceIcon="bagIcon"
              >
              </AgFlightFare>
              <AgHeading
                variant="h2"
                :title="formatCurrency(flightItem.price, flightItem.currency)"
              />
            </AgRow>
          </AgColumn>
        </AgRow>
        <AgRow
          test-id=""
          style="display: flex; align-items: right; justify-content: flex-end"
        >
          <MButton
            type="filled"
            :disabled="$store.state.flightModule.isLoading"
            @click="
              handleBookFare($event, flightItem.pre_booking_token, flightItem)
            "
          >
            Book Fare
          </MButton>
        </AgRow>
      </template>
      <template #tabSection>
        <hr />
        <AgTabs test-id="test">
          <template #TabHeading>
            <v-tab value="f_info_tab_1"> Flight Information</v-tab>
            <v-tab value="f_info_tab_2"> Cancellation</v-tab>
            <v-tab value="f_info_tab_3"> Date Change</v-tab>
            <v-tab value="f_info_tab_4"> Baggage</v-tab>
          </template>

          <template #TabDetail>
            <v-window-item
              value="f_info_tab_1"
              transition="fade"
              reverse-transition="fade"
            >
              <AgDiv class="complete_flight_details">
                <AgRow test-id="">
                  <AgColumn test-id="" sm="2" md="2" lg="2">
                    <AgIconBox
                      test-id=""
                      title="Departure"
                      :sub-title="flightItem.firstLegDepartureTime"
                      icon="departureIcon"
                    ></AgIconBox>
                  </AgColumn>
                  <AgColumn test-id="" sm="10" md="10" lg="10">
                    <AgDiv class="inner_left_border">
                      <AgReturnFlightDetailCard
                        test-id=""
                        :airline-logo="flightItem.logo"
                        :airline-code="flightItem.goingFlightNo"
                        :rairline-code="flightItem.comingFlightNo"
                        :duration="flightItem.firstLegDuration"
                        :rduration="flightItem.secondLegDuration"
                        :from-destination="flightItem.source"
                        :from-time="flightItem.firstLegDepartureTime"
                        :from-timer="flightItem.secondLegDepartureTime"
                        :to-time="flightItem.firstLegArrivalTime"
                        :to-destination="flightItem.destination"
                        :rfrom-destination="flightItem.destination"
                        :rto-time="flightItem.secondLegArrivalTime"
                        :rto-destination="flightItem.source"
                        :fare-info="true"
                        :airline-name="flightItem.name"
                      />
                      <AgHeading
                        class="flight_info_green_box"
                        :title="renderDepartureDate(flightItem.departure_date)"
                        variant="h4"
                        test-id=""
                      />
                      <FlightRoute
                        v-for="(segment, index) in flightItem.FO_1.segments"
                        :next-departure-time="getNextDepartureTime(index)"
                        :show-lay-off="index + 1 < flightItem.FO_1!.segments.length
                          "
                        :key="
                          segment.flight_number[0] + segment.departure_datetime
                        "
                        :segment="segment"
                      />
                      <AgHeading
                        class="flight_info_green_box"
                        :title="renderDepartureDate(flightItem.arrival_date)"
                        variant="h4"
                        test-id=""
                      />
                      <FlightRoute
                        v-for="(segment, index) in flightItem.FO_2.segments"
                        :next-departure-time="getNextDepartureTime(index)"
                        :show-lay-off="index + 1 < flightItem.FO_2!.segments.length
                          "
                        :key="
                          segment.flight_number[0] + segment.departure_datetime
                        "
                        :segment="segment"
                      />
                    </AgDiv>
                  </AgColumn>
                </AgRow>
              </AgDiv>
            </v-window-item>
            <rules-tabs
              :origin="currentJourneyLeg?.origin?.iata_code"
              :destination="currentJourneyLeg?.destination?.iata_code"
              :pre_booking_token="flightItem.pre_booking_token"
              :isRefundable="flightItem?.FO_1.is_refundable"
            />
          </template>
        </AgTabs>
      </template>
    </ag-accordion-panel>
  </ag-accordion>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import _ from "lodash";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";

import {
  FareOption,
  FlightOption,
  returnFlightPairs,
} from "../../types/Flights";
import { BaggageInfo } from "@/ag-flight-components/types/BookingResponse";
import { Price } from "../../types/BookingResponse";
import { TimeUtility } from "../../utils/TimeUtility";
import { getCurrencyFormatter, renderBaggageInfo } from "../../utils";
import { AMOUNT_TYPE, FARE_TYPE } from "../../enums/fare_calculator";
import FlightRoute from "./FlightRoute.vue";

import RulesTabs from "./RulesTabs.vue";

import { parseLocalStorageData } from "@/ag-portal-common/utils/helpers";
import { STORAGE_KEYS } from "@/ag-portal-common//constants/storageKeys";
import { IOrganizationPreference } from "@/ag-portal-common//interfaces/organization.interface";

import analyticsService from "@/services/analytics.service"; // Root folder flightItem
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents"; // Root folder flightItem
import { AgHeading } from "@aeroglobe/ag-core-ui";
export default defineComponent({
  name: "ReturnFlightDetailCardItem",
  props: {
    flightItem: {
      type: Object as PropType<returnFlightPairs>,
      default: () => ({}),
    },
    showFareBreakDown: {
      type: Boolean,
      default: true,
    },
  },
  data(): {
    fareOption: FareOption | null;
    isPanelExpanded: boolean;
  } {
    return {
      fareOption: null,
      isPanelExpanded: false,
    };
  },
  beforeMount() {
    this.fareOption = this.flightItem?.FO_1;
  },
  methods: {
    handleSuccessCallBack(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.FLIGHTS_BOOKING_FORM, { id })
      );
    },
    lightenColor(color: string, percent: number) {
      // Function to lighten the color manually
      const hex = color.replace(/^#/, "");
      const num = parseInt(hex, 16);
      const amt = Math.round(2.55 * percent);
      const R = (num >> 16) + amt;
      const G = ((num >> 8) & 0x00ff) + amt;
      const B = (num & 0x0000ff) + amt;
      return `#${((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1)}`;
    },
    getFirstHalf(str: string) {
      const indexOfUnderscore = str.indexOf("_");
      if (indexOfUnderscore !== -1) {
        return str.substring(0, indexOfUnderscore);
      } else {
        return str;
      }
    },
    addToQuote(flightItem: returnFlightPairs) {
      const existingQuotationsString =
        localStorage.getItem("Quotations-Return");
      const existingQuotations: any[] = existingQuotationsString
        ? JSON.parse(existingQuotationsString)
        : [];
      // Check if the flight is already in quotations
      const isFlightAlreadyAdded = existingQuotations.some((quotation) => {
        return (
          quotation.name == flightItem.name &&
          quotation.FO_1.rbd == flightItem.rbdFirst &&
          quotation.FO_2.rbd == flightItem.rbdSecond
        );
      });

      if (!isFlightAlreadyAdded) {
        if (existingQuotations.length < 10) {
          const data = flightItem;
          existingQuotations.push(data);
          localStorage.setItem(
            "Quotations-Return",
            JSON.stringify(existingQuotations)
          );
          notificationService.type = NOTIFICATION_TYPES.SUCCESS;
          notificationService.description = "Flight Added To Quotations";
          notificationService.triggerNotification();
        } else {
          notificationService.type = NOTIFICATION_TYPES.ERROR;
          notificationService.description = "Delete few quotations to add more";
          notificationService.triggerNotification();
        }
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = "Flight already exists in quotations";
        notificationService.triggerNotification();
      }
      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.ADD_TO_QUOTE,
        {
          dataAdded: localStorage.getItem("Quotations-Return"),
        }
      );
    },
    title(amount: number) {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    renderBaggageInformation(piece: number, weight: number, unit: string) {
      if (piece < 1) {
        return "Baggage included (As per airline policy)";
      }
      return `${piece} piece - ${weight} ${unit}`;
    },
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    calculateFlightPrice(priceObject: Price) {
      const fareCalculationAmount = Number(
        this.$store.state.flightModule.fareCalculatorAmount
      );
      const fareType = this.$store.state.flightModule.fareType;
      const amountType = this.$store.state.flightModule.amountType;
      if (!fareCalculationAmount) {
        return Number(
          priceObject.ag_gross_fare?.value
            ? priceObject.ag_gross_fare.value
            : priceObject.gross_fare.value
        );
      }
      let fare = Number(
        priceObject.ag_gross_fare?.value
          ? priceObject.ag_gross_fare.value
          : priceObject.gross_fare.value
      );
      let amountToAdd = fareCalculationAmount;
      let tax = 0;
      if (fareType === FARE_TYPE.BASE) {
        fare = Number(priceObject.base_fare.value);
        tax = Number(priceObject.tax.value);
      }
      if (amountType === AMOUNT_TYPE.PERCENTAGE) {
        amountToAdd = (fare / 100) * fareCalculationAmount;
      }
      return fare + amountToAdd + tax;
    },
    formatCurrency: (amount: number, currency_format: string) => {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    getNextDepartureTime(index: number) {
      return index + 1 < this.fareOption!.segments.length
        ? this.fareOption!.segments[index + 1].departure_datetime
        : new Date();
    },
    renderDepartureDate: (departure_date: Date) => {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
    renderBaggage(fareOption: FareOption) {
      const has_baggage = _.get(fareOption, "baggage_info");
      return has_baggage
        ? renderBaggageInfo(fareOption?.baggage_info)
        : "No Baggage";
    },
    handleBookFare(e: MouseEvent, pre_booking_token: string, flightItem: any) {
      e.stopPropagation();
      const payload = {
        pre_booking_tokens: [pre_booking_token],
      };
      this.$store.dispatch("initiateBooking", {
        payload,
        successCallback: this.handleSuccessCallBack,
      });
      const analyticsPayload = {
        "return-Flight-pairs": flightItem,
      };
      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_CONFIRMED_VIA_RETURN_PAIRS,
        analyticsPayload
      );
    },
    renderRBD(rbd: string) {
      return `RBD: ${rbd}`;
    },
  },
  computed: {
    colors() {
      const organizationPreferences = parseLocalStorageData(
        STORAGE_KEYS.PREFERENCES
      ) as IOrganizationPreference;
      if (organizationPreferences) {
        //
        return organizationPreferences.default_color;
      }
      return "#10B981";
    },
    currentJourneyLeg() {
      return this.$store.getters.currentJourneyLeg;
    },
    nextJourneyLeg() {
      return this.$store.getters.nextJourneyLeg;
    },
    extractToDate() {
      const url = this.$route.query.trips;
      const urlToString = new String(url);
      const [origin, destination, departureDate, returnDate] =
        urlToString?.split(",");
      return {
        departureDate,
        returnDate: returnDate || departureDate,
      };
    },
  },
  components: { AgHeading, FlightRoute, RulesTabs },
});
</script>
