<template>
  <ag-heading variant="h2" title="Price Summary" class="margin_bottom_10" />
  <AgPriceSummary
    test-id=""
    v-show="showAdultBreakDown"
    :info="renderAdultPriceSummaryTitle.label"
    :price="renderAdultPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    v-show="showChildBreakDown"
    :info="renderChildPriceSummaryTitle.label"
    :price="renderChildPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    v-show="showInfantBreakDown"
    :info="renderInfantPriceSummaryTitle.label"
    :price="renderInfantPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    info="Price You Pay"
    :price="renderTotalGrossPrice"
    total-price="true"
  />
  <ag-accordion
    class="flight_accordion_wrap"
    :panel-value="[1, 2]"
    v-show="showBreakDown"
  >
    <ag-accordion-panel>
      <template #flightInfo>
        <ag-heading
          variant="h3"
          title="Fare Break Down"
          class="margin_bottom_10"
          style="margin-top: 10px"
        />
      </template>
      <template #tabSection>
        <AgFareInfo test-id="" style="max-width: inherit">
          <AgDiv v-show="showAdultBreakDown">
            <ag-heading
              variant="h3"
              title="Adult Break Down"
              class="margin_bottom_10"
              style="margin-top: 10px"
            />
            <AgPriceSummary
              test-id=""
              info="Base Fare"
              :price="renderBaseFare.adultBaseFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Tax"
              :price="renderTax.adultTax"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Gross Fare"
              :price="renderGrossFare.adultGrossFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              v-if="AdultPriceDifference !== 0"
              :info="title(AdultPriceDifference || 0)"
              :price="renderPriceDifference.adultPriceDifference"
            ></AgPriceSummary>

            <AgPriceSummary
              test-id=""
              info="Total"
              :price="renderTotalFare.adultTotal"
              total-price="true"
            ></AgPriceSummary>
          </AgDiv>

          <AgDiv v-show="showChildBreakDown">
            <ag-heading
              variant="h3"
              title="Child Break Down"
              class="margin_bottom_10"
              style="margin-top: 10px"
            />
            <AgPriceSummary
              test-id=""
              info="Base Fare"
              :price="renderBaseFare.childBaseFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Tax"
              :price="renderTax.childTax"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Gross Fare"
              :price="renderGrossFare.childGrossFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              v-if="ChildPriceDifference !== 0"
              :info="title(ChildPriceDifference || 0)"
              :price="renderPriceDifference.childPriceDifference"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Total"
              :price="renderTotalFare.childTotal"
              total-price="true"
            ></AgPriceSummary>
          </AgDiv>

          <AgDiv v-show="showInfantBreakDown">
            <ag-heading
              variant="h3"
              title="Infant Break Down"
              class="margin_bottom_10"
              style="margin-top: 10px"
            />
            <AgPriceSummary
              test-id=""
              info="Base Fare"
              :price="renderBaseFare.infantBaseFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Tax"
              :price="renderTax.infantTax"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Gross Fare"
              :price="renderGrossFare.infantGrossFare"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              v-if="InfantPriceDifference !== 0"
              :info="title(InfantPriceDifference || 0)"
              :price="renderPriceDifference.infantPriceDifference"
            ></AgPriceSummary>
            <AgPriceSummary
              test-id=""
              info="Total"
              :price="renderTotalFare.infantTotal"
              total-price="true"
            ></AgPriceSummary>
          </AgDiv>
        </AgFareInfo>
      </template>
    </ag-accordion-panel>
  </ag-accordion>
  <slot name="flightDetailsActionButtons"> </slot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { FlightDetailsType, FlightLeg } from "@/ag-flight-components/types";
import { testMatrix } from "firebase-functions/v1/testLab";
import { totalmem } from "os";
import { channel } from "diagnostics_channel";

export default defineComponent({
  name: "PriceSummary",
  methods: {
    formatCurrency(amount: number, currency_format: string) {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    title(amount: number) {
      if (amount > 0) {
        return "Fee";
      } else {
        return "Discount";
      }
    },
    showBreakDown() {
      return (
        this.showAdultBreakDown ||
        this.showChildBreakDown ||
        this.showInfantBreakDown
      );
    },
  },
  computed: {
    showAdultBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.adult_count) > 0;
    },
    showChildBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.child_count) > 0;
    },
    showInfantBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.infant_count) > 0;
    },
    renderAdultPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossprice = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const baseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.base_fare.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.base_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.tax.currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const total = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency
      );
      const currency =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency;
      return {
        label: `${airline} (adult) x ${flightDetails.adult_count}`,
        price,
        grossprice: `Gross Fare: ${grossprice}`,
        agprice: `Total Amount: ${price}`,
        baseFare: baseFare,
        tax: tax,
        grossFare: grossFare,
        total: total,
        currency: currency,
      };
    },
    renderChildPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossprice = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const baseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.tax.currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const total = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency
      );
      const currency =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency;
      return {
        label: `${airline} (child) x ${flightDetails.child_count}`,
        price,
        grossprice: `Gross Fare: ${grossprice}`,
        agprice: `Total Amount: ${price}`,
        baseFare: baseFare,
        tax: tax,
        grossFare: grossFare,
        total: total,
        currency: currency,
      };
    },
    renderInfantPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant
            ?.ag_total_amount.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossprice = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const baseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.tax.currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const grossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const total = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant
            ?.ag_total_amount.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency
      );

      const currency =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency;
      return {
        label: `${airline} (infant) x ${flightDetails.infant_count}`,
        price,
        grossprice: `Gross Fare: ${grossprice}`,
        agprice: `Total Amount: ${price}`,
        baseFare: baseFare,
        tax: tax,
        grossFare: grossFare,
        total: total,
        currency: currency,
      };
    },
    renderTotalGrossPrice() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.formatCurrency(
        Number(flightDetails?.ag_total_amount?.value),
        flightDetails?.ag_total_amount?.currency
      );
    },
    renderTotalGrossPrice2() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.formatCurrency(
        Number(flightDetails?.price?.gross_fare.value),
        flightDetails?.price?.gross_fare.currency
      );
    },
    AdultPriceDifference() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const agTotal =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .value;

      const agGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare.value;

      const ag_price_diff = agTotal - agGrossFare;
      return ag_price_diff;
    },
    ChildPriceDifference() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const agTotal =
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .value;

      const agGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare.value;

      const ag_price_diff = agTotal - agGrossFare;
      return ag_price_diff;
    },
    InfantPriceDifference() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const agTotal =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .value;

      const agGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .value;

      const ag_price_diff = agTotal - agGrossFare;
      return ag_price_diff;
    },
    renderBaseFare() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const adultBaseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.base_fare.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.base_fare
          .currency
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childBaseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.base_fare
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const infantBaseFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.base_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.base_fare
          .currency
      );

      return {
        adultBaseFare: adultBaseFare,
        childBaseFare: childBaseFare,
        infantBaseFare: infantBaseFare,
      };
    },
    renderGrossFare() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const adultGrossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childGrossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const infantGrossFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .currency
      );

      return {
        adultGrossFare: adultGrossFare,
        childGrossFare: childGrossFare,
        infantGrossFare: infantGrossFare,
      };
    },
    renderTotalFare() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const adultTotalFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childTotalFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const infantTotalFare = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant
            ?.ag_total_amount.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency
      );

      return {
        adultTotal: adultTotalFare,
        childTotal: childTotalFare,
        infantTotal: infantTotalFare,
      };
    },
    renderTax() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const adultTax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.tax.currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childTax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.tax.currency
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const infantTax = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant?.tax.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.tax.currency
      );

      return {
        adultTax: adultTax,
        childTax: childTax,
        infantTax: infantTax,
      };
    },

    renderPriceDifference() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const agAdultTotal =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .value;

      const agAdultGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare.value;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const adultAgPriceDiff = this.formatCurrency(
        Number(agAdultTotal - agAdultGrossFare),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.gross_fare
          .currency
      );

      const agChildTotal =
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .value;

      const agChildGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare.value;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childAgPriceDiff = this.formatCurrency(
        Number(agChildTotal - agChildGrossFare),
        flightDetails?.price?.pax_type_price_breakdown?.child?.gross_fare
          .currency
      );

      const agInfantTotal =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .value;

      const agInfantGrossFare =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .value;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const infantAgPriceDiff = this.formatCurrency(
        Number(agInfantTotal - agInfantGrossFare),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.gross_fare
          .currency
      );

      return {
        adultPriceDifference: adultAgPriceDiff,
        childPriceDifference: childAgPriceDiff,
        infantPriceDifference: infantAgPriceDiff,
      };
    },

    renderCurrency() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};

      const AdultCurrency =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency;

      const ChildCurrency =
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency;
      const InfantCurrency =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency;
      return AdultCurrency || ChildCurrency || InfantCurrency;
    },
  },
});
</script>
