export const NOTIFICATION_MESSAGES = {
  //SOA
  SOA_DOWNLOAD_SUCCESS: "Successfully downloaded SOA sheet for {0}",
  SOA_DOWNLOAD_ERROR: "Failed to download SOA sheet",
  SOA_SEND_EMAIL_SUCCESS: "Successfully sent email to {0}",
  SOA_SEND_EMAIL_ERROR: "Failed to send SOA sheet",

  //Auth
  FORGOT_PASSWORD_SUCCESS:
    "Reset password email has been sent. Please check your inbox",
  RESET_PASSWORD_SUCCESS: "Password Resets Successfully",

  //Profile
  CHANGE_PASSWORD_SUCCESS: "Password Changed Successfully",
  CHANGE_NUMBER_SUCCESS: "Number Changed Successfully",

  //Financial Profile
  LINKED_FINANCIAL_PROFILE_SUCCESS: "Successfully linked financial profile",
  UNLINKED_FINANCIAL_PROFILE_SUCCESS: "Successfully unlinked financial profile",

  //Organization
  ORGANIZATION_CREATE_SUCCESS: "Organization created successfully",
  ORGANIZATION_UPDATE_SUCCESS: "Organization updated successfully",
  ORGANIZATION_STATUS_UPDATE_SUCCESS: "Organization {0} successfully",
  ORGANIZATION_UPDATE_PREFERENCE_SUCCESS:
    "Organization preferences updated successfully",
  ORGANIZATION_NOT_FOUND: "Organization not found",

  //Permissions
  UPDATE_PERMISSIONS_SUCCESS: "Permissions updated successfully",

  // Agent
  ADD_AGENT_SUCCESS: "{0} added successfully in the organization",
  AGENT_DELETE_SUCCESS: "Agent deleted successfully from the organization",

  //Others
  INVALID_EMAIL: "Email is invalid",
  DEFAULT: "Something went wrong",
  SOMETHING_WENT_WRONG: "Something went wrong",
  NO_RESULTS_FOUND: "No results found",
  NO_RESULTS_FOUND_DESCRIPTION:
    "Uh-oh! It seems like there are no records that match your selected filter",
  INVALID_DATE: "Invalid date",

  //Payments
  CC_CHARGES_DEDUCTION:
    "{0}% charges will be deducted incase of credit/debit card.",
  ONE_BILL_CHARGES_DEDUCTION:
    "PKR {0} charges will be deducted incase of one bill.",
  TOTAL_AMOUNT_DEPOSIT: "Total {1} {0} will be deposited in your account",
  PAYMENT_CREATE_SUCCESS: "Payment created successfully",
  IBFT_DEDUCTION:
    "Your payment will be reflected in your AeroGlobe account balance within 30 minute",
  //My Bookings
  AIRLINE_TICKETS_SENT_SUCCESS:
    "Tickets sent successfully to provided email addresses.",

  //Tour
  SELECT_ANY_PACKAGE: "Please select any package",
  FDG_FLIGHTS_CONFIRM_BOOKING_SUCCESS:
    "Your request of tour has been recieved, you will be contacted through email shortly.",

  //  Credit Limit Requests
  CREATE_CREDIT_LIMIT_REQUEST_SUCCESS:
    "Request for temporary credit limit has been created successfully and sent to the concerned person for review",
  APPROVE_CREDIT_LIMIT_REQUEST_SUCCESS:
    "Approve credit limit request successfully",
  REJECT_CREDIT_LIMIT_REQUEST_SUCCESS:
    "Reject credit limit request successfully",
  UNLINK_FINANCIAL_PROFILE_SUCCESS: "Unlink financial profile successfully",
  HOTEL_FREE_CANCELLATION_MESSAGE:
    "Cancellation is Free - Issue now and pay later",
  HOTEL_FREE_CANCELLATION_DESCRIPTION:
    "Secure your booking with ease - Payment will be automatically deducted on {0} at {1}",
  HOTEL_BOOKING_CANCEL_SUCCESS: "Hotel booking cancelled successfully",
  HOTEL_BOOKING_PAYMENT_SUCCESS: "Successfully paid for the booking",
};
