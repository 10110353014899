import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgContactInfo = _resolveComponent("AgContactInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createBlock(_component_AgDiv, { class: "widget_border" }, {
    default: _withCtx(() => [
      _createVNode(_component_AgRow, { "test-id": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_AgColumn, {
            "test-id": "",
            sm: "4",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgContactInfo, {
                "icon-name": "pakFlag",
                title: _ctx.renderFullName
              }, null, 8 /* PROPS */, ["title"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_AgColumn, {
            "test-id": "",
            sm: "4",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgContactInfo, {
                "icon-name": "nicIcon",
                title: _ctx.renderPassportNumberandExpiry.number,
                "sub-title": _ctx.renderPassportNumberandExpiry.expiry
              }, null, 8 /* PROPS */, ["title", "sub-title"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_AgColumn, {
            "test-id": "",
            sm: "4",
            cols: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AgContactInfo, {
                "icon-name": _ctx.renderPassengerType.icon,
                title: _ctx.renderPassengerType.type
              }, null, 8 /* PROPS */, ["icon-name", "title"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}