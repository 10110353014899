<template>
  <AgDiv
    test-id=""
    class="d-flex justify-content-end margin_bottom_10 row-column-container"
  >
    <v-switch
      v-if="isTypeReturn"
      test-id=""
      hide-details
      class="pairview-toggle"
      color="primary"
      inset
      :value="true"
      v-model="pairViewModal"
      :label="renderLabelForPairView"
      style="min-width: 50%"
    />
    <AgFilterDropdown
      test-id=""
      class="fare_calculator_main"
      label="Fare Calculator"
      icon="calculator"
      @click.capture="handleOpenFilter()"
    >
      <template #Items>
        <AgFareCalculator test-id="">
          <AgTextFieldNew
            :label="renderLabel()"
            test-id=""
            type="number"
            variant="outlined"
            v-model="selectedAmount"
            :sign="renderSign()"
            style="align-items: center"
            :error="fieldError"
          ></AgTextFieldNew>
          <AgRow test-id="">
            <AgColumn xs="6" sm="6" md="6" test-id="">
              <AgDiv test-id="" class="fare_calc_box">
                <AgHeading
                  title="Select Amount Type"
                  variant="label"
                  test-id=""
                ></AgHeading>
                <AgRadio test-id="" v-model="selectedAmountType">
                  <AgRadioItem
                    test-id=""
                    name="amount_type"
                    value="fixed"
                    label="Fixed Amount"
                  ></AgRadioItem>
                  <AgRadioItem
                    test-id=""
                    name="amount_type"
                    value="percentage"
                    label="Percentage Amount"
                  ></AgRadioItem>
                </AgRadio>
              </AgDiv>
            </AgColumn>
            <AgColumn xs="6" sm="6" md="6" test-id="">
              <AgDiv test-id="" class="fare_calc_box">
                <AgHeading
                  title="Select Fare Type"
                  variant="label"
                  test-id=""
                ></AgHeading>
                <AgRadio test-id="" v-model="selectedFareType">
                  <AgRadioItem
                    test-id=""
                    name="fare_type"
                    value="gross"
                    label="Gross Fare"
                  ></AgRadioItem>
                  <AgRadioItem
                    test-id=""
                    name="fare_type"
                    value="base"
                    label="Base Fare"
                  ></AgRadioItem>
                </AgRadio>
              </AgDiv>
            </AgColumn>
          </AgRow>
          <AgRow test-id="">
            <AgColumn test-id="" xs="6" sm="6" md="6">
              <AGButton
                class="full-width red_color"
                test-id=""
                @click="handleClearFilter"
                variant="primary"
                >CLEAR</AGButton
              >
            </AgColumn>
            <AgColumn test-id="" xs="6" sm="6" md="6">
              <AGButton
                class="full-width"
                test-id=""
                variant="primary"
                @click="handleApplyFilter"
                >APPLY</AGButton
              ></AgColumn
            >
          </AgRow>
        </AgFareCalculator>
      </template>
    </AgFilterDropdown>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { AMOUNT_TYPE, FARE_TYPE } from "../../enums/fare_calculator";

import analyticsService from "@/services/analytics.service"; // Root folder item
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents"; // Root folder item

export default defineComponent({
  name: "FlightFareCalculator",
  props: {
    pairView: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:pairView"],
  data(): {
    selectedAmount: string | null;
    selectedAmountType: string;
    selectedFareType: string;
    sign: string;
    fieldError: string;
    state: boolean;
    pairViewModal: boolean;
  } {
    return {
      selectedAmount: null,
      selectedAmountType: "",
      selectedFareType: "",
      sign: localStorage.getItem("currency") || "",
      fieldError: "",
      state: false,
      pairViewModal: this.pairView,
    };
  },
  computed: {
    isTypeReturn(): boolean {
      return ROUTE_TYPE.RETURN === this.$route.query?.route_type;
    },
    amount() {
      return this.$store.state.flightModule.fareCalculatorAmount;
    },
    amountType() {
      return this.$store.state.flightModule.amountType;
    },
    fareType() {
      return this.$store.state.flightModule.fareType;
    },
    renderLabelForPairView() {
      return `Switch to ${this.pairViewModal ? "single" : "pair"} view`;
    },
  },
  methods: {
    renderSign() {
      const currency = localStorage.getItem("currency");
      return this.selectedAmountType === AMOUNT_TYPE.PERCENTAGE
        ? "%"
        : currency;
    },
    renderLabel() {
      return this.selectedAmountType === AMOUNT_TYPE.FIXED
        ? "Enter Fix Value of Amount"
        : "Enter Percentage of Amount";
    },
    renderViewLabel() {
      const value = localStorage.getItem("pair-view");
      if (value === "true" || value === null) {
        return "switch to single view";
      } else {
        return "switch to pairs view";
      }
    },
    toggleView() {
      const analyticsPayload = {
        "pair-view": this.pairView,
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.PAIR_VIEW,
        analyticsPayload
      );
    },
    handleOpenFilter() {
      const payload = {
        status: this.state,
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.FARE_CALCULATOR,
        payload
      );

      this.state = !this.state;
    },
    handleApplyFilter() {
      if (Number(this.selectedAmount)) {
        this.fieldError = "";
        this.$store.commit("saveFareCalculatorAmount", this.selectedAmount);
        this.$store.commit("saveAmountType", this.selectedAmountType);
        this.$store.commit("saveFareType", this.selectedFareType);
        this.selectedAmount = "";
      } else {
        this.fieldError = "Enter value here";
      }

      this.handleTrackAction("apply");
    },
    handleClearFilter() {
      this.$store.commit("saveFareCalculatorAmount", null);
      this.$store.commit("saveAmountType", AMOUNT_TYPE.FIXED);
      this.$store.commit("saveFareType", FARE_TYPE.GROSS);

      this.handleTrackAction("clear");

      this.selectedAmount = null;
    },
    handleTrackAction(item: "apply" | "clear") {
      const analyticsPayload = {
        "item-name": `${item} fare`,
        amount: this.selectedAmount,
        "amount-type": this.selectedAmountType,
        "fare-type": this.selectedFareType,
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.FARE_CALCULATOR_ACTION,
        analyticsPayload
      );
    },
  },
  watch: {
    "$store.state.flightModule.fareCalculatorAmount": {
      handler: function (value) {
        this.selectedAmount = value;
      },
    },
    "$store.state.flightModule.amountType": {
      handler: function (value) {
        this.selectedAmountType = value;
      },
    },
    "$store.state.flightModule.fareType": {
      handler: function (value) {
        this.selectedFareType = value;
      },
    },
    pairViewModal(value: boolean): void {
      this.$emit("update:pairView", value);
    },
  },
  beforeMount() {
    const { amount, amountType, fareType } = this.$store.state.flightModule;
    this.selectedAmount = amount;
    this.selectedFareType = fareType;
    this.selectedAmountType = amountType;
  },
});
</script>

<style lang="scss">
.pairview-toggle {
  margin-bottom: 10px;

  .v-selection-control__wrapper {
    &.text-primary {
      color: var(--green-color) !important;
    }
  }

  .v-selection-control {
    min-height: 0 !important;
  }
}

.row-column-container {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .row-column-container {
    flex-direction: column;
  }
}
</style>
