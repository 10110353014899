<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlightResultsTimer",
  data(): { timeRemaining: number; timerId: number; timedOut: boolean } {
    return {
      timeRemaining: 600,
      timerId: 0,
      timedOut: false,
    };
  },
  computed: {
    formatedTime() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.formatDuration(this.timeRemaining);
    },
  },
  mounted() {
    const timer = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        this.timedOut = true;
        clearInterval(this.timerId);
        this.$emit("timer:timedOut", this.timedOut);
      }
    }, 1000);
    this.timerId = Number(timer);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  methods: {
    formatDuration(durationInSeconds: number) {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      return `${minutes < 10 ? "0" : ""}${minutes} : ${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
  },
});
</script>

<template>
  <AgTimeRunOut
    test-id=""
    :time="formatedTime"
    text="Book now before search expires"
  />
</template>
