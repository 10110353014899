import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_FlightSearch = _resolveComponent("FlightSearch")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isDialogOpen,
    persistent: "",
    class: "change_search_modal",
    "modal-width": "80%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode("Change Search")
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_g_button, {
        type: "button",
        onClick: _ctx.handleClose,
        class: "modal_close_icon",
        variant: "link"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_FlightSearch, { onClose_flight_search_dialog: _ctx.handleCloseDialog }, null, 8 /* PROPS */, ["onClose_flight_search_dialog"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}