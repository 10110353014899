<template>
  <ag-loader v-if="isLoading" />
  <template v-else>
    <ag-row>
      <ag-column
        xs="12"
        sm="12"
        :md="isInitiateBooking ? 12 : 8"
        :lg="isInitiateBooking ? 12 : 9"
      >
        <AgCard test-id="">
          <AgRow test-id="" class="v-center">
            <AgColumn test-id="" md="9">
              <AgSelectedFlightInfo>
                <template #pnrInfo>
                  <AgDiv class="booking_id_wrap"
                    >Booking ID: <span>{{ renderBookingId }}</span></AgDiv
                  >
                </template>
              </AgSelectedFlightInfo>
            </AgColumn>
            <AgColumn test-id="" md="3" class="text-right">
              <AGButton
                test-id=""
                type="button"
                :variant="renderStatusSeverity"
              >
                {{ renderBookingStatus }}
              </AGButton>
            </AgColumn>
          </AgRow>
        </AgCard>
      </ag-column>
      <ag-column v-if="!isInitiateBooking" xs="12" sm="12" md="4" lg="3">
        <AgCard>
          <AgPnrDetail test-id="" :pnr="supplierPnr" :expiry="pnrExpiresAt" />
        </AgCard>
      </ag-column>
    </ag-row>
    <ag-row>
      <ag-column xs="12" sm="12" md="8" lg="9">
        <AgCard test-id="">
          <ag-heading
            variant="h2"
            title="Flight Details"
            class="margin_bottom_20"
          />
          <FlightDetailAccordion
            v-for="(flightLeg, index) in flightDetails?.flight_legs"
            :key="index"
            :flight-leg="flightLeg"
            :index="index"
          />
        </AgCard>
        <AgCard test-id="" v-if="hasPassengers">
          <ag-heading
            variant="h2"
            title="Passenger Details"
            class="margin_bottom_20"
          />
          <PassengerDetailCard
            v-for="(passenger, index) in flightDetails?.passengers"
            :key="index"
            :passenger="passenger"
            :is-international="flightDetails?.is_international"
          />
        </AgCard>
        <AGDiv v-if="AdditionalDetails()">
          <AgCard v-for="(detail, index) in legAdditionalDetails" :key="index">
            <ag-heading
              variant="h2"
              :title="'Additional Services on Flight ' + (index + 1)"
            />
            <AdditionalDetailsCard
              :has-meal="detail.mealDetails"
              :luggage-info="detail.baggageDetails"
            />
          </AgCard>
        </AGDiv>
      </ag-column>
      <ag-column xs="12" sm="12" md="4" lg="3">
        <AgCard>
          <AgSidebarThumb test-id="" thumb="sidebarFlightThumb1">
            <PriceSummary>
              <template #flightDetailsActionButtons>
                <template v-if="isBookingCancelled">
                  <h3
                    style="
                      font-weight: bold;
                      color: #ed6c02;
                      text-align: center;
                    "
                  >
                    Booking {{ renderBookingStatus }}
                  </h3>
                </template>

                <template v-else>
                  <AGButton
                    :disabled="isFlightWaitListed"
                    v-show="!isFlightIssued"
                    @click="onProceedToIssuance"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Proceed To Issuance</AGButton
                  >
                  <AGButton
                    v-show="!isFlightCancelled"
                    :is-loading="isCancelBookingLoading"
                    @click="isCancelationDialogOpen = true"
                    class="full-width margin_bottom_10"
                    test-id=""
                    variant="danger"
                    >Cancel Booking</AGButton
                  >
                  <AGButton
                    v-show="isIssuedAndisPIAAndisNotInternational"
                    :is-loading="isVoidFlightTicketLoading"
                    @click="onVoidPIATicket"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Void ticket</AGButton
                  >
                  <AGButton
                    :is-loading="isDownloadTicketLoading"
                    @click="onOpenEditFareModalHandler"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Download ticket</AGButton
                  >
                  <AGButton
                    v-if="showDownloadAirlineTicketButton()"
                    :is-loading="isDownloadTicketLoading"
                    @click="onClickAirlineTicket()"
                    class="full-width margin_bottom_10"
                    test-id="emailTicket"
                    >Email ticket
                  </AGButton>
                </template>
                <AirlineTicketModal
                  :show-modal="showAirlineTicketModal"
                  @onSendAirlineTicket="onSendAirlineTicket"
                  @closeModal="showAirlineTicketModal = false"
                />
              </template>
            </PriceSummary>
          </AgSidebarThumb>
        </AgCard>
      </ag-column>
    </ag-row>
    <ag-modal persistent :is-open="isEditModalOpen" modal-width="50%">
      <template #header>
        <ag-heading variant="h4" title="Edit & Download Ticket" />
        <a-g-button
          type="button"
          class="modal_close_icon"
          variant="link"
          @click="closeDownloadTicketModal"
          >Close
        </a-g-button>
      </template>
      <template #body>
        <AgDiv>
          <AgHeading
            title="Do you want to show fare on ticket"
            variant="label"
            test-id=""
          ></AgHeading>
          <MButton
            class="margin_right_10"
            :type="`${canRenderFare ? 'filled' : 'outlined'}`"
            @click="onShowFareHandler"
          >
            Yes
          </MButton>
          <MButton
            :type="`${!canRenderFare ? 'filled' : 'outlined'}`"
            @click="onHideFareHandler"
          >
            No
          </MButton>
        </AgDiv>
        <AgDiv>
          <AgHeading
            v-show="canRenderFare"
            title="Do you want to edit fare on ticket"
            variant="label"
            test-id=""
          ></AgHeading>
          <MButton
            v-show="canRenderFare"
            class="margin_right_10"
            :type="`${canEditFare ? 'filled' : 'outlined'}`"
            @click="onShowEditedFareHandler"
          >
            Yes
          </MButton>
          <MButton
            v-show="canRenderFare"
            :type="`${!canEditFare ? 'filled' : 'outlined'}`"
            @click="onHideEditedFareHandler"
          >
            No
          </MButton>
        </AgDiv>
        <AgDiv test-id="" v-show="openEditFareTable()">
          <AgTable
            :headers="headers"
            :items="items"
            :items-per-page="tableRows"
            :has-pagination="false"
            :has-search="false"
            :simpleDataTable="false"
          >
            <template #col-type="{ item }">
              <ag-div>{{ item.raw.paxType }}</ag-div>
            </template>
            <template #col-amount="{ item }">
              <ag-div>{{ item.raw.paxAmount }}</ag-div>
            </template>
            <template #col-editedAmount="{ item }">
              <a-g-text-field
                style="padding-top: 5%"
                v-model="item.raw.updatedAmount"
                :value="item.raw.updatedAmount"
                type="number"
              />
            </template>
          </AgTable>
        </AgDiv>
      </template>
      <template #footer>
        <MButton variant="filled" @click="saveAndDownload()"
          >Save & Download</MButton
        >
      </template>
    </ag-modal>
  </template>
  <CancelBookingConfirmationDialog
    :is-open="isCancelationDialogOpen"
    :handle-accpet="onCancelBooking"
    :handle-reject="handleCloseCancelationDialog"
  />
  <PriceUpdatedDialog
    :is-open="isPriceUpdatedDialog"
    :handle-accpet="handlePriceUpdateContinue"
    :gross-fare="flightDetails?.equivalent_ag_total_amount || '-'"
    :currency="Currency"
  />
  <WaitListDialog :is-open="isWaitListDialog" />
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import AirlineTicketModal from "../views/AirlineTicketModal.vue";
import { ALLOWED_SUPPLIERS } from "@/ag-portal-common/constants/allowedSuppliers";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import _ from "lodash";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import FlightDetailAccordion from "@/ag-flight-components/components/FlightBooking/FlightDetailAccordion.vue";
import PassengerDetailCard from "@/ag-flight-components/components/FlightBooking/PassengerDetailCard.vue";
import CancelBookingConfirmationDialog from "@/ag-flight-components/components/FlightBooking/CancelBookingConfirmationDialog.vue";
import AdditionalDetailsCard from "@/ag-flight-components/components/FlightBooking/AdditionalDetailsCard.vue";
import PriceUpdatedDialog from "@/ag-flight-components/components/FlightBooking/PriceUpdatedDialog.vue";
import WaitListDialog from "@/ag-flight-components/components/FlightBooking/WaitlistDialog.vue";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import PriceSummary from "@/ag-flight-components/components/FlightBooking/PriceSummary.vue";
import { BOOKING_STATUSES } from "@/ag-flight-components/enums/booking_statuses";
import { renderBaggageInfo } from "@/ag-flight-components/utils";
import { FlightLeg, FlightDetailsType } from "@/ag-flight-components/types";
import { PATH } from "@/ag-portal-common/constants/path";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import analyticsService from "@/services/analytics.service"; // Root folder item
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents"; // Root folder item
import { FORMAT_DD_MM_YYYY_HH_MM_24_HOURS } from "@/ag-portal-common/constants/dateTimeFormats";
import { format } from "date-fns";
import { handleConvertUTCtoZonedTime } from "@/ag-flight-components/utils";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { MButton } from "@aeroglobe/ag-core-ui";

export default defineComponent({
  name: "FlightBookingForm",
  components: {
    FlightDetailAccordion,
    PassengerDetailCard,
    PriceSummary,
    CancelBookingConfirmationDialog,
    AdditionalDetailsCard,
    PriceUpdatedDialog,
    AirlineTicketModal,
    WaitListDialog,
  },
  data() {
    return {
      legAdditionalDetails: [] as {
        mealDetails: string;
        baggageDetails: string;
      }[],
      canEditFare: false,
      canRenderFare: false,
      tableRows: 0,
      isCancelationDialogOpen: false,
      isPriceUpdatedDialog: false,
      isWaitListDialog: false,
      showAirlineTicketModal: false,
      showFareOnDownload: false,
      showEditOnDownload: false,
      editFareOnDownload: false,
      perAdultEditedFare: 0,
      perChildEditedFare: 0,
      perInfantEditedFare: 0,
      isEditModalOpen: false,
      isInputEditModalOpen: false,
      inputAmount: 0,
      mainIndex: 0,
      isTableShow: false,
      isOpen: false,
      items: [
        {
          paxType: "Adult",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: 0,
          index: 0,
        },
        {
          paxType: "Child",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: "0",
          index: 1,
        },
        {
          paxType: "Infant",
          paxCount: "0",
          paxAmount: "0",
          updatedAmount: "0",
          index: 2,
        },
      ],
      headers: [
        {
          title: "Passenger Type",
          value: "type",
          key: "type",
          sortable: true,
        },
        {
          title: "Count",
          value: "count",
          key: "count",
          sortable: true,
        },
        {
          title: "Amount",
          value: "amount",
          key: "amount",
          sortable: false,
        },
        {
          title: "Edited Amount",
          value: "editedAmount",
          key: "editedAmount",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    openEditFareTable() {
      if (this.canRenderFare && this.canEditFare) {
        const index = this.headers.findIndex(
          (header) => header.key === "count"
        );
        if (index !== -1) {
          this.headers.splice(index, 1);
        }
        return true;
      } else {
        return false;
      }
    },
    closeDownloadTicketModal() {
      this.isEditModalOpen = false;
      this.canRenderFare = false;
      this.canEditFare = false;
    },
    onSave() {
      if (this.inputAmount > 0 && this.inputAmount) {
        this.items[this.mainIndex].updatedAmount = this.inputAmount;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          "Fare cant be empty or negative or in words";
        notificationService.triggerNotification();
      }
    },
    populateTable() {
      this.tableRows = this.showAdultBreakDown ? 1 : 0;
      this.tableRows = this.showChildBreakDown
        ? this.tableRows + 1
        : this.tableRows;
      this.tableRows = this.showInfantBreakDown
        ? this.tableRows + 1
        : this.tableRows;
      if (
        this.showAdultBreakDown ||
        this.showChildBreakDown ||
        this.showInfantBreakDown
      ) {
        if (this.showAdultBreakDown) {
          this.items[0].paxCount = this.renderAdultPriceSummaryTitle.label;
          this.items[0].paxAmount = this.renderAdultPriceSummaryTitle.price;
          this.items[0].updatedAmount =
            this.renderAdultPriceSummaryTitle.amount;
        }
        if (this.showChildBreakDown) {
          this.items[1].paxCount = this.renderChildPriceSummaryTitle.label;
          this.items[1].paxAmount = this.renderChildPriceSummaryTitle.price;
          this.items[1].updatedAmount =
            this.renderChildPriceSummaryTitle.amount;
        }
        if (this.showInfantBreakDown) {
          this.items[2].paxCount = this.renderInfantPriceSummaryTitle.label;
          this.items[2].paxAmount = this.renderInfantPriceSummaryTitle.price;
          this.items[2].updatedAmount =
            this.renderInfantPriceSummaryTitle.amount;
        }
        if (!this.showInfantBreakDown) {
          this.items.splice(2, 1);
        }
        if (!this.showChildBreakDown) {
          this.items.splice(1, 1);
        }
        if (!this.showAdultBreakDown) {
          this.items.splice(0, 1);
        }
        return this.tableRows;
      } else {
        return false;
      }
    },
    formatCurrency(amount: number, currency_format: string) {
      return getCurrencyFormatter(currency_format).format(amount);
    },
    showEditTicket() {
      return (
        this.showAdultBreakDown ||
        this.showChildBreakDown ||
        this.showInfantBreakDown
      );
    },
    saveAndDownload() {
      const adultItem = this.items.find((item) => item.paxType === "Adult");
      this.perAdultEditedFare = adultItem
        ? Number(adultItem?.updatedAmount)
        : 0;
      const childItem = this.items.find((item) => item.paxType === "Child");
      this.perChildEditedFare = childItem
        ? Number(childItem?.updatedAmount)
        : 0;
      const infantItem = this.items.find((item) => item.paxType === "Infant");
      this.perInfantEditedFare = infantItem
        ? Number(infantItem?.updatedAmount)
        : 0;
      const payload = {
        adultItem,
        childItem,
        infantItem,
      };
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.EDIT_FARE,
        payload
      );
      this.onDownloadTicket(
        this.canRenderFare,
        this.canEditFare,
        this.perAdultEditedFare,
        this.perChildEditedFare,
        this.perInfantEditedFare
      );
      this.isEditModalOpen = false;
      this.canRenderFare = false;
      this.canEditFare = false;
    },
    onSendAirlineTicket(emails: string[]) {
      this.$store.dispatch("sendAirlineTicket", {
        payload: {
          pnr: this.supplierPnr,
          airline_provider: this.flightDetails?.flight_sub_provider,
          emails,
        } as SendAirlineTicketBodyRequest,
        callback: () => (this.showAirlineTicketModal = false),
      });
    },
    showDownloadAirlineTicketButton() {
      const status = this.renderBookingStatus;
      const supplier = this.flightDetails?.flight_sub_provider;
      const isAllowedSupplier = ALLOWED_SUPPLIERS.includes(supplier);
      return (
        status === BOOKING_STATUS.ISSUED.toUpperCase() && isAllowedSupplier
      );
    },
    onClickAirlineTicket() {
      // this.activeBooking = booking;
      this.showAirlineTicketModal = true;
    },
    onProceedToIssuance() {
      const flight_sub_provider: string =
        this.flightDetails?.flight_sub_provider || "";
      const sub_providers_enum: IObject = {
        FLYJINNAH: "fly_jinnah",
        AIRARABIA: "air_arabia",
      };
      const supplier = Object.keys(sub_providers_enum).includes(
        this.flightDetails?.flight_sub_provider
      )
        ? sub_providers_enum[flight_sub_provider]
        : flight_sub_provider;

      const airline =
        this.flightDetails?.flight_legs[0].segment[0].marketing_airline.code;

      this.$router.push({
        path: PATH.FLIGHT_ISSUANCE,
        query: {
          pnr: this.supplierPnr,
          supplier,
          ...(supplier === "SABRE" && { sabre_airline: airline }),
        },
      });
    },
    onSuccessHandler() {
      const booking_id = this.$route.params.id;
      this.$store.dispatch("getFlightBooking", { booking_id });
    },
    onCancelBooking() {
      const booking_id = this.$route.params.id;
      this.isCancelationDialogOpen = false;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("cancelFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
    handleCloseCancelationDialog() {
      this.isCancelationDialogOpen = false;
    },
    onDownloadTicket(
      render_fare: boolean,
      is_edited: boolean,
      per_adult_edited_fare: number,
      per_child_edited_fare: number,
      per_infant_edited_fare: number
    ) {
      const booking_id = this.$route.params.id;
      const payload = {
        booking_id,
        render_fare,
        is_edited,
        per_adult_edited_fare,
        per_child_edited_fare,
        per_infant_edited_fare,
      };
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_TICKET_DOWNLOAD,
        payload
      );
      this.$store.dispatch("downloadFlightBooking", payload);
    },
    handlePriceUpdateContinue() {
      this.isPriceUpdatedDialog = false;
    },
    onVoidPIATicket() {
      const booking_id = this.$route.params.id;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("voidPIAFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
    onOpenEditFareModalHandler() {
      this.isEditModalOpen = true;
      this.showFareOnDownload = true;
      this.populateTable();
      return this.showFareOnDownload;
    },
    onShowFareHandler() {
      this.canRenderFare = true;
      this.showEditOnDownload = true;
    },
    onHideFareHandler() {
      this.canRenderFare = false;
      this.canEditFare = false;
      this.showEditOnDownload = false;
    },
    onShowEditedFareHandler() {
      this.canEditFare = true;
    },
    onHideEditedFareHandler() {
      this.canEditFare = false;
    },
    getBaggageInfo(baggageInfo: any) {
      let baggageDetails;
      if (!baggageInfo || Object.keys(baggageInfo).length === 0) {
        baggageDetails = "No Baggage";
      } else {
        if (
          baggageInfo.pieces === 0 ||
          baggageInfo.pieces === null ||
          baggageInfo.weight === null ||
          baggageInfo.unit === null
        ) {
          baggageDetails = "(As per airline policy)";
        } else {
          baggageDetails =
            baggageInfo.pieces +
            " piece of " +
            baggageInfo.weight +
            " " +
            baggageInfo.unit;
        }
      }
      return baggageDetails;
    },
    getMealInfo(mealInfo: boolean) {
      return mealInfo === true ? "Meal Included" : "No Meal Included";
    },
    AdditionalDetails() {
      const totalFlightLegs = this.flightLegs;
      for (let leg = 0; leg < totalFlightLegs; leg++) {
        const flightLeg = this.flightDetails?.flight_legs[leg];
        const baggageInfo = flightLeg?.baggage_info;
        const mealInfo: boolean = flightLeg?.has_meal;
        let details = {
          mealDetails: this.getMealInfo(mealInfo),
          baggageDetails: this.getBaggageInfo(baggageInfo),
        };
        this.legAdditionalDetails.push(details);
      }
      return this.legAdditionalDetails;
    },
  },
  computed: {
    flightLegs() {
      return this.$store.getters.flightDetails?.flight_legs.length;
    },
    renderAdultPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency
      );
      const amount =
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .value;
      return {
        label: `${flightDetails.adult_count}`,
        price,
        amount,
      };
    },
    renderChildPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency
      );
      const amount =
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .value;
      return {
        label: `${flightDetails.child_count}`,
        price,
        amount,
      };
    },
    renderInfantPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant
            ?.ag_total_amount.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency
      );
      const amount =
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .value;
      return {
        label: `${flightDetails.infant_count}`,
        price,
        amount,
      };
    },
    showAdultBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.adult_count) > 0;
    },
    showChildBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.child_count) > 0;
    },
    showInfantBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.infant_count) > 0;
    },
    defaultCurrency() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return flightDetails?.price?.pax_type_price_breakdown?.adult
        ?.ag_total_amount.currency;
    },
    Currency() {
      return localStorage.getItem("currency") || "PKR";
    },
    isFlightWaitListed() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (_.capitalize(bookingStatus) === BOOKING_STATUSES.WAITLIST) {
        return true;
      } else {
        return false;
      }
    },
    isInitiateBooking(): boolean {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      return _.capitalize(bookingStatus) === BOOKING_STATUS.INITIATED;
    },
    renderBookingId() {
      return this.$route.params.id;
    },
    renderBookingStatus() {
      return this.$store.getters.flightBookingStatus;
    },
    flightDetails() {
      return this.$store.getters.flightDetails;
    },
    supplierPnr() {
      return this.$store.state?.flightBookingModule?.supplierPnr;
    },
    pnrExpiresAt() {
      const pnrExpiryTime =
        this.$store.state?.flightBookingModule?.pnrExpiryDate;
      if (pnrExpiryTime) {
        const utcZonedPnrExpiryTime = handleConvertUTCtoZonedTime(
          pnrExpiryTime
        ) as string;
        return utcZonedPnrExpiryTime
          ? format(
              new Date(utcZonedPnrExpiryTime),
              FORMAT_DD_MM_YYYY_HH_MM_24_HOURS
            )
          : null;
      }
      return "";
    },
    isLoading() {
      return this.$store.getters.isFlightBookingLoading;
    },
    isCancelBookingLoading() {
      return this.$store.getters.isFlightCancelBookingLoading;
    },
    isDownloadTicketLoading() {
      return this.$store.getters.isDownloadTicketLoading;
    },
    isBookingCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      return (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      );
    },
    luggageInfo(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info = flight_legs.find((x: FlightLeg) => x.baggage_info) || {};
      if (!_.isEmpty(leg_info?.baggage_info)) {
        return renderBaggageInfo(leg_info?.baggage_info);
      }
      return "";
    },
    hasReturnluggageInfo(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs =
        this.flightDetails?.flight_legs[1]?.baggage_info || "";
      if (flight_legs) {
        return renderBaggageInfo(flight_legs);
      }
      return "";
    },
    hasMeal(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info: FlightLeg =
        flight_legs.find((x: FlightLeg) => x.baggage_info) || {};
      return leg_info.has_meal;
    },
    isReturnMeal(): boolean {
      return this.flightDetails?.flight_legs[1]?.has_meal || false;
    },
    renderStatusSeverity() {
      switch (_.capitalize(this.renderBookingStatus)) {
        case BOOKING_STATUSES.CANCELED:
          return "danger";
        case BOOKING_STATUSES.CONFIRMED:
          return "primary";
        case BOOKING_STATUSES.ISSUED:
          return "primary";
        case BOOKING_STATUSES.WAITLIST:
          return "danger";
        default:
          return "primary";
      }
    },
    showAdditionalDetails() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.hasMeal || !!this.luggageInfo;
    },
    showAdditionalDetailsOnReturn() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.isReturnMeal || !!this.hasReturnluggageInfo;
    },
    isVoidFlightTicketLoading() {
      return this.$store.getters.isVoidFlightTicketLoading;
    },
    isIssuedAndisPIAAndisNotInternational() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      const isInternational =
        this.$store.getters.flightDetails.is_international;
      const isPIA =
        this.$store.getters.flightDetails?.flight_legs[0].segment[0]
          .operating_airline.name;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED &&
        isInternational === false &&
        isPIA === "Pakistan International Airlines"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightIssued() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasPassengers() {
      const flightDetails = this.$store.getters.flightDetails;
      return Boolean(flightDetails?.passengers?.length);
    },
  },
  beforeMount() {
    const booking_id = this.$route.params.id;
    this.$store.dispatch("getFlightBooking", { booking_id });
  },

  watch: {
    "$store.getters.flightDetails": {
      handler(flightDetails) {
        const bookingStatus = this.$store.getters.flightBookingStatus as string;
        const isBookingWaitlisted =
          bookingStatus === BOOKING_STATUSES.WAITLIST.toLocaleUpperCase();

        if (isBookingWaitlisted) {
          this.isWaitListDialog = true;
        }
        const isBookingConfirmed =
          bookingStatus === BOOKING_STATUSES.CONFIRMED.toLocaleUpperCase();

        if (isBookingConfirmed && flightDetails?.price?.fare_updated) {
          this.isPriceUpdatedDialog = true;
        }
      },
    },
  },
});
export interface SendAirlineTicketBodyRequest {
  pnr: string;
  airline_provider: string;
  emails: string[];
}
</script>

<style scoped>
.selected {
  border: 2px solid var(--green-color) !important;
}
</style>
