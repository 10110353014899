<template>
  <m-card class="m-flight-summary">
    <div class="m-wrapper">
      <table class="m-table">
        <thead>
          <template v-for="(header, index) in headers" :key="index">
            <th>{{ header }}</th>
          </template>
        </thead>

        <tbody>
          <template
            v-for="(traveler, index1) in paginatedTravelers"
            :key="index1"
          >
            <tr :class="travelers.length > 0 ? 'bordered' : ''">
              <td>{{ traveler.name }}</td>
              <td>{{ traveler.surname }}</td>
              <td>{{ traveler.type }}</td>
              <td>{{ traveler.birthdate }}</td>
              <td>{{ traveler.base_fare }}</td>
              <td>{{ traveler.surcharges }}</td>
              <td>{{ traveler.taxes }}</td>
              <td>{{ traveler.fees }}</td>
              <td>{{ traveler.total_fare }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <div class="m-pagination">
        <div class="rows-per-page">
          <m-typography type="body">Rows per page</m-typography>
          <input
            v-model="rowsPerPage"
            class="rows-count"
            type="number"
            min="1"
          />
        </div>
        <div class="count">
          <m-typography type="body">{{ paginationRange }}</m-typography>
        </div>
        <div class="controls">
          <div
            class="control-action first-page"
            @click="goToFirstPage"
            :disabled="isFirstPage"
          >
            <m-first-page-icon />
          </div>

          <div
            class="control-action previous"
            @click="goToPreviousPage"
            :disabled="isFirstPage"
          >
            <m-previous-page-icon />
          </div>

          <div
            class="control-action next"
            @click="goToNextPage"
            :disabled="isLastPage"
          >
            <m-next-page-icon />
          </div>

          <div
            class="control-action last-page"
            @click="goToLastPage"
            :disabled="isLastPage"
          >
            <m-last-page-icon />
          </div>
        </div>
      </div>
    </div>
  </m-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  MTypography,
  MCard,
  MFirstPageIcon,
  MLastPageIcon,
  MNextPageIcon,
  MPreviousPageIcon,
} from "@aeroglobe/ag-core-ui";

import { PassengerTraveler } from "@/modules/FlightIssuance/dtos/flightIssuance.dto";

export default defineComponent({
  name: "MFlightTravelers",
  components: {
    MCard,
    MTypography,
    MFirstPageIcon,
    MLastPageIcon,
    MNextPageIcon,
    MPreviousPageIcon,
  },
  props: {
    travelers: {
      type: Array as PropType<PassengerTraveler[]>,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        "Passenger Name",
        "Surname",
        "Passenger Type",
        "Birth Date",
        "Ticket Base Fare",
        "Surcharges",
        "Tax",
        "Fees",
        "Total Fare",
      ],

      rowsPerPage: 2,
      currentPage: 1,
    };
  },
  watch: {
    rowsPerPage() {
      this.currentPage = 1;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.travelers.length / this.rowsPerPage);
    },
    paginatedTravelers() {
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;
      return this.travelers.slice(startIndex, endIndex);
    },
    paginationRange(): string {
      const startRange = (this.currentPage - 1) * this.rowsPerPage + 1;
      const endRange = Math.min(
        this.currentPage * this.rowsPerPage,
        this.travelers.length
      );
      return `${startRange}-${endRange} of ${this.travelers.length}`;
    },

    isFirstPage(): boolean {
      return this.currentPage === 1;
    },

    isLastPage(): boolean {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    goToFirstPage() {
      this.currentPage = 1;
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToLastPage() {
      this.currentPage = this.totalPages;
    },
  },
});
</script>

<style scoped>
.m-flight-summary {
  width: 100%;
  display: flex;
  gap: 16px;

  --t-border-color: #ececec;
}

.m-wrapper {
  width: 100%;

  overflow-x: auto;

  border-radius: 4px;
  border: 1px solid var(--t-border-color);
}

.m-table {
  width: 100%;
  min-width: 1080px;

  border-collapse: collapse;
}

.m-table th {
  text-align: left;

  color: #49454f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.8px;

  padding: 16px;

  border-bottom: 1px solid var(--t-border-color);
}

.m-table tr {
  color: var(--label-color);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.8px;
}

.m-table tr.bordered {
  border-bottom: 1px solid var(--t-border-color);
}

.m-table td {
  padding: 16px;
}

.m-pagination {
  width: 100%;
  min-width: 1080px;

  padding: 16px;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.m-pagination .rows-per-page {
  display: flex;
  align-items: center;
  gap: 16px;
}

.m-pagination .rows-per-page .rows-count {
  width: 58px;
  padding: 8px;

  color: rgba(73, 69, 79, 0.87);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;

  border-radius: 4px;
  border: 1px solid rgba(73, 69, 79, 0.12);
}

.m-pagination .controls {
  display: flex;
  align-items: center;
  gap: 24px;
}

.m-pagination .controls > .control-action {
  width: 32px;
  height: 32px;

  cursor: pointer;
}

.m-pagination .controls > .control-action[disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.m-pagination .controls > .control-action > svg {
  opacity: 0.38;
}

.m-pagination .controls > .control-action:hover > svg {
  opacity: 100%;
}
</style>
