<template>
  <AgDiv>
    <span class="add_service">
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/breakfast.png?alt=media&amp;token=f8de8953-6d82-421f-8b73-af071d91e822"
      />
      <span class="title">Meal included</span>
    </span>
    <br />
    <span class="add_service">
      <img
        class="image"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/travel-luggage.png?alt=media&amp;token=5bd10394-1735-4d38-91b6-a1c0e1d62064"
      />
      <span class="title"> Luggage included: {{ luggageInfo }} </span>
    </span>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdditionalDetailsCard",
  props: {
    hasMeal: {
      type: String,
    },
    luggageInfo: {
      type: String,
    },
  },
});
</script>

<style scoped lang="scss">
.title {
  font-weight: bold;
}

.image {
  width: 35px;
  margin-right: 10px;
}
.add_service {
  display: flex;
  align-items: center;
}
</style>
