<template>
  <div class="issuance">
    <section>
      <m-typography type="display"> Add Information </m-typography>
      <m-card>
        <div class="issuance-inputs">
          <MFinancialProfileCombobox
            v-model:inputValue="flightInformation.financialProfile.value"
            label="Financial Profiles"
            itemValue="value"
            itemLabel="label"
            :hasError="flightInformation.financialProfile.hasError"
            :errorMessage="flightInformation.financialProfile.errorMessage"
            :disabled="isPnrFetched || isFetchingPNR"
            :options="financialProfiles"
          />

          <div class="row">
            <m-combobox
              v-model:inputValue="flightInformation.supplier.value"
              :options="suppliers"
              itemValue="value"
              itemLabel="label"
              label="Airline/Supplier"
              :hasError="flightInformation.supplier.hasError"
              :errorMessage="flightInformation.supplier.errorMessage"
              :disabled="isPnrFetched || isFetchingPNR"
            />

            <m-textfield
              v-model:inputValue.trim="flightInformation.pnrNumber.value"
              label="PNR Number"
              :hasError="flightInformation.pnrNumber.hasError"
              :errorMessage="flightInformation.pnrNumber.errorMessage"
              :disabled="isPnrFetched || isFetchingPNR"
            />
          </div>

          <div class="row">
            <m-textarea
              v-model:inputValue.trim="flightInformation.comments.value"
              label="Comments"
              :disabled="isPnrFetched || isFetchingPNR"
            />

            <template v-if="isSabreSelected">
              <m-combobox
                v-model:inputValue="flightInformation.sabreAirline.value"
                :options="sabreAirlines"
                itemValue="value"
                itemLabel="label"
                label="Sabre Airline"
                :hasError="flightInformation.sabreAirline.hasError"
                :errorMessage="flightInformation.sabreAirline.errorMessage"
                :disabled="isPnrFetched || isFetchingPNR"
              />
            </template>
          </div>
        </div>

        <div class="actions">
          <m-button
            class="issuance-action"
            :disabled="!canFetchPnrDetails || isPnrFetched"
            type="filled"
            @click="canFetchPnrDetails ? handleFetchPnrDetails() : ''"
          >
            {{ isFetchingPNR ? "Fetching PNR" : "Get PNR Details" }}
          </m-button>

          <m-button
            class="issuance-action"
            :disabled="infoHasErrors() || !isPnrFetched"
            type="outlined"
            @click="infoHasErrors() || !isPnrFetched ? '' : handleDataReset()"
          >
            Reset
          </m-button>
        </div>

        <m-typography type="label" v-show="showSabreAirlineInfoMessage">
          {{ sabreAirlineInfoMessage }}
        </m-typography>
      </m-card>
    </section>

    <template v-if="isPnrFetched && !isTicketIssued">
      <section id="flightSummary">
        <m-typography type="display"> Flight Summary </m-typography>
        <m-flight-summary
          :totalPricing="totalPricing"
          :agPricing="agPricing"
          :flightDetails="flightDetails"
          :travelersCount="travelers ? travelers.length : 0"
        />
      </section>

      <section>
        <m-typography type="display"> Travelers </m-typography>
        <m-flight-travelers :travelers="travelers" />
      </section>

      <section>
        <m-typography type="display"> Issue Ticket </m-typography>

        <m-card>
          <div class="m-issue-ticket">
            <m-textfield
              class="m-otp"
              v-model:inputValue="OTP.value"
              label="OTP"
              :disabled="isIssueTicketLoading"
              :hasError="OTP.hasError"
              :errorMessage="OTP.errorMessage"
              @change="handleFlightIssuanceErrors()"
            />

            <m-button
              class="m-issue-ticket-action"
              :disabled="!canIssueFlightTicket"
              type="filled"
              @click="canIssueFlightTicket ? handleFlightTicketIssuance() : ''"
            >
              Issue Ticket
            </m-button>
          </div>
        </m-card>
      </section>
    </template>

    <template v-else-if="isTicketIssued">
      <section>
        <m-typography type="display"> Issuance Status </m-typography>
        <m-card class="m-success-message"> Ticket Issued Successfully </m-card>
      </section>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";

import {
  MTypography,
  MCard,
  MTextfield,
  MTextarea,
  MCombobox,
  MButton,
  MFinancialProfileCombobox,
} from "@aeroglobe/ag-core-ui";

import MFlightSummary from "@/components/MFlightSummary.vue";
import MFlightTravelers from "@/components/MFlightTravelers.vue";

import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import {
  ISabreAirlineConfig,
  ISettings,
} from "@/ag-portal-common/interfaces/settings.interface";
import { SUPPLIER_NAMES } from "@/ag-flight-components/enums/supplier_names";
import { fetchPNRValidation } from "@/ag-flight-components/validations/pnrFetchValidation";
import {
  PNRDetails,
  PassengersList,
  ISSUE_TICKET_PAYLOAD,
} from "@/ag-flight-components/types";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
import {
  IFlightDetails,
  PassengerTraveler,
} from "@/modules/FlightIssuance/dtos/flightIssuance.dto";
import { ValidationError } from "yup";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { IUser, IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { issueTicketValidation } from "@/ag-flight-components/validations/issueTicketValidation";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { FLIGHT_ISSUANCE_ANALYTICS_EVENTS } from "@/modules/FlightIssuance/constants/anaylticsEvents";
import analyticsService from "@/services/analytics.service";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";

export default defineComponent({
  name: "FlightIssuance",
  components: {
    MTypography,
    MCard,
    MTextfield,
    MTextarea,
    MCombobox,
    MButton,
    MFlightSummary,
    MFlightTravelers,
    MFinancialProfileCombobox,
  },
  async mounted() {
    let organization =
      (this.organization() as IOrganizationFromLoginResponse) || null;

    if (!this.isSuperUserOrOperationUser) {
      let defaultFinancialProfile = organization.financial_profiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.flightInformation.financialProfile.value =
          defaultFinancialProfile.public_id;
      }

      this.handleFlightIssuanceErrors();
    }
  },
  created() {
    if (this.isSuperUserOrOperationUser) {
      this.$store.dispatch("fetchFinancialProfiles", { is_linked: true });
    }
  },
  async beforeMount() {
    this.handleDataReset();

    await this.handleFetchPnrDetailsByRoute();
  },
  unmounted() {
    this.handleDataReset();
  },
  watch: {
    pnrNumber(value: string) {
      this.flightInformation.pnrNumber.value = value;
    },
    "flightInformation.pnrNumber.value"(value: string) {
      this.$store.commit("setPnr", value);
    },
  },
  computed: {
    canFetchPnrDetails(): boolean {
      return !(this.infoHasErrors() || this.$store.getters.isFetchingPNR);
    },
    canIssueFlightTicket(): boolean {
      return !(
        this.OTP.hasError ||
        this.isIssueTicketLoading ||
        this.infoHasErrors()
      );
    },

    pnrNumber(): string {
      return this.$store.getters.pnr;
    },

    financialProfiles(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUser) {
        financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      } else {
        const organization =
          this.organization() as IOrganizationFromLoginResponse;
        financialProfiles = organization?.financial_profiles;
      }

      if (financialProfiles) {
        return financialProfiles?.map((fp: IFinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },

    suppliers() {
      let settings = this.settings() as ISettings | null;
      return settings?.airline
        ?.filter((airline) => airline?.is_ticket_issuance_enabled)
        ?.map((airline) => ({
          label: airline.name,
          value: airline.name,
        }));
    },
    sabreAirlines() {
      let settings = this.settings() as ISettings | null;

      return settings?.sabre_airlines?.map((item) => ({
        label: item.airline_code,
        value: item.airline_code,
      }));
    },

    isSabreSelected(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    showSabreAirlineInfoMessage(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    sabreAirlineInfoMessage(): string {
      if (!this.isSabreSelected) return "";

      const sabreAirline = this.findSabreAirlineByAirlineCode(
        this.flightInformation.sabreAirline.value
      ) as ISabreAirlineConfig | null;

      if (!sabreAirline) return "";

      if (sabreAirline.pcc) {
        return `Please queue on "${sabreAirline.pcc}" for ${
          sabreAirline.airline_code
        }. ${
          sabreAirline.airline_code === "PK"
            ? "Only PK international routes can be issued via sabre"
            : ""
        }`;
      }

      return "This airline cannot be issued through portal, please issue through whatsapp or email.";
    },

    isFetchingPNR(): boolean {
      return this.$store.getters.isFetchingPNR;
    },
    isPnrFetched: {
      get(): boolean {
        if (this.$store.getters.isFetchingPNR) return false;
        return this.pnrDetails !== null;
      },
      set() {
        this.$store.commit("resetPNRDetails");
      },
    },

    pnrDetails(): PNRDetails {
      return this.$store.getters.pnrDetails;
    },
    totalPricing(): string {
      if (this.isPnrFetched) {
        const totalPricing = this.pnrDetails.total_amount.value;
        return getCurrencyFormatter(
          this.pnrDetails.total_amount.currency
        ).format(totalPricing);
      } else {
        return "";
      }
    },
    agPricing(): string {
      if (this.isPnrFetched) {
        const agPrice = this.pnrDetails.ag_total_amount?.value;
        return agPrice
          ? getCurrencyFormatter(
              this.pnrDetails.ag_total_amount?.currency
            ).format(agPrice)
          : "N/A";
      } else {
        return "";
      }
    },
    flightDetails(): IFlightDetails[] {
      if (this.isPnrFetched) {
        const flights = this.pnrDetails.flight_details as IFlightDetails[];
        return flights;
      } else {
        return [];
      }
    },
    travelers(): PassengerTraveler[] {
      const travelers = this.$store.getters.airTravelers.map(
        (traveler: PassengersList) => {
          const title = traveler.title;
          const personName = traveler.person_name;
          const surname = traveler.surname;
          const passengerTypeCode = this.formatString(
            traveler?.passenger_type_code
          );
          const birthdate = this.formatString(traveler?.birthdate);
          const baseFare =
            traveler?.pricing_detail?.fare.value === null
              ? "-"
              : traveler?.pricing_detail?.fare.currency +
                this.formatString(traveler?.pricing_detail?.fare.value);
          const surcharges =
            traveler?.pricing_detail?.surcharges.value === null
              ? "-"
              : traveler?.pricing_detail?.surcharges.currency +
                this.formatString(traveler?.pricing_detail?.surcharges.value);
          const taxes =
            traveler?.pricing_detail?.tax.value === null
              ? "-"
              : traveler?.pricing_detail?.tax.currency +
                this.formatString(traveler?.pricing_detail?.tax.value);
          const totalFare =
            traveler?.pricing_detail?.total_fare.value === null
              ? "-"
              : traveler?.pricing_detail?.total_fare.currency +
                this.formatString(traveler?.pricing_detail?.total_fare.value);
          const fees =
            traveler?.pricing_detail?.fees.value === null
              ? "-"
              : traveler?.pricing_detail?.fees.currency +
                this.formatString(traveler?.pricing_detail?.fees.value);

          return {
            name: `${title} ${personName}`,
            surname: surname,
            type: passengerTypeCode,
            birthdate: birthdate,
            base_fare: `${baseFare}`,
            surcharges: `${surcharges}`,
            taxes: `${taxes}`,
            total_fare: `${totalFare}`,
            fees: `${fees}`,
          };
        }
      );
      return travelers;
    },
    isIssueTicketLoading(): boolean {
      return this.$store.getters.isIssueTicketLoading;
    },
    isTicketIssued(): boolean {
      return this.$store.getters.isTicketIssued;
    },
    isSuperUserOrOperationUser(): boolean {
      return [USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS].includes(
        this.userRole()
      );
    },
  },
  data() {
    return {
      options: [
        {
          code: "PK",
          country: "Pakistan",
        },
        {
          code: "AG",
          country: "Afghanistan",
        },
      ],
      flightInformation: {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        comments: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      OTP: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      validateFlightInfoErrors: false,
      validateFlightIssuanceErrors: false,
    };
  },
  methods: {
    findSabreAirlineByAirlineCode(
      airline_code: string
    ): ISabreAirlineConfig | null {
      let settings = this.settings() as ISettings | null;
      return (
        settings?.sabre_airlines?.find(
          (airline) => airline.airline_code === airline_code
        ) || null
      );
    },
    transformPnrData() {
      let settings = this.settings() as ISettings | null;

      const { pnrNumber, comments, sabreAirline, supplier } =
        this.flightInformation;

      const mappedData = {
        financial_profile_public_id:
          this.flightInformation.financialProfile.value,
        pnr: pnrNumber.value.trim(),
        comments: comments.value.trim(),
        airline:
          settings?.airline?.find((airlineSupplier) => {
            return (
              airlineSupplier.name.toLowerCase() ===
              supplier.value.toLowerCase()
            );
          })?.value || null,
        ...(this.isSabreSelected && {
          airline_code:
            settings?.sabre_airlines?.find((airline) => {
              return airline.airline_code === sabreAirline.value;
            })?.airline_code || null,
        }),
      };
      return mappedData;
    },

    infoIsEmpty() {
      const transformedArray = Object.keys(this.flightInformation).map(
        (key) => (this.flightInformation as any)[key]
      );

      return transformedArray.every((item) => item.value === "");
    },
    infoHasErrors() {
      const transformedArray = Object.keys(this.flightInformation).map(
        (key) => (this.flightInformation as any)[key]
      );

      this.handleFlightInfoErrors();

      return transformedArray.some((item) => item.hasError);
    },

    handleFlightInfoErrors() {
      if (this.validateFlightInfoErrors) {
        const { financialProfile, supplier, pnrNumber, sabreAirline } =
          this.flightInformation;

        if (financialProfile.value === "") {
          financialProfile.hasError = true;
          financialProfile.errorMessage = "Financial Profile is required";
        } else {
          financialProfile.hasError = false;
          financialProfile.errorMessage = "";
        }

        if (supplier.value === "") {
          supplier.hasError = true;
          supplier.errorMessage = "Supplier is required";
        } else {
          supplier.hasError = false;
          supplier.errorMessage = "";
        }

        if (pnrNumber.value === "") {
          pnrNumber.hasError = true;
          pnrNumber.errorMessage = "PNR is required";
        } else {
          pnrNumber.hasError = false;
          pnrNumber.errorMessage = "";
        }

        if (this.isSabreSelected) {
          if (sabreAirline.value === "") {
            sabreAirline.hasError = true;
            sabreAirline.errorMessage = "Sabre Airline is required";
          } else {
            sabreAirline.hasError = false;
            sabreAirline.errorMessage = "";
          }
        }
      }
    },
    handleFlightIssuanceErrors() {
      if (this.validateFlightIssuanceErrors) {
        if (this.OTP.value === "") {
          this.OTP.hasError = true;
          this.OTP.errorMessage = "OTP is required";
        } else {
          this.OTP.hasError = false;
          this.OTP.errorMessage = "";
        }
      }
    },
    handleDataReset() {
      this.flightInformation = {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        comments: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.OTP = {
        value: "",
        hasError: false,
        errorMessage: "",
      };

      this.isPnrFetched = false;
      this.validateFlightInfoErrors = false;
      this.validateFlightIssuanceErrors = false;

      this.$store.commit("resetPNRDetails");

      this.handleFlightIssuanceErrors();
    },

    formatString<T extends number | string>(value: T | undefined): string {
      return value ? String(value) : typeof value === "number" ? "0" : "";
    },

    // FETCH PNR BY ROUTE
    async handleFetchPnrDetailsByRoute() {
      const settings = this.settings() as ISettings | null;

      const PNR = this.$route.query.pnr as string | null;
      const SUPPLIER = this.$route.query.supplier as string | null;
      const SABRE_AIRLINE = this.$route.query.sabre_airline as string | null;

      if (!(PNR && SUPPLIER)) {
        return;
      }

      this.$store.commit("setPnr", PNR);

      if (SUPPLIER.toLowerCase() === SUPPLIER_NAMES.SABRE) {
        this.flightInformation.sabreAirline.value = SABRE_AIRLINE as string;
      }

      const airline = settings?.airline?.find(
        (airline) => airline.name.toLowerCase() === SUPPLIER.toLowerCase()
      );

      this.flightInformation.supplier.value = airline?.name || "";

      setTimeout(async () => {
        await this.handleFetchPnrDetails();
      }, 300);
    },

    // FETCH PNR
    async handleFetchPnrDetails() {
      this.validateFlightInfoErrors = true;

      this.handleFlightInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      try {
        const payload = this.transformPnrData();

        const request = await fetchPNRValidation.validate(payload, {
          abortEarly: false,
        });

        await this.$store.dispatch("fetchPNR", request);

        analyticsService.logActionEvent(
          FLIGHT_ISSUANCE_ANALYTICS_EVENTS.FETCH_PNR_DETAILS,
          payload
        );

        this.scrollToSummary();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightInfoErrors();
      }
    },

    // ISSUE TICKET
    async handleFlightTicketIssuance() {
      let user = this.user() as IUser | null;

      this.validateFlightIssuanceErrors = true;

      this.handleFlightIssuanceErrors();

      if (this.OTP.hasError) {
        return;
      }

      try {
        const payload: ISSUE_TICKET_PAYLOAD = {
          pnr: this.$store.getters.pnr.toUpperCase(),
          reference: this.pnrDetails.reference,
          financial_profile_public_id:
            this.flightInformation.financialProfile.value,
          emails: [user?.email ?? ""],
          otp: this.OTP.value,
        };

        await issueTicketValidation.validate(
          { otp: this.OTP.value },
          { abortEarly: false }
        );

        await this.$store.dispatch("issueTicket", payload);

        analyticsService.logActionEvent(
          FLIGHT_ISSUANCE_ANALYTICS_EVENTS.FLIGHT_TICKET_ISSUANCE,
          payload
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightIssuanceErrors();
      }
    },

    scrollToSummary() {
      const flightSummary = document.getElementById("flightSummary");

      if (flightSummary) {
        flightSummary.scrollIntoView({ behavior: "smooth" });
      }
    },
    userRole(): number {
      let user = this.user() as IUserV2 | null;
      return user?.role_unique_id as number;
    },
  },

  setup() {
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUser | null;
    let settings = inject(AUTH_CONTEXT_KEYS.settings) as () => ISettings | null;
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;

    return {
      settings,
      user,
      organization,
    };
  },
});
</script>

<style scoped>
.issuance {
  --text-color: #49454f;
  --label-color: #636363;
  --border-color: #ababab;
  --error-color: #b3261e;
  --theme-color: var(--green-color);

  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 50px;
}

.issuance-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issuance-inputs,
.issuance-action {
  margin-bottom: 16px;
}

.issuance-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.issuance .actions {
  display: flex;
  gap: 16px;
}

.m-issue-ticket {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.m-issue-ticket .m-otp {
  max-width: 50%;
}

.m-success-message {
  color: #22bb33;
}

@media screen and (max-width: 600px) {
  .issuance-inputs .row {
    display: flex;
    flex-direction: column;
  }

  .m-issue-ticket .m-otp {
    max-width: none;
  }
}
</style>
