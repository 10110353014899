<template>
  <ag-loader v-if="isLoading" />
  <template v-else>
    <AgCard test-id="">
      <ag-row>
        <ag-column xs="12" sm="12" md="8" lg="9">
          <AgSelectedFlightInfo
            :date="formatTripDates"
            :adult="$store.state.flightBookingModule.flightDetails?.adult_count"
            :children="
              $store.state.flightBookingModule.flightDetails?.child_count
            "
            :infant="
              $store.state.flightBookingModule.flightDetails?.infant_count
            "
          >
            <template #destination>
              {{ formatLegsInfo }}
            </template>
          </AgSelectedFlightInfo>
        </ag-column>
        <ag-column xs="12" sm="12" md="3">
          <AgSelectedFlightInfo>
            <template #pnrInfo>
              <AgDiv class="booking_id_wrap"
                >Booking Confirmation <span>{{ renderBookingId }}</span></AgDiv
              >
            </template>
          </AgSelectedFlightInfo>
        </ag-column>
      </ag-row>
    </AgCard>
    <!-- Contact Details -->
    <AgRow test-id="">
      <AgColumn test-id="" md="8" lg="9" cols="12">
        <!-- Lead Contact Box -->
        <AgCard test-id="">
          <AgIconInfoBar
            icon="contactPhoneIcon"
            title="Contact Details"
            test-id=""
          />
          <ag-accordion
            class="flight_accordion_wrap margin_bottom_0"
            :panel-value="[0]"
          >
            <ag-accordion-panel>
              <template #flightInfo>
                <ag-heading
                  variant="h3"
                  title="Lead Traveler"
                  class="margin_bottom_0"
                ></ag-heading>
              </template>
              <template #tabSection>
                <AgRow test-id="" class="padding_top_20">
                  <AgColumn test-id="" md="7" lg="7" cols="12">
                    <AgRow test-id="">
                      <AgColumn test-id="" md="12" lg="9">
                        <AgPhoneField
                          test-id=""
                          default-country-code="PK"
                          info-message="Please input the traveler’s
                        number here to receive flight updates"
                          :error="renderMobileNumberError()"
                          @update-value="handleMobileFieldChangeNew"
                        />
                      </AgColumn>
                    </AgRow>
                  </AgColumn>
                  <AgColumn test-id="" md="5" lg="5" cols="12">
                    <!-- Side Box -->
                    <AgTravelDetail
                      test-id=""
                      :items="{
                        'Mobile Number':
                          $store.state.flightBookingModule.mobileNumber
                            ?.formatInternational,
                      }"
                    >
                      <template #headingArea>
                        <AgHeading
                          variant="h2"
                          title="Contact Details"
                          class="margin_bottom_5"
                        />
                      </template>
                    </AgTravelDetail>
                  </AgColumn>
                </AgRow>
              </template>
            </ag-accordion-panel>
          </ag-accordion>
        </AgCard>

        <TavelerCard
          v-for="(traveler, index) in travelers"
          :traveler="traveler"
          :errors="errors"
          :index="index"
          :key="index"
        />

        <AgCard test-id="">
          <AgDiv test-id="" class="text-right">
            <AGButton
              test-id=""
              type="button"
              @click="handleConfirmBooking"
              :is-loading="isConfirmBookingLoading"
              >Confirm Booking</AGButton
            >
          </AgDiv>
        </AgCard>
      </AgColumn>
      <AgColumn test-id="" md="4" lg="3" cols="12">
        <ag-card>
          <PriceSummary />
        </ag-card>
        <ag-heading
          variant="h2"
          title="Trip Summary"
          class="margin_bottom_10"
        />
        <TripSummary :legs="getAllLegs" />
      </AgColumn>
    </AgRow>
  </template>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { date, ValidationError } from "yup";
import { differenceInYears, differenceInMonths } from "date-fns";
import { addDays, format } from "date-fns";
import _ from "lodash";

import { bookingFormValidationSchema } from "@/ag-flight-components/validations/bookingFormValidation";
import PriceSummary from "@/ag-flight-components/components/FlightBooking/PriceSummary.vue";
import TripSummary from "@/ag-flight-components/components/FlightBooking/TripSummary.vue";
import TavelerCard from "@/ag-flight-components/components/FlightBooking/TravelerCard.vue";
import { FlightDetailsType, FlightLeg } from "@/ag-flight-components/types";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import {
  IConfirmBooking,
  Traveler,
} from "@/ag-flight-components/types/FlightBookingForm";
import { PATH } from "@/ag-portal-common/constants/path";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/services/analytics.service";
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_MMM_D,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import { formatBookingTravelersPayload } from "../utils";
import { PASSENGER_TYPE } from "@/ag-portal-common/enums/PASSENGER_TYPES";

export default defineComponent({
  name: "FlightBookingForm",
  data(): {
    errors: any;
    flightDetails: FlightDetailsType | null;
    contact_number: any;
    canByPass: boolean;
  } {
    return {
      flightDetails: null,
      errors: {},
      contact_number: {},
      canByPass: false,
    };
  },
  components: {
    TavelerCard,
    PriceSummary,
    TripSummary,
  },
  computed: {
    renderBookingId() {
      return this.$route.params.id;
    },
    isLoading(): boolean {
      return this.$store.getters.isFlightBookingLoading;
    },
    isConfirmBookingLoading() {
      return this.$store.getters.isFlightConfirmBookingLoading;
    },
    travelers() {
      return this.$store.getters.flightTravelers;
    },
    formatLegsInfo() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const results = legs
        .map((item: FlightLeg, idx: number) => {
          return `${item.origin.iata_code} - ${item.destination.iata_code}${
            idx === legs.length - 1 ? "" : ","
          }`;
        })
        .join(" ");
      return results;
    },
    formatTripDates() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const firstDeparture = legs[0]?.departure_datetime;
      const lastDeparture = legs[legs.length - 1]?.departure_datetime;

      if (firstDeparture) {
        if (flightDetails?.route_type === ROUTE_TYPE.ONEWAY) {
          return TimeUtility.parsedFlightTimeorDate(
            firstDeparture,
            FORMAT_MMM_D
          );
        }
        return (
          TimeUtility.parsedFlightTimeorDate(firstDeparture, FORMAT_MMM_D) +
          " - " +
          TimeUtility.parsedFlightTimeorDate(lastDeparture, FORMAT_MMM_D)
        );
      }
      return "";
    },
    renderFlightRouteType() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return _.capitalize(this.flightDetails?.route_type);
    },
    renderMarketingAirlineLogo() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      return flightDetails?.flight_legs[0]?.segment[0]?.marketing_airline?.logo;
    },
    renderTripSummaryItem() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const leg = flightDetails?.flight_legs[0] as FlightLeg;

      return {
        departure_date: leg?.departure_datetime,
        arrival_date: leg?.arrival_datetime,
        origin: leg?.origin,
        destination: leg?.destination,
      };
    },
    renderArrival() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      const leg = flightDetails?.flight_legs[
        flightDetails?.flight_legs?.length - 1
      ] as FlightLeg;
      return {
        departure_date: leg?.departure_datetime,
        arrival_date: leg?.arrival_datetime,
        origin: leg?.origin,
        destination: leg?.destination,
      };
    },
    getAllLegs() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      return flightDetails?.flight_legs;
    },
    isInternational() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails;
      return flightDetails?.is_international;
    },
  },
  watch: {
    isConfirmBookingLoading(currentLoadingState, previousLoadingState) {
      if (previousLoadingState === true && currentLoadingState === false) {
        this.validateResponseErrors(this.errors);
      }
    },
  },
  methods: {
    validateResponseErrors(previousErrors: string) {
      const travellerResponseErrors =
        this.$store.getters.errors?.travelers || "";

      if (!travellerResponseErrors && !previousErrors) {
        this.canByPass = true;
        return "";
      } else if (!travellerResponseErrors && previousErrors) {
        return previousErrors;
      } else {
        const travelersErrors = travellerResponseErrors
          ? travellerResponseErrors
              .map((traveler: Traveler, index: number) => {
                if (Object.keys(traveler).length === 0) {
                  return {};
                }
                const flattenedErrors = {};
                Object.keys(traveler).forEach((key) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  flattenedErrors[`travelers[${index}].${key}`] = traveler[key];
                });
                return flattenedErrors;
              })
              .reduce((acc: any, current: any) => {
                return { ...acc, ...current };
              }, {})
          : "";
        if (travelersErrors) {
          this.errors = travelersErrors;
          this.canByPass = true;
        }
        return this.errors;
      }
    },
    validateTitle(title: string) {
      return title === null ? "Title is required" : "";
    },
    validateDateOfBirth(dob: Date, passengerType: string) {
      if (dob === null) {
        return "Date of Birth is required";
      }
      if (passengerType === PASSENGER_TYPE.ADULT) {
        const diffInYears = differenceInYears(new Date(), dob as Date) >= 12;
        if (diffInYears === false) {
          return "Adult age must be greater than or equal to 12yrs";
        } else {
          return "";
        }
      } else if (passengerType === PASSENGER_TYPE.CHILD) {
        const ageInYears = differenceInYears(new Date(), dob as Date);
        const diffInYears = ageInYears >= 2 && ageInYears <= 11;
        if (diffInYears === false) {
          return "Child age must be greater than 2 and less than or equal to 11yrs";
        } else {
          return "";
        }
      } else if (passengerType === PASSENGER_TYPE.INFANT) {
        const ageInMonths = differenceInMonths(new Date(), dob as Date);
        const diffInYears = ageInMonths <= 23 && ageInMonths >= 0;
        if (diffInYears === false) {
          return "Infant age must be less than or equal to 23 months";
        } else {
          return "";
        }
      }
      return true;
    },
    validateCnic(cnic: string) {
      if (this.isInternational) {
        return "";
      }
      if (!this.isInternational && !cnic) {
        return "CNIC is required";
      }
      if (!this.isInternational) {
        const cnicWithoutHyphens = cnic ? cnic.replace(/-/g, "") : "";
        if (
          !/^\d+$/.test(cnicWithoutHyphens) ||
          cnicWithoutHyphens.length !== 13
        ) {
          return "CNIC must be 13 digits & must be numeric only";
        }
      }
      return "";
    },
    validatePassportNumber(passportNumber: string) {
      if (!this.isInternational) {
        return "";
      }
      if (this.isInternational && !passportNumber) {
        return "Passport Number is Required";
      }
      const length = passportNumber ? passportNumber.trim().length : 0;
      if (length < 9 || length > 15) {
        return "Passport Number must be between 9 to 15";
      }
      if (passportNumber && !/^[a-zA-Z0-9]+$/.test(passportNumber)) {
        return "Passport Number cant contain special characters";
      }
      return "";
    },
    validatePassportExpiry(passportExpiry: string) {
      if (!this.isInternational) {
        return "";
      }
      if (this.isInternational && !passportExpiry) {
        return "Passport expiry is required.";
      }
      if (this.isInternational && passportExpiry) {
        const expiryDate = new Date(passportExpiry);
        const today = addDays(new Date(), 1);
        if (expiryDate <= today) {
          return "Passport expiry must be a atleast 6 months and a day more than today's date.";
        }
      }
      return "";
    },
    validateFirstName(firstName: string) {
      if (firstName === null || firstName.length < 1) {
        return "First Name is required";
      } else {
        const regex = /^[^\d@]+(\s+[^\d@]+)*$/;
        const testFirstName = regex.test(firstName.trim());
        return testFirstName === false ? "First Name is Invalid" : "";
      }
    },
    validateLastName(lasttName: string) {
      if (lasttName === null || lasttName.length < 1) {
        return "Last Name is required";
      } else {
        const regex = /^[^\d@]+(\s+[^\d@]+)*$/;
        const testLastName = regex.test(lasttName.trim());
        return testLastName === false ? "Last Name is Invalid" : "";
      }
    },
    validateGender(gender: string) {
      return gender === null ? "Gender is Required" : "";
    },
    validateNationality(nationality: string) {
      return nationality === null ? "Nationality is Required" : "";
    },
    renderTraverlerErrors(Travelers: any) {
      const errors = {};

      for (let i = 0; i < Travelers.length; i++) {
        const traveler = Travelers[i];
        const passengerType = traveler.passenger_type;
        const prefix = `travelers[${i}]`;

        const titleError = this.validateTitle(traveler.title);
        if (titleError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.title`] = titleError;
        }
        const firstNameError = this.validateFirstName(traveler.first_name);
        if (firstNameError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.first_name`] = firstNameError;
        }

        const lastNameError = this.validateLastName(traveler.last_name);
        if (lastNameError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.last_name`] = lastNameError;
        }
        const dobError = this.validateDateOfBirth(traveler.dob, passengerType);
        if (dobError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.dob`] = dobError;
        }
        const genderError = this.validateGender(traveler.gender);
        if (genderError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.gender`] = genderError;
        }
        const nationalityError = this.validateNationality(traveler.nationality);
        if (nationalityError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.nationality`] = nationalityError;
        }

        const passportNumberError = this.validatePassportNumber(
          traveler.passport_number
        );
        if (passportNumberError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.passport_number`] = passportNumberError;
        }

        const passportExpiryError = this.validatePassportExpiry(
          traveler.passport_expiry
        );
        if (passportExpiryError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.passport_expiry`] = passportExpiryError;
        }

        const cnic = this.validateCnic(traveler.cnic);
        if (cnic) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          errors[`${prefix}.cnic`] = cnic;
        }
      }
      this.errors = Object.keys(errors).length ? errors : "";
    },

    renderMobileNumberError(): string {
      return (
        this.$store.getters.errors?.contact_details?.mobile_number ||
        (this.errors?.mobile_number as string)
      );
    },
    handleMobileFieldChangeNew(value: any) {
      this.contact_number = value;
      this.$store.commit("updateMobileNumber", value);
    },
    handleSuccessBooking(id: string) {
      this.$router.push(
        formatStringToRoutePath(PATH.FLIGHTS_BOOKING_DETAILS, { id })
      );
    },
    validateContactNumber(contact_number: any, mobile_number: any) {
      const haveMobileNumber = mobile_number === undefined ? false : true;
      const hasCountryCode =
        contact_number?.countryCode === undefined ? false : true;

      const isValid = contact_number?.isValid;
      if (!hasCountryCode && !haveMobileNumber) {
        return "Both Country Code and Mobile Number Are Required";
      } else if (hasCountryCode && !haveMobileNumber) {
        return "Mobile Number is Required";
      } else if (!hasCountryCode && haveMobileNumber) {
        return "Country Code is Required";
      } else if (hasCountryCode && haveMobileNumber && !isValid) {
        return "Mobile Number Is Invalid";
      } else {
        return "";
      }
    },
    async handleConfirmBooking() {
      try {
        this.errors = "";
        this.canByPass = false;
        const contact_number = this.contact_number;
        const booking_id = this.$route.params.id as string;
        const mobile_number = contact_number?.e164;

        const flightTravelers = this.$store.getters.flightTravelers || [];

        const {
          travelersForConfirmBookingPaylaod,
          travelersForCreatePassengersPayload,
        } = formatBookingTravelersPayload(flightTravelers);

        this.renderTraverlerErrors(flightTravelers);

        const mobileNumberError = this.validateContactNumber(
          contact_number,
          mobile_number
        );
        if (mobileNumberError) {
          this.errors.mobile_number = mobileNumberError;
        }

        if (!this.errors) {
          this.errors = this.validateResponseErrors(this.errors);
        }

        const payload: IConfirmBooking = {
          contact_details: { mobile_number },
          booking_id,
          travelers: travelersForConfirmBookingPaylaod,
        };

        const createPassengersPayload = {
          organizationId: this.organization().organization_id,
          body: travelersForCreatePassengersPayload,
        };

        analyticsService.logActionEvent(
          FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_BOOKING_CONFIRMED,
          payload
        );
        if (this.canByPass) {
          this.$store.dispatch("confirmFlightBooking", {
            payload,
            successCallback: this.handleSuccessBooking,
          });
          this.$store.dispatch("createPassengers", createPassengersPayload);
        }
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          this.errors = err;
        }
      }
    },
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY_WITH_DASH) => {
      return date ? TimeUtility.parsedFlightTimeorDate(date, format) : "";
    },
  },
  mounted() {
    const booking_id = this.$route.params.id;
    let organization = this.organization() as IOrganizationFromLoginResponse;

    this.$store.dispatch("getFlightBooking", { booking_id });
    this.$store.dispatch(
      "getOrganizationPassengers",
      organization.organization_id
    );
  },

  setup() {
    const organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse;
    return { organization };
  },
});
</script>
