import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e70f111"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"font-weight":"bold","color":"#ed6c02","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgPnrDetail = _resolveComponent("AgPnrDetail")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_FlightDetailAccordion = _resolveComponent("FlightDetailAccordion")!
  const _component_PassengerDetailCard = _resolveComponent("PassengerDetailCard")!
  const _component_AdditionalDetailsCard = _resolveComponent("AdditionalDetailsCard")!
  const _component_AGDiv = _resolveComponent("AGDiv")!
  const _component_AirlineTicketModal = _resolveComponent("AirlineTicketModal")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_AgSidebarThumb = _resolveComponent("AgSidebarThumb")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_a_g_text_field = _resolveComponent("a-g-text-field")!
  const _component_AgTable = _resolveComponent("AgTable")!
  const _component_ag_modal = _resolveComponent("ag-modal")!
  const _component_CancelBookingConfirmationDialog = _resolveComponent("CancelBookingConfirmationDialog")!
  const _component_PriceUpdatedDialog = _resolveComponent("PriceUpdatedDialog")!
  const _component_WaitListDialog = _resolveComponent("WaitListDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: _ctx.isInitiateBooking ? 12 : 8,
                lg: _ctx.isInitiateBooking ? 12 : 9
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgRow, {
                        "test-id": "",
                        class: "v-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgColumn, {
                            "test-id": "",
                            md: "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgSelectedFlightInfo, null, {
                                pnrInfo: _withCtx(() => [
                                  _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Booking ID: "),
                                      _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_AgColumn, {
                            "test-id": "",
                            md: "3",
                            class: "text-right"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AGButton, {
                                "test-id": "",
                                type: "button",
                                variant: _ctx.renderStatusSeverity
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.renderBookingStatus), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["variant"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["md", "lg"]),
              (!_ctx.isInitiateBooking)
                ? (_openBlock(), _createBlock(_component_ag_column, {
                    key: 0,
                    xs: "12",
                    sm: "12",
                    md: "4",
                    lg: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgCard, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgPnrDetail, {
                            "test-id": "",
                            pnr: _ctx.supplierPnr,
                            expiry: _ctx.pnrExpiresAt
                          }, null, 8 /* PROPS */, ["pnr", "expiry"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "8",
                lg: "9"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        variant: "h2",
                        title: "Flight Details",
                        class: "margin_bottom_20"
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.flight_legs, (flightLeg, index) => {
                        return (_openBlock(), _createBlock(_component_FlightDetailAccordion, {
                          key: index,
                          "flight-leg": flightLeg,
                          index: index
                        }, null, 8 /* PROPS */, ["flight-leg", "index"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (_ctx.hasPassengers)
                    ? (_openBlock(), _createBlock(_component_AgCard, {
                        key: 0,
                        "test-id": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ag_heading, {
                            variant: "h2",
                            title: "Passenger Details",
                            class: "margin_bottom_20"
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.passengers, (passenger, index) => {
                            return (_openBlock(), _createBlock(_component_PassengerDetailCard, {
                              key: index,
                              passenger: passenger,
                              "is-international": _ctx.flightDetails?.is_international
                            }, null, 8 /* PROPS */, ["passenger", "is-international"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  (_ctx.AdditionalDetails())
                    ? (_openBlock(), _createBlock(_component_AGDiv, { key: 1 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legAdditionalDetails, (detail, index) => {
                            return (_openBlock(), _createBlock(_component_AgCard, { key: index }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_heading, {
                                  variant: "h2",
                                  title: 'Additional Services on Flight ' + (index + 1)
                                }, null, 8 /* PROPS */, ["title"]),
                                _createVNode(_component_AdditionalDetailsCard, {
                                  "has-meal": detail.mealDetails,
                                  "luggage-info": detail.baggageDetails
                                }, null, 8 /* PROPS */, ["has-meal", "luggage-info"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgSidebarThumb, {
                        "test-id": "",
                        thumb: "sidebarFlightThumb1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PriceSummary, null, {
                            flightDetailsActionButtons: _withCtx(() => [
                              (_ctx.isBookingCancelled)
                                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " Booking " + _toDisplayString(_ctx.renderBookingStatus), 1 /* TEXT */))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      disabled: _ctx.isFlightWaitListed,
                                      onClick: _ctx.onProceedToIssuance,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Proceed To Issuance")
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["disabled", "onClick"]), [
                                      [_vShow, !_ctx.isFlightIssued]
                                    ]),
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isCancelBookingLoading,
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCancelationDialogOpen = true)),
                                      class: "full-width margin_bottom_10",
                                      "test-id": "",
                                      variant: "danger"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Cancel Booking")
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["is-loading"]), [
                                      [_vShow, !_ctx.isFlightCancelled]
                                    ]),
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isVoidFlightTicketLoading,
                                      onClick: _ctx.onVoidPIATicket,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Void ticket")
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["is-loading", "onClick"]), [
                                      [_vShow, _ctx.isIssuedAndisPIAAndisNotInternational]
                                    ]),
                                    _createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isDownloadTicketLoading,
                                      onClick: _ctx.onOpenEditFareModalHandler,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Download ticket")
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["is-loading", "onClick"]),
                                    (_ctx.showDownloadAirlineTicketButton())
                                      ? (_openBlock(), _createBlock(_component_AGButton, {
                                          key: 0,
                                          "is-loading": _ctx.isDownloadTicketLoading,
                                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickAirlineTicket())),
                                          class: "full-width margin_bottom_10",
                                          "test-id": "emailTicket"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("Email ticket ")
                                          ]),
                                          _: 1 /* STABLE */
                                        }, 8 /* PROPS */, ["is-loading"]))
                                      : _createCommentVNode("v-if", true)
                                  ], 64 /* STABLE_FRAGMENT */)),
                              _createVNode(_component_AirlineTicketModal, {
                                "show-modal": _ctx.showAirlineTicketModal,
                                onOnSendAirlineTicket: _ctx.onSendAirlineTicket,
                                onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAirlineTicketModal = false))
                              }, null, 8 /* PROPS */, ["show-modal", "onOnSendAirlineTicket"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ag_modal, {
            persistent: "",
            "is-open": _ctx.isEditModalOpen,
            "modal-width": "50%"
          }, {
            header: _withCtx(() => [
              _createVNode(_component_ag_heading, {
                variant: "h4",
                title: "Edit & Download Ticket"
              }),
              _createVNode(_component_a_g_button, {
                type: "button",
                class: "modal_close_icon",
                variant: "link",
                onClick: _ctx.closeDownloadTicketModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close ")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"])
            ]),
            body: _withCtx(() => [
              _createVNode(_component_AgDiv, null, {
                default: _withCtx(() => [
                  _createVNode(_component_AgHeading, {
                    title: "Do you want to show fare on ticket",
                    variant: "label",
                    "test-id": ""
                  }),
                  _createVNode(_component_MButton, {
                    class: "margin_right_10",
                    type: `${_ctx.canRenderFare ? 'filled' : 'outlined'}`,
                    onClick: _ctx.onShowFareHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Yes ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["type", "onClick"]),
                  _createVNode(_component_MButton, {
                    type: `${!_ctx.canRenderFare ? 'filled' : 'outlined'}`,
                    onClick: _ctx.onHideFareHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" No ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["type", "onClick"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_AgDiv, null, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_AgHeading, {
                    title: "Do you want to edit fare on ticket",
                    variant: "label",
                    "test-id": ""
                  }, null, 512 /* NEED_PATCH */), [
                    [_vShow, _ctx.canRenderFare]
                  ]),
                  _withDirectives(_createVNode(_component_MButton, {
                    class: "margin_right_10",
                    type: `${_ctx.canEditFare ? 'filled' : 'outlined'}`,
                    onClick: _ctx.onShowEditedFareHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Yes ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["type", "onClick"]), [
                    [_vShow, _ctx.canRenderFare]
                  ]),
                  _withDirectives(_createVNode(_component_MButton, {
                    type: `${!_ctx.canEditFare ? 'filled' : 'outlined'}`,
                    onClick: _ctx.onHideEditedFareHandler
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" No ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["type", "onClick"]), [
                    [_vShow, _ctx.canRenderFare]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _withDirectives(_createVNode(_component_AgDiv, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgTable, {
                    headers: _ctx.headers,
                    items: _ctx.items,
                    "items-per-page": _ctx.tableRows,
                    "has-pagination": false,
                    "has-search": false,
                    simpleDataTable: false
                  }, {
                    "col-type": _withCtx(({ item }) => [
                      _createVNode(_component_ag_div, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.raw.paxType), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    "col-amount": _withCtx(({ item }) => [
                      _createVNode(_component_ag_div, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.raw.paxAmount), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    "col-editedAmount": _withCtx(({ item }) => [
                      _createVNode(_component_a_g_text_field, {
                        style: {"padding-top":"5%"},
                        modelValue: item.raw.updatedAmount,
                        "onUpdate:modelValue": ($event: any) => ((item.raw.updatedAmount) = $event),
                        value: item.raw.updatedAmount,
                        type: "number"
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "value"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["headers", "items", "items-per-page"])
                ]),
                _: 1 /* STABLE */
              }, 512 /* NEED_PATCH */), [
                [_vShow, _ctx.openEditFareTable()]
              ])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_MButton, {
                variant: "filled",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveAndDownload()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save & Download")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["is-open"])
        ], 64 /* STABLE_FRAGMENT */)),
    _createVNode(_component_CancelBookingConfirmationDialog, {
      "is-open": _ctx.isCancelationDialogOpen,
      "handle-accpet": _ctx.onCancelBooking,
      "handle-reject": _ctx.handleCloseCancelationDialog
    }, null, 8 /* PROPS */, ["is-open", "handle-accpet", "handle-reject"]),
    _createVNode(_component_PriceUpdatedDialog, {
      "is-open": _ctx.isPriceUpdatedDialog,
      "handle-accpet": _ctx.handlePriceUpdateContinue,
      "gross-fare": _ctx.flightDetails?.equivalent_ag_total_amount || '-',
      currency: _ctx.Currency
    }, null, 8 /* PROPS */, ["is-open", "handle-accpet", "gross-fare", "currency"]),
    _createVNode(_component_WaitListDialog, { "is-open": _ctx.isWaitListDialog }, null, 8 /* PROPS */, ["is-open"])
  ], 64 /* STABLE_FRAGMENT */))
}